import React from "react";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  FormLabel,
  FormHelperText,
  FormControl,
  InputBase,
  ListItemText,
  Checkbox,
  Hidden
} from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { withStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ZohoRegistrationController from "./ZohoRegistrationController.web";
import "./ZohoRegistrationForm.web.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  customerService,
  Pop_up__New_Img,
  zoho_register_left_panel,
} from "../assets";
import { HISTORY } from "../../../../components/src/common";

export default class ZohoRegistrationForm extends ZohoRegistrationController {
  render() {
    const phoneReg = /^(?:[0-9]●?){10,15}[0-9]$/;
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const name = /^[a-zA-Z ]*$/;

    const BootstrapInput = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },

      input: {
        borderRadius: 4,
        position: "relative",
        paddingLeft: "9%",
        border: this.state.boardError ? "1px solid red" : "1px solid #cfcfcf",
        fontSize: 16,
        padding: "10.5px 14px",
        color: this.state.boardError ? "#a6a5c0" : "#394052",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: "8.8px",
          borderColor: "#cfcfcf",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    const BootstrapInput1 = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 4,
        position: "relative",
        border: this.state.courseError ? "1px solid red" : "1px solid #cfcfcf",
        fontSize: 16,
        padding: "10.5px 14px",
        color: this.state.courseError ? "#a6a5c0" : "#394052",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: "8.8px",
          borderColor: "#cfcfcf",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        "& input": {
          padding: "16px 14px",
        },
      },
    }))(InputBase);
    const BootstrapInput2 = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      input: {
        borderRadius: 4,
        position: "relative",
        border: this.state.classError ? "1px solid red" : "1px solid #cfcfcf",
        fontSize: 16,
        paddingLeft: "9%",
        padding: "10.5px 14px",
        color: this.state.classError ? "#a6a5c0" : "#394052",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: "8.8px",
          borderColor: "#cfcfcf",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    }))(InputBase);
    return (
      <Grid
        style={{ width: "100%", position: "relative" }}
        container
        direction="row"
      >
        <Hidden smDown>
        <Grid className="left-section-grid" item 
        //xs={false} 
       sm={5}
        xs={5}>
          <Grid
            item
            xs={12}
            className="close-icon-div"
            style={{ position: "absolute", top: "7px", left: "9px" }}
          >
            <div className="close-rectangle" onClick={this.props.onCloseModal}>
              <Close style={{ color: "#fff", cursor: "pointer" }} />
            </div>
          </Grid>
          {/* <div className="left-section-panel"> */}
          <img
            src={zoho_register_left_panel}
            alt=""
            className="zoho-left-panel-img"
          />
          <Typography className="Enroll" component="span">
            Enroll now and get free demo class
          </Typography>
          {/* </div> */}
        </Grid>
       
        </Hidden>
       
       
        <Grid
          style={{
            fontFamily: "Inter !important",
          }}
          item
          //xs={12}
          // md={12}
          md={7}
          lg={7}
          sm={12}
          xs={12}
        >
          <ToastContainer />
          <Grid style={{ padding: "1% 3% 0 3%" }} container direction="row">
            <Grid
              container
              style={{ width: "100%", padding: "2%" }}
              direction="row"
            >
              <form onSubmit={(e: any) => this.submitForm(e)}>
                <Grid className="marginBottom" item xs={12}>
                  <Typography className="Registration" component="span">
                    Registration
                  </Typography>
                </Grid>
                <Grid className="marginBottom" item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={12} className="marginBottom">
                      <FormLabel className="form-label">
                        Name of Student<span style={{ color: "red" }}> *</span>
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{
                          style: {
                            height: "5px",
                            fontFamily: "Inter",
                            fontSize: 14,
                            color: "#394052",
                          },
                        }}
                        className="textfield"
                        variant="outlined"
                        name="studentName"
                        autoComplete="off"
                        value={this.state.studentName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value.trim();
                          if (value.length === 0) {
                            this.setState({
                              studentnameerror: true,
                              studentnameerrorText: "Student name is required.",
                            });
                          } else {
                            this.setState({
                              studentnameerror: false,
                              studentnameerrorText: "",
                            });
                          }
                          if (
                            event.target.value === "" ||
                            name.test(event.target.value)
                          ) {
                            this.setState({
                              studentName: event.target.value,
                            });
                          }
                        }}
                        onBlur={() => {
                          if (
                            this.state.studentName === null ||
                            this.state.studentName.length === 0
                          ) {
                            this.setState({
                              studentnameerror: true,
                              studentnameerrorText: "Student name is required.",
                            });
                          } else {
                            this.setState({
                              studentnameerror: false,
                              studentnameerrorText: "",
                            });
                          }
                        }}
                        error={this.state.studentnameerror}
                        //helperText={this.state.studentnameerrorText}
                      />
                      {this.state.studentnameerror && (
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.studentnameerrorText}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="marginBottom" item xs={12}>
                  <Grid
                    container
                    className="batchInputContainer"
                    direction="row"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className="marginBottomSmall"
                    >
                      <Grid container direction="row">
                        <Grid item xs={12} className="marginBottom">
                          <FormLabel className="form-label">
                            Father or Mother Name
                            <span style={{ color: "red" }}> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            inputProps={{
                              style: {
                                height: "5px",
                                fontFamily: "Inter",
                                fontSize: 14,
                                color: "#394052",
                              },
                            }}
                            className="textfield"
                            variant="outlined"
                            name="parentName"
                            autoComplete="off"
                            value={this.state.parentName}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = event.target.value.trim();
                              if (value.length === 0) {
                                this.setState({
                                  parentnameerror: true,
                                  parentnameerrorText:
                                    "Parent name is required.",
                                });
                              } else {
                                this.setState({
                                  parentnameerror: false,
                                  parentnameerrorText: "",
                                });
                              }
                              if (
                                event.target.value === "" ||
                                name.test(event.target.value)
                              ) {
                                this.setState({
                                  parentName: event.target.value,
                                });
                              }
                            }}
                            onBlur={() => {
                              if (
                                this.state.parentName === null ||
                                this.state.parentName.length === 0
                              ) {
                                this.setState({
                                  parentnameerror: true,
                                  parentnameerrorText:
                                    "Parent name is required.",
                                });
                              } else {
                                this.setState({
                                  parentnameerror: false,
                                  parentnameerrorText: "",
                                });
                              }
                            }}
                            error={this.state.parentnameerror}
                          />
                          {this.state.parentnameerror && (
                            <FormHelperText style={{ color: "red" }}>
                              {this.state.parentnameerrorText}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container direction="row">
                        <Grid item xs={12} className="marginBottom">
                          <FormLabel className="form-label">
                            Mobile Number of Parent
                            <span style={{ color: "red" }}> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <ReactPhoneInput
                            country={"in"}
                            placeholder="Phone Number"
                            alwaysDefaultMask={false}
                            // autoFormat={false}
                            value={this.state.phonenumber}
                            inputStyle={{
                              width: "100%",
                              // height: "56px",
                              padding: "20px 5px 20px 41px",
                              fontFamily: "Inter",
                              fontSize: "14px",
                              borderRadius: "5px",
                              backgroundColor: "#f9faff",
                              border: this.state.phoneerror
                                ? "1px solid red"
                                : "1px solid #d7d7d9",
                              borderLeft: this.state.phoneerror
                                ? "0px"
                                : "1px solid lightgray",
                            }}
                            buttonStyle={{
                              backgroundColor: "#f1f1f1",
                              border: this.state.phoneerror
                                ? "1px solid red"
                                : "1px solid lightgray",
                              borderRight: this.state.phoneerror
                                ? "0px"
                                : "1px solid lightgray",
                            }}
                            dropdownStyle={{ width: "250px" }}
                            searchStyle={{
                              margin: "0",
                              width: "97%",
                              height: "30px",
                            }}
                            searchClass="search-class"
                            disableSearchIcon
                            enableSearch={true}
                            countryCodeEditable={false}
                            onChange={(value, country: any) => {
                              if (this.state.dialCode !== country.countryCode) {
                                this.setState({
                                  phonenumber: value,
                                  dialCode: country.countryCode,
                                });
                              } else {
                                this.setState({
                                  phonenumber: value,
                                  dialCode: country.countryCode,
                                });
                              }
                              if (value.length === 0) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: "Phone number is required.",
                                });
                              } else if (phoneReg.test(value)) {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: "",
                                });
                              } else if (!phoneReg.test(value)) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: "Enter a valid phone number.",
                                });
                              } else {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: "",
                                });
                              }
                            }}
                            onBlur={(value: any) => {
                              if (
                                this.state.phonenumber === null ||
                                this.state.phonenumber.length === 0
                              ) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: "Phone number is required.",
                                });
                              } else if (
                                !phoneReg.test(this.state.phonenumber)
                              ) {
                                this.setState({
                                  phoneerror: true,
                                  phoneerrorText: "Enter a valid phone number.",
                                });
                              } else if (
                                phoneReg.test(this.state.phonenumber)
                              ) {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: "",
                                });
                              } else {
                                this.setState({
                                  phoneerror: false,
                                  phoneerrorText: "",
                                });
                              }
                            }}
                          />
                          {this.state.phoneerror && (
                            <FormHelperText style={{ color: "red" }}>
                              {this.state.phoneerrorText}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="marginBottom" item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={12} className="marginBottom">
                      <FormLabel className="form-label">
                        Email
                        <span style={{ color: "red" }}> *</span>
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="standard-basic"
                        className="textfield"
                        type="text"
                        autoComplete="off"
                        value={this.state.email}
                        variant="outlined"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value.trim();

                          if (value.length === 0) {
                            this.setState({
                              emailerror: true,
                              emailerrorText: "Email is required.",
                            });
                          } else if (!emailReg.test(value)) {
                            this.setState({
                              emailerror: true,
                              emailerrorText: "Enter a valid Email.",
                            });
                          } else if (emailReg.test(value)) {
                            this.setState({
                              emailerror: false,
                              emailerrorText: "",
                            });
                          }
                          this.setState({ email: event.target.value.trim() });
                        }}
                        onBlur={() => {
                          if (
                            this.state.email === null ||
                            this.state.email.length === 0
                          ) {
                            this.setState({
                              emailerror: true,
                              emailerrorText: "Email is required.",
                            });
                          } else if (!emailReg.test(this.state.email)) {
                            this.setState({
                              emailerror: true,
                              emailerrorText: "Enter a valid Email.",
                            });
                          } else if (emailReg.test(this.state.email)) {
                            this.setState({
                              emailerror: false,
                              emailerrorText: "",
                            });
                          } else {
                            this.setState({
                              emailerror: false,
                              emailerrorText: "",
                            });
                          }
                        }}
                        error={this.state.emailerror}
                        //helperText={this.state.emailerrorText}
                        inputProps={{
                          style: {
                            fontFamily: "Inter",
                            fontSize: 14,
                            color: "#394052",
                            height: "5px",
                          },
                        }}
                      />
                      {this.state.emailerror && (
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.emailerrorText}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="marginBottom" item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={12} className="marginBottom">
                      <FormLabel className="form-label">
                        School Name<span style={{ color: "red" }}> *</span>
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{
                          style: {
                            height: "5px",
                            fontFamily: "Inter",
                            fontSize: 14,
                            color: "#394052",
                          },
                        }}
                        className="textfield"
                        variant="outlined"
                        autoComplete="off"
                        name="schoolName"
                        value={this.state.schoolName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value.trim();
                          if (value.length === 0) {
                            this.setState({
                              schoolnameerror: true,
                              schoolnameerrorText: "School name is required.",
                            });
                          } else {
                            this.setState({
                              schoolnameerror: false,
                              schoolnameerrorText: "",
                            });
                          }
                          if (
                            event.target.value === "" ||
                            name.test(event.target.value)
                          ) {
                            this.setState({
                              schoolName: event.target.value,
                            });
                          }
                        }}
                        onBlur={() => {
                          if (
                            this.state.schoolName === null ||
                            this.state.schoolName.length === 0
                          ) {
                            this.setState({
                              schoolnameerror: true,
                              schoolnameerrorText: "School name is required.",
                            });
                          } else {
                            this.setState({
                              schoolnameerror: false,
                              schoolnameerrorText: "",
                            });
                          }
                        }}
                        error={this.state.schoolnameerror}
                      />
                      {this.state.schoolnameerror && (
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.schoolnameerrorText}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="marginBottom" item xs={12}>
                  <Grid
                    container
                    className="batchInputContainer"
                    style={{ width: "100%" }}
                    direction="row"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className="marginBottomSmall"
                    >
                      <Grid container direction="row">
                        <Grid item xs={12} className="marginBottom">
                          <FormLabel className="form-label">
                            State<span style={{ color: "red" }}> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className="select">
                            <Select
                              id="select"
                              variant="outlined"
                              //className={this.props.classes.selectDropDown}
                              input={<BootstrapInput />}
                              inputProps={{
                                style: {
                                  borderColor: this.state.stateError
                                    ? "1px solid red"
                                    : "#ebebeb",
                                },
                              }}
                              displayEmpty={true}
                              value={this.state.selectedState}
                              onChange={(
                                event: React.ChangeEvent<{ value: unknown }>
                              ) => {
                                if (event.target.value != 0) {
                                  this.setState({ stateError: false });
                                }
                                this.setState({
                                  selectedState: event.target.value,
                                });
                              }}
                              onBlur={() => {
                                if (
                                  this.state.selectedBoard === null ||
                                  this.state.states.length === 0
                                ) {
                                  this.setState({ stateError: true });
                                } else if (this.state.states.length != 0) {
                                  this.setState({ stateError: false });
                                } else {
                                  this.setState({ stateError: false });
                                }
                              }}
                              error={this.state.stateError}
                            >
                              <MenuItem value="" disabled>
                                <ListItemText
                                  style={{
                                    fontFamily: "Inter",
                                    margin: "0px",
                                    lineHeight: "unset",
                                    paddingLeft: "2%",
                                    fontSize: 12,
                                  }}
                                  className="zoho-course-item"
                                >
                                  Select Option
                                </ListItemText>
                              </MenuItem>
                              {this.state.states.map((data: any) => (
                                <MenuItem
                                  value={data.name}
                                  style={{
                                    color: "#394052",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  <ListItemText
                                    style={{
                                      color: "#394052",
                                      fontFamily: "Inter",
                                      margin: "0px",
                                      lineHeight: "unset",
                                      paddingLeft: data.isOptGrp ? "2%" : "5%",
                                    }}
                                    className="zoho-course-item"
                                  >
                                    {data.name}
                                  </ListItemText>
                                </MenuItem>
                              ))}
                            </Select>
                            {this.state.stateError && (
                              <FormHelperText style={{ color: "red" }}>
                                Please select State
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid item xs={12} className="marginBottom">
                        <FormLabel className="form-label">
                          Name of City/Town
                          <span style={{ color: "red" }}> *</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="standard-basic"
                          className="textfield"
                          type="text"
                          name="city"
                          variant="outlined"
                          autoComplete="off"
                          value={this.state.cityName}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value.trim();
                            if (value.length === 0) {
                              this.setState({
                                citynameerror: true,
                                citynameerrorText:
                                  "City/Town name is required.",
                              });
                            } else {
                              this.setState({
                                citynameerror: false,
                                citynameerrorText: "",
                              });
                            }
                            if (
                              event.target.value === "" ||
                              name.test(event.target.value)
                            ) {
                              this.setState({
                                cityName: event.target.value,
                              });
                            }
                          }}
                          onBlur={() => {
                            if (
                              this.state.cityName === null ||
                              this.state.cityName.length === 0
                            ) {
                              this.setState({
                                citynameerror: true,
                                citynameerrorText:
                                  "City/Town name is required.",
                              });
                            } else {
                              this.setState({
                                citynameerror: false,
                                citynameerrorText: "",
                              });
                            }
                          }}
                          error={this.state.citynameerror}
                          inputProps={{
                            style: {
                              fontFamily: "Inter",
                              fontSize: 14,
                              color: "#394052",
                              height: "5px",
                            },
                          }}
                        />
                        {this.state.citynameerror && (
                          <FormHelperText style={{ color: "red" }}>
                            {this.state.citynameerrorText}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="marginBottom" item xs={12}>
                  <Grid
                    container
                    className="batchInputContainer"
                    style={{ width: "100%" }}
                  >
                    <Grid item xs={12} sm={12} md={6} className="marginBottom">
                      <Grid container direction="row">
                        <Grid item xs={12} className="marginBottom">
                          <FormLabel className="form-label">
                            Board<span style={{ color: "red" }}> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className="select">
                            <Select
                              id="select"
                              variant="outlined"
                              //className={this.props.classes.selectDropDown}
                              input={<BootstrapInput />}
                              inputProps={{
                                style: {
                                  borderColor: this.state.boardError
                                    ? "1px solid red"
                                    : "#ebebeb",
                                },
                              }}
                              displayEmpty={true}
                              value={this.state.selectedBoard}
                              onChange={(
                                event: React.ChangeEvent<{ value: unknown }>
                              ) => {
                                if (event.target.value != 0) {
                                  this.setState({ boardError: false });
                                }
                                this.setState({
                                  selectedBoard: event.target.value,
                                });
                              }}
                              onBlur={() => {
                                if (
                                  this.state.selectedBoard === null ||
                                  this.state.boards.length === 0
                                ) {
                                  this.setState({ boardError: true });
                                } else if (this.state.boards.length != 0) {
                                  this.setState({ boardError: false });
                                } else {
                                  this.setState({ boardError: false });
                                }
                              }}
                              error={this.state.boardError}
                            >
                              <MenuItem value="" disabled>
                                <ListItemText
                                  style={{
                                    color: "black",
                                    fontFamily: "Inter",
                                    margin: "0px",
                                    lineHeight: "unset",
                                    paddingLeft: "2%",
                                    fontSize: 14,
                                  }}
                                  className="zoho-course-item"
                                >
                                  Select Option
                                </ListItemText>
                              </MenuItem>
                              {this.state.boards.map((data: any) => (
                                <MenuItem
                                  value={data.name}
                                  style={{
                                    color: "#394052",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  <ListItemText
                                    style={{
                                      color: "#394052",
                                      fontFamily: "Inter",
                                      margin: "0px",
                                      lineHeight: "unset",
                                      paddingLeft: data.isOptGrp ? "2%" : "5%",
                                    }}
                                    className="zoho-course-item"
                                  >
                                    {data.name}
                                  </ListItemText>
                                </MenuItem>
                              ))}
                            </Select>
                            {this.state.boardError && (
                              <FormHelperText style={{ color: "red" }}>
                                Please select board
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container direction="row">
                        <Grid item xs={12} className="marginBottom">
                          <FormLabel className="form-label">
                            Class currently studying in
                            <span style={{ color: "red" }}> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className="select">
                            <Select
                              id="select"
                              variant="outlined"
                              //className={this.props.classes.selectDropDown}
                              input={<BootstrapInput2 />}
                              inputProps={{
                                style: {
                                  borderColor: this.state.classError
                                    ? "1px solid red"
                                    : "#ebebeb",
                                },
                              }}
                              displayEmpty
                              value={this.state.selectedCurrentClass}
                              onChange={(
                                event: React.ChangeEvent<{ value: unknown }>
                              ) => {
                                if (event.target.value != 0) {
                                  this.setState({ classError: false });
                                }
                                this.setState({
                                  selectedCurrentClass: event.target.value,
                                });
                              }}
                              onBlur={() => {
                                if (
                                  this.state.selectedCurrentClass === null ||
                                  this.state.currentClassesList.length === 0
                                ) {
                                  this.setState({ classError: true });
                                } else if (
                                  this.state.currentClassesList.length != 0
                                ) {
                                  this.setState({ classError: false });
                                } else {
                                  this.setState({ classError: false });
                                }
                              }}
                              error={this.state.classError}
                            >
                              <MenuItem value="" disabled>
                                <ListItemText
                                  style={{
                                    color: "black",
                                    fontFamily: "Inter",
                                    margin: "0px",
                                    lineHeight: "unset",
                                    paddingLeft: "2%",
                                    fontSize: 14,
                                  }}
                                  className="zoho-course-item"
                                >
                                  Select Option
                                </ListItemText>
                              </MenuItem>
                              {this.state.currentClassesList.map(
                                (data: any) => (
                                  <MenuItem
                                    value={data.name}
                                    style={{
                                      width: "100%",
                                      fontFamily: "Inter",
                                    }}
                                  >
                                    <ListItemText
                                      style={{
                                        color: "#394052",
                                        fontFamily: "Inter",
                                        lineHeight: "unset",
                                        paddingLeft: "5%",
                                      }}
                                      className="zoho-course-item"
                                    >
                                      {data.name}
                                    </ListItemText>
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            {this.state.classError && (
                              <FormHelperText style={{ color: "red" }}>
                                Please select class
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="marginBottom" item xs={12}>
                  <Grid
                    container
                    className="batchInputContainer"
                    style={{ width: "100%" }}
                    direction="row"
                  >
                    <Grid item xs={12} sm={12} md={6} className="marginBottom">
                      <Grid container direction="row">
                        <Grid item xs={12} className="marginBottom">
                          <FormLabel className="form-label">
                            Select the Program
                            <span style={{ color: "red" }}> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className="select">
                            <Select
                              id="select"
                              variant="outlined"
                              //className={this.props.classes.selectDropDown}
                              input={<BootstrapInput2 />}
                              inputProps={{
                                style: {
                                  borderColor: this.state.programError
                                    ? "1px solid red"
                                    : "#ebebeb",
                                },
                              }}
                              displayEmpty
                              value={this.state.selectedProgram}
                              onChange={(
                                event: React.ChangeEvent<{ value: unknown }>
                              ) => {
                                if (event.target.value != 0) {
                                  this.setState({ programError: false });
                                }
                                this.setState({
                                  selectedProgram: event.target.value,
                                  filteredcoachingCoursesOptions: this.state.coachingCoursesOptions.filter(
                                    (item: any) =>
                                      item.programType == event.target.value
                                  ),
                                });
                              }}
                              onBlur={() => {
                                if (
                                  this.state.selectedProgram === null ||
                                  this.state.programList.length === 0
                                ) {
                                  this.setState({ programError: true });
                                } else if (this.state.programList.length != 0) {
                                  this.setState({ programError: false });
                                } else {
                                  this.setState({ programError: false });
                                }
                              }}
                              error={this.state.programError}
                            >
                              <MenuItem value="" disabled>
                                <ListItemText
                                  style={{
                                    color: "black",
                                    fontFamily: "Inter",
                                    margin: "0px",
                                    lineHeight: "unset",
                                    paddingLeft: "2%",
                                    fontSize: 14,
                                  }}
                                  className="zoho-course-item"
                                >
                                  Select Option
                                </ListItemText>
                              </MenuItem>
                              {this.state.programList.map((data: any) => (
                                <MenuItem
                                  value={data.name}
                                  style={{
                                    width: "100%",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  <ListItemText
                                    style={{
                                      color: "#394052",
                                      fontFamily: "Inter",
                                      lineHeight: "unset",
                                      paddingLeft: "5%",
                                    }}
                                    className="zoho-course-item"
                                  >
                                    {data.name}
                                  </ListItemText>
                                </MenuItem>
                              ))}
                            </Select>
                            {this.state.classError && (
                              <FormHelperText style={{ color: "red" }}>
                                Please select program
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container direction="row">
                        <Grid item xs={12} className="marginBottom">
                          <FormLabel className="form-label">
                            Looking for Admission in
                            <span style={{ color: "red" }}> *</span>
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className="select-course">
                            <Select
                              id="select"
                              variant="outlined"
                              //className={this.props.classes.selectDropDown}
                              input={<BootstrapInput1 />}
                              inputProps={{
                                style: {
                                  borderColor: this.state.courseError
                                    ? "1px solid red"
                                    : "#ebebeb",
                                  paddingLeft: "0px",
                                },
                              }}
                              displayEmpty
                              value={this.state.selectedCourse}
                              onChange={(
                                event: React.ChangeEvent<{ value: unknown }>
                              ) => {
                                if (event.target.value != 0) {
                                  this.setState({ courseError: false });
                                }
                                this.setState({
                                  selectedCourse: event.target.value,
                                });
                              }}
                              onBlur={() => {
                                if (
                                  this.state.selectedCourse === null ||
                                  this.state.coachingCoursesOptions.length === 0
                                ) {
                                  this.setState({ courseError: true });
                                } else if (
                                  this.state.coachingCoursesOptions.length != 0
                                ) {
                                  this.setState({ courseError: false });
                                } else {
                                  this.setState({ courseError: false });
                                }
                              }}
                              error={this.state.courseError}
                            >
                              <MenuItem value="" disabled>
                                <ListItemText
                                  style={{
                                    color: "black",
                                    fontFamily: "Inter",
                                    margin: "0px",
                                    lineHeight: "unset",
                                    paddingLeft: "2%",
                                    fontSize: 14,
                                  }}
                                  className="zoho-course-item"
                                >
                                  Select Option
                                </ListItemText>
                              </MenuItem>

                              {this.state.selectedProgram !== ""
                                ? this.state.filteredcoachingCoursesOptions.map(
                                    (data: any) => (
                                      <MenuItem
                                        key={data.id}
                                        value={data.name}
                                        disabled={data.isOptGrp}
                                        style={{
                                          color: "#394052",
                                          fontFamily: "Inter",
                                          fontWeight: 600,
                                        }}
                                      >
                                        <ListItemText
                                          style={{
                                            color: "#394052",
                                            fontFamily: "Inter",
                                            margin: "0px",
                                            lineHeight: "unset",
                                            paddingLeft: data.isOptGrp
                                              ? "2%"
                                              : "5%",
                                          }}
                                          className="zoho-course-item"
                                        >
                                          {data.name}
                                        </ListItemText>
                                      </MenuItem>
                                    )
                                  )
                                : this.state.coachingCoursesOptions.map(
                                    (data: any) => (
                                      <MenuItem
                                        key={data.id}
                                        value={data.name}
                                        disabled={data.isOptGrp}
                                        style={{
                                          color: "#394052",
                                          fontFamily: "Inter",
                                          fontWeight: 600,
                                        }}
                                      >
                                        <ListItemText
                                          style={{
                                            color: "#394052",
                                            fontFamily: "Inter",
                                            margin: "0px",
                                            lineHeight: "unset",
                                            paddingLeft: data.isOptGrp
                                              ? "2%"
                                              : "5%",
                                          }}
                                          className="zoho-course-item"
                                        >
                                          {data.name}
                                        </ListItemText>
                                      </MenuItem>
                                    )
                                  )}
                            </Select>
                            {this.state.courseError && (
                              <FormHelperText style={{ color: "red" }}>
                                Please select course
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  className="marginBottom"
                  style={{ marginLeft: "-10px" }}
                  item
                  xs={12}
                >
                  <div className="policy">
                    <Checkbox
                      edge="end"
                      onChange={this.handleTogglePrivacyPolicy}
                      className="checkbox"
                      inputProps={{
                        "aria-labelledby":
                          "checkbox-list-secondary-label-question",
                      }}
                    />
                    <span className="privacy-terms">
                      By submitting this form I agree with the storage and
                      handling of your data by Teachze in accordance with our
                      Privacy Policy <span style={{ color: "red" }}> *</span>
                    </span>
                  </div>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                  className="marginBottom"
                  item
                  xs={12}
                  md={6}
                  sm={6}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    className="submit-btn"
                    type="submit"
                    disabled={
                      this.state.studentnameerror ||
                      this.state.parentnameerror ||
                      this.state.phoneerror ||
                      this.state.emailerror ||
                      this.state.boardError ||
                      this.state.classError ||
                      this.state.courseError ||
                      this.state.citynameerror ||
                      !this.state.privacyPolicy
                    }
                    style={{
                      opacity:
                        this.state.studentnameerror ||
                        this.state.parentnameerror ||
                        this.state.phoneerror ||
                        this.state.emailerror ||
                        this.state.boardError ||
                        this.state.classError ||
                        this.state.courseError ||
                        this.state.citynameerror ||
                        !this.state.privacyPolicy
                          ? 0.6
                          : 1,
                    }}
                    //onClick={this.handleOpenZohoFormPopup}
                  >
                    <div className="submit-zoho-form-text">Submit</div>
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="primary"
                    className="cancel-btn"
                    onClick={this.props.onCloseModal}
                  >
                    <div className="cancel-zoho-form-text">Cancel</div>
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
