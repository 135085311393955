import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import { rightArrow, bulletin } from "../../assets";

interface IExpansionPanel {
  details: any;
}

const ExpansionPanel: React.FC<IExpansionPanel> = ({ details }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  return (
    <Accordion elevation={0} expanded={isExpanded} onChange={handleToggle}>
      <AccordionSummary
        expandIcon={
          isExpanded ? <img src={rightArrow} /> : <img src={rightArrow} />
        }
        aria-controls="panel-content"
        style={{
          backgroundColor: "#f8f9ff",
          boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.11);",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            color: "#394052",
            fontFamily: "Inter",
          }}
        >
          <span style={{ fontSize: "20px", fontWeight: 600 }} className="small-chapter-name chapter-dynamic-width">
            {details.chapterName}
          </span>
          <span
            style={{ fontSize: "14px", marginLeft: "5%" }}
            className="small-topic-style"
          >{`${details.topics.length} Topics`}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          style={{ marginLeft: "1%", marginTop: "3%" }}
          container
          direction="row"
        >
          {details.topics?.length &&
            details.topics?.map((topic: any) => (
              <Grid style={{ marginBottom: "3%" }} item xs={12} sm={6}>
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: 500,
                  }} className="small-list-item"
                >
                  <span style={{ marginRight: "2%" }}>
                    <img src={bulletin} />
                  </span>
                  <span>{topic.name}</span>
                </div>
              </Grid>
            ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpansionPanel;
