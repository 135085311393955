import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { subjectScience, subjectChemistry, subjectMaths, ellipse9, group2, group2_1, group2_2 , ellipse8, ellipse10,
    Dinesh_Kumar,
    Mrs_Vaishnavi_Narayanan,
    S_Subramaniam,
    Govarthanan,
    HARI_KRISHNAN_R,
    J_Ajith_Kumar,
    KANNAN_N,
    Rahman_Khan_M,
    S_ARAVINDHA_KRISHNAN,
    Shanmugapriya,
    Yogeshraj_I,
    Ms_Bala_Divya,
    Dr_Gurumoorthy,
    Kalpana_Alla,
    CA_D_Abhinandh_Kumar,
    S_D_Vishnu_Vardhan,
    Dr_P_Jothi,
    CA_Kameswaran,
    K_NARENDIRAN,
    Dr_Chitra_Natarajan,
    Kavitha_Sarma_Kolachana,
    Mrs_Lalitha,
    K_Tamil_oli,
    Vahitha_Banu_M_A,
    MRS_NACHAMMAI_VENKATACHALAM,
    PRAVEEN_SHANKER_N,
    Tamilselvan_V,
    Dinesh_Kumar_detailImg,
    Mrs_Vaishnavi_Narayanan_detailImg,
    S_Subramaniam_detailImg,
    _J_Ajith_Kumar_detailImg,
    _S_ARAVINDHA_KRISHNAN_detailImg,
    _Yogeshraj_I_detailImg,
    Govarthanan_detailImg,
    KANNAN_N_detailImg,
    HARI_KRISHNAN_R_detailImg,
    Shanmugapriya_detailImg,
    Rahman_Khan_M_detailImg,
    Ms_Bala_Divya_detailImg,
    _Dr_Gurumoorthy_detailImg,
    Kalpana_Alla_detailImg,
    CA_D_Abhinandh_Kumar_detailImg,
    _Dr_P_Jothi_detailImg,
    CA_Kameswaran_detailImg,
    _K_NARENDIRAN_detailImg,
    Dr_Chitra_Natarajan_detailImg,
    Kavitha_Sarma_Kolachana_detailImg,
    _Mrs_Lalitha_detailImg,
    K_Tamil_oli_detailImg,
    Vahitha_Banu_M_A_detailImg,
    MRS_NACHAMMAI_VENKATACHALAM_detailImg,
    PRAVEEN_SHANKER_N_detailImg,
    Tamilselvan_V_detailImg,
    S_D_Vishnu_Vardhan_detailImg
} from "../assets";



export interface Props {
    navigation: any;
}

interface S {
    // Customizable Area Start
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    data: any;
    selectedIndex: number;
    crewList: any;
    facultiesList: any;
    isOpenEducatorDetails: boolean;
    educatorSubjects: any;
    testimonialsList: any;
    selectedTestimonial: any;
    selectedEducator: any;
    leaderShipCrewList: any;
    academicCrewList: any;
    operationsCrewList: any;
    selectedCrewList: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class FacultiesController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            data: {},
            selectedIndex: 0,
            crewList: [
                {
                  key: 0,
                  label: "Leadership Crew",
                  detail: "50+ Teachers",
                },
                {
                  key: 1,
                  label: "Academic Crew",
                  detail: "50+ Teachers",
                },
                {
                  key: 2,
                  label: "Operations Crew",
                  detail: "50+ Teachers",
                },
            ],
            facultiesList: [
                {
                    id: 1,
                    profileImage: group2,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in Montessori, Kindergarten & Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 2,
                    profileImage: group2_1,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 3,
                    profileImage: group2_2,
                    name: "Madhusandhya Das",
                    role: "Class Teacher",
                    location: "Bangalore",
                    description: "Diploma in Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 4,
                    profileImage: group2,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in Montessori, Kindergarten & Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 5,
                    profileImage: group2_1,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 6,
                    profileImage: group2_2,
                    name: "Madhusandhya Das",
                    role: "Class Teacher",
                    location: "Bangalore",
                    description: "Diploma in Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 7,
                    profileImage: group2,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in Montessori, Kindergarten & Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 8,
                    profileImage: group2_1,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 9,
                    profileImage: group2_2,
                    name: "Madhusandhya Das",
                    role: "Class Teacher",
                    location: "Bangalore",
                    description: "Diploma in Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 10,
                    profileImage: group2,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in Montessori, Kindergarten & Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 11,
                    profileImage: group2_1,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 12,
                    profileImage: group2_2,
                    name: "Madhusandhya Das",
                    role: "Class Teacher",
                    location: "Bangalore",
                    description: "Diploma in Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 13,
                    profileImage: group2,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in Montessori, Kindergarten & Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 14,
                    profileImage: group2_1,
                    name: "Shilpa Sharma",
                    role: "Class Teacher",
                    location: "New Delhi",
                    description: "Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
                {
                    id: 15,
                    profileImage: group2_2,
                    name: "Madhusandhya Das",
                    role: "Class Teacher",
                    location: "Bangalore",
                    description: "Diploma in Nursery, Diploma in TESOL/TEFL with Specialization in Young learners Teacher Training Program, BBM.",
                },
            ],
            isOpenEducatorDetails: false,
            educatorSubjects: [
              { 
                imgSource: subjectScience,
                name: "Science"      
              },
              { 
                imgSource: subjectChemistry,
                name: "Chemistry"      
              },
              { 
                imgSource: subjectMaths,
                name: "Mathametics"      
              },
            ],
            testimonialsList: [
              {
                id: 0,
                profileImage: ellipse9,
                name: "Gamaho Gemoy",
                type: "Student",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              },
              {
                id: 1,
                profileImage: ellipse8,
                name: "Gamaho Gemoy 2",
                type: "Student",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              },
              {
                id: 2,
                profileImage: ellipse10,
                name: "Gamaho Gemoy 3",
                type: "Student",
                desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              }
            ],
            selectedTestimonial: {},
            selectedEducator: {
                id: "1",
                teacherName: "Vijay Sinha",
                qualifications: "M Tech , IIT Bombay",
                experience: "18 years experience of teaching.Mathematics graduation from Delhi University",
                no_of_likes: 3000,
            },
            leaderShipCrewList: [
                {
                    id: 1,
                    profileImage: Dinesh_Kumar,
                    profileDetailsImage: Dinesh_Kumar_detailImg,             
                    name: "Dinesh Kumar",
                    role: "Co-founder",
                    Qualification: "Master's degree(Computer Science), Bachelor of Engineering (BE)(Computer Science)",
                    experience: "9 years and 4 months of experience in software",
                    about: "EDUCATION FOR ALL. This is the mission that drives Dinesh Kumar. As a Silicon Valley professional and USC alumni with over ten years of experience working for companies like NVIDIA, HP, and various other startups, he possesses the expertise necessary to build a competitive yet enriching learning platform. He is passionate about technology and is particularly invested in using it to solve the genuine issues we face in the world today."
                },
                {
                    id: 2,
                    profileImage: "",
                    name: "Sunitha",
                    role: "Academic Director",
                    Qualification: "M.Sc, B.Ed",
                    experience: "16 years of experience in education",
                    about: "Sunitha is a highly skilled and overtly student-focused educational leader possessing a strong commitment to the development of students with the experience of 16 years in the Field of Curriculum development and learning methodologies. Started the career as a teacher in 2005. Since then, has been working in various institutions as an educationist and received the Best Teacher award 2015 from CBSE board. Through workshop trained 300+ teachers on Art of Questioning, Thinking routines, Making Learning visible, Assessment through Blooms Taxonomy, 21st Century skills & Academic rigour. Experienced in creating and executing Interdisciplinary and Cross-Curricular Connect. Deployed imbibing Ron Richard’s thinking routines (project zero) and understanding map as academic routines to instill thinking minds among students. Worked in building emotional and physical awareness in primary school and best career prospects to students in senior school. Strongly believes in “the function of education is to teach one to think and imbibe life skills”.",
                    certifications: [ "International certificate in Education (ICEPT) – Asian International College, Singapore" ],
                    subject: [ "Computer Science" ],
                    achievements: [
                        "Best Teacher Award from CBSE",
                        "Best Presentation award – Academic Progression strategies 2019 for Concept consolidation & Application"
                    ],
                },
                {
                    id: 3,
                    profileImage: Mrs_Vaishnavi_Narayanan,
                    profileDetailsImage: Mrs_Vaishnavi_Narayanan_detailImg, 
                    name: "Vyshnavi Narayanan",
                    role: "Co-founder",
                    Qualification: "",
                    experience: "",
                    about: "Vysh is an alumna of Academy of Arts, San Francisco and has experience working for a Fortune 100 company. She has a passion for product design and wants to use her creative abilities in the field of education. She has a keen eye for detail and has won awards for her designs. She has established a strong track record in conceptualizing innovative product designs and loves to experiment with creative processes. She wants to combine her passion and skills and help people learn through new educational programs."
                },
                {
                    id: 4,
                    profileImage: S_Subramaniam,
                    profileDetailsImage: S_Subramaniam_detailImg,
                    name: "S.Subramaniam",
                    Qualification: "MA Business Economics",
                    experience: "33 Years of experience",
                    about: "A Seasoned Sales Professional in the field of Education Technology, who works with School, Colleges & Universities across India to upscale ICT interface in the field of Education."
                }
            ],
            academicCrewList: [
                {
                    id: 1,
                    profileImage: Ms_Bala_Divya,
                    profileDetailsImage: Ms_Bala_Divya_detailImg,
                    name: "Bala Divya",
                    qualification: "B.Tech (IT)",
                    experience: "7yrs experience in teaching Mathematics in a reputed CBSE school and coaching students of Foundation Courses and Competitive exams like Olympiad, NTSE",
                    about: "She possess aptitude to remain flexible, ensuring that every child's learning styles and abilities are addressed. Creative instructional methodology and developing mathematical rigour among students are her core strengths. She is expertise in coaching students of class 10 CBSE board examinations.",
                    subject: [ "Mathematics" ],
                    achievements: [],
                    certifications: [],
                },
                {
                    id: 2,
                    profileImage: Dr_Gurumoorthy,
                    profileDetailsImage: _Dr_Gurumoorthy_detailImg,
                    name: "Dr. Gurumoorthy",
                    qualification: "Ph.D in Chemistry",
                    experience: "Former Prof.& HOD Chemistry Annamalai University.  Registrar, SCSVMV Deemed University, Kanchipuram",
                    about: "Prof.Dr. Gurumuthy is the Subject Matter Expert in Chemistry serving more than 4 decades in education. He has been coaching for IIT JEE & NEET for the past 15 years and guided 8 Ph.Ds & 24 M.Phils. He has a track record of 50+ students qualifying JEE & 500 + students qualifying NEET.",
                    subject: [ "Chemistry" ],
                    achievements: [
                        "Published 32 research papers.",
                        "50+ students qualifying JEE & 500 + students qualifying NEET",
                    ],
                    certifications: [],
                },
                {
                    id: 3,
                    profileImage: Kalpana_Alla,
                    profileDetailsImage: Kalpana_Alla_detailImg,
                    name: "Kalpana Alla",
                    qualification: "M Sc Chemistry",
                    experience: "10 + years of coaching Chemistry and Mathematics across boards and 2 years of coaching competitive examinations.",
                    about: "Kalpana is an enthusiastic, committed educator with an innate ability to understand and motivate children. Clarity of concepts, good instructional strategies & building scientific temper are her core strengths.",
                    subject: [ "Chemistry" ],
                    achievements: [],
                    certifications: ["Six Sigma GB"]
                },
                {
                    id: 4,
                    profileImage: "",
                    profileDetailsImage: "",
                    name: "S D Vishnu Vardhan",
                    qualification: "MS in Mathematics",
                    experience: "18 years of teaching experience",
                    about: "Have been teaching maths for CBSE, International boards and many US state school curriculum, college students from India and US, JEE and foundation and for entrance exams like SAT, ACT, GMAT for Indian, US, Canadian and other country students for since 2004. Have also been running a software company for a couple of years and doing Organic farming",
                    subject: [ "Mathematics" ],
                    achievements: [
                        "Built and headed content development department for mathematics, whose job included mapping the standards of various US state curriculum, building content for it, designing games for maths and developing them as apps, received full scholarship to do MS in the University of Kentucky, US, had admission into IIT Madras to do PhD with GATE score and scholarship",
                    ],
                    certifications: [
                        "MS in mathematics from University of Kentucky, Lexington, US",
                        "MSc Mathematics from Anna University, Chennai, India"
                    ],
                },
                {
                    id: 5,
                    profileImage: CA_D_Abhinandh_Kumar,
                    profileDetailsImage: CA_D_Abhinandh_Kumar_detailImg,
                    name: "CA.D.Abhinandh Kumar",
                    qualification: "BCom, ACA",
                    experience: "Experience as a Chartered Accountant along with 2 years of teaching experience",
                    about: "As an Associate Member of the Institute of Chartered Accountants of India (ICAI) and a Commerce graduate from the University of Madras, this young CA has diverse exposure and varied experience in Direct taxation ( domestic and international ) and Indirect taxation - encompassing advisory, consultancy, litigation support, compliance support and audit. He's assisted and actively involved in advising and resolving taxation issues of celebrities, corporates and industrialists across the wide spectrum of the economy. This astute professional also comes across as an avid traveller, movie buff and an endearing lyricist on his personal front. His fervency for teaching is a result of his joy to connect with young minds and enthusiasm to interpret and simplify complex tax laws. He firmly believes in the words of Dr. A. P. J. Abdul Kalam that education is the most powerful tool one can use to change the world.  ",
                    subject: [ "Business Laws (CA Foundation)", "Taxation (CA Intermediate)" ],
                    achievements: [
                        "Certificate of Outstanding Merit in Mathematics by Srinivasa Ramanujan Academy of Maths Talent"
                    ],
                    certifications: [
                        "BCom (University of Madras)",
                        "CA (Institute of Chartered Accountants of India)"
                    ],
                },
                {
                    id: 6,
                    profileImage: Dr_P_Jothi,
                    profileDetailsImage: _Dr_P_Jothi_detailImg,
                    name: "Dr. P Jothi",
                    qualification: "",
                    experience: "30+ years in teaching chemistry across boards and 10+ years of coaching NEET.",
                    about: "Dr.P. Jothi is a Professional Educator with diverse experience and strong track record fostering the highest Score. Served as a Subject matter expert in chemistry and trained teachers of various boards on how to explore the chemistry concepts. He has a track record of 200 + students qualifying NEET.",
                    subject: [ "Chemistry" ],
                    achievements: [
                        "District Teacher Award Received from the Thiruvallur District Collector",
                        "200 + students qualifying NEET",
                    ],
                    certifications: [],
                },
                {
                    id: 7,
                    profileImage: CA_Kameswaran,
                    profileDetailsImage: CA_Kameswaran_detailImg,
                    name: "CA Kameswaran.S",
                    qualification: "M.Com ,Chartered Accountancy",
                    experience: "A practicing Chartered Accountant for the past twenty-three years handling various clients at all levels and 20 years of experience in coaching students for ICFAI – MBA and CFA classes,  Chartered / Cost Accountancy.",
                    about: "He completed his Chartered Accountancy course in November 1996 and is a practicing Chartered Accountant since then. Started teaching in ICAI Bangalore for the Foundation Level Course, which he did for 6 batches. Then started taking courses for Intermediate and Finals too till the year 2007. Since 2007 have been coaching for the ICAI Final Students for the subject of Strategic Finance Management in ICAI Bangalore. Meanwhile, he became adjunct faculty for MBA programs for various MBA Institutes across Bangalore and has been teaching well over 15 years. Currently he is a faculty for Amrita School of Business – Bangalore Campus and does on-line classes to them. Apart from this he has been a sincere teacher to many students who seek his guidance and is also a private tutor for the CA aspirants. He is an expert in Finance Subjects and a passionate teacher with creative teaching methodology. He has a strong communication and presentation skills along with acumen for mentoring students to become successful Finance Professionals.",
                    subject: [ "Accounts" ],
                    achievements: [
                        "He has been associated with well over 20 Institutions in the last 20+ years for teaching Finance.",
                        "He has tutored over 3000 students and more than 500 students have become Chartered Accountants.",
                    ],
                    certifications: [],
                },
                {
                    id: 8,
                    profileImage: "",
                    profileDetailsImage: "",
                    name: "Mohan Raj",
                    qualification: "M.Sc- Physics",
                    experience: "2 Years of experience",
                    about: "He is an inspiring faculty trainee interested to work with young minds.  He is experienced to handle students from grades 7 to 10.",
                    subject: [ "Physics" ],
                    achievements: [],
                    certifications: [],
                },
                {
                    id: 9,
                    profileImage: K_NARENDIRAN,
                    profileDetailsImage: _K_NARENDIRAN_detailImg,
                    name: "K. NARENDIRAN",
                    qualification: "M. sc., B. Ed",
                    experience: "9 years in teaching Mathematics",
                    about: "He is a dedicated & experienced maths teacher of TN state board curriculum and business mathematics – CA Foundation courses. He possesses effective teaching method.",
                    subject: [ "Mathematics" ],
                    achievements: [
                        "College topper in UG",
                    ],
                    certifications: [],
                },
                {
                    id: 10,
                    profileImage: Dr_Chitra_Natarajan,
                    profileDetailsImage: Dr_Chitra_Natarajan_detailImg,
                    name: "Dr. Chitra Natarajan",
                    qualification: "M.Sc, Ph.D",
                    experience: "25 years of experience in coaching Biology - CBSE and NEET ",
                    about: "Dr. Chitra Natarajan is the Subject Matter Expert for Biology. She has been successfully coaching students for medical entrance examinations since 1995. She worked as the resource person in various top academies and developed Content for competitive exams like AIIMS, AIPMT, NEET etc.",
                    subject: [ "Biology" ],
                    achievements: [
                        "Over 2000 + Students qualified for Medical Courses.",
                        "100+ students - admitted in Govt. Medical Colleges"
                    ],
                    certifications: [],
                },
                {
                    id: 11,
                    profileImage: Kavitha_Sarma_Kolachana,
                    profileDetailsImage: Kavitha_Sarma_Kolachana_detailImg,
                    name: "Kavitha Sarma Kolachana",
                    qualification: "M.sc Zoology",
                    experience: "8 years+ experience",
                    about: "She worked as Biology faculty with various academies APJ, Insight Education, SRM etc. She is working as a biology instructor for NEET Since 2016. Easy communication with kids, understanding, motivating them are her strengths.",
                    subject: [ "Biology (Specialization in Botany and Zoology)" ],
                    achievements: [
                        "Gold medallist in M.Sc."
                    ],
                    certifications: [
                        "B.sc Chemistry, Botany and Zoology -St. Joseph’s college for women Vishakhapatnam",
                        "M.sc Zoology (specialization in Fishery biology) Andhra University Vishakhapatnam"
                    ],
                },
                {
                    id: 12,
                    profileImage: Mrs_Lalitha,
                    profileDetailsImage: _Mrs_Lalitha_detailImg,
                    name: "Lalitha",
                    qualification: "M.Sc, M.Phil in Physics",
                    experience: "Former HoD- Physics, Women’s College 37 Years of Experience in Teaching UG & PG",
                    about: "Prof. Lalitha Vijayakumar is the Subject Matter Expert in Chemistry serving more than 3 decades in education. She has been coaching for PMPD, IIT JEE & NEET for the past 24 years. She worked as a resource person in various top Academies and developed Content for competitive exams like JEE, AIIMS, AIPMT, NEET, Foundation Courses etc. Enthusiastic, committed educator with innate ability to understand and motivate children.",
                    subject: [ "Physics" ],
                    achievements: [
                        "She has a track record of 50+ students qualifying JEE & 500 + students qualifying NEET."
                    ],
                    certifications: [],
                },
                {
                    id: 13,
                    profileImage: K_Tamil_oli,
                    profileDetailsImage: K_Tamil_oli_detailImg,
                    name: "K.Tamil oli",
                    qualification: "BE , MA( Political Science)",
                    experience: "Handled Social science and Economics for 10 th to 12 th  Standard about 1 year",
                    about: "Tamil Oli is an enthusiastic, committed educator with innate ability to understand and motivate children. She utilizes variety of instructional strategies and techniques to ensure maximum student participation and learning.",
                    subject: [ "Economics" ],
                    achievements: [
                        "Anna University Rank holder",
                        "Cleared CDS exams twice, Appeared for Mains exam"
                    ],
                    certifications: [],
                },
                {
                    id: 14,
                    profileImage: Vahitha_Banu_M_A,
                    profileDetailsImage: Vahitha_Banu_M_A_detailImg,
                    name: "Vahitha Banu M A",
                    qualification: "M. Sc, B.Ed",
                    experience: "2 years of experience",
                    about: "Vahitha Banu has two years of experience in teaching from grade 6th to 12th across boards.  Teaching is her passion. “Knowledge sharing” helped her to bind and connect with every set of people, especially with the younger generation. This in fact enriched her individual learning, creativity and enhanced professionalism.",
                    subject: [ "Chemistry" ],
                    achievements: [],
                    certifications: [
                        "M.Sc, Ethiraj College For Women, Chennai.",
                        "B.Sc, Meenakshi College For Women, Chennai.",
                    ],
                },
                {
                    id: 15,
                    profileImage: MRS_NACHAMMAI_VENKATACHALAM,
                    profileDetailsImage: MRS_NACHAMMAI_VENKATACHALAM_detailImg,
                    name: "NACHAMMAI VENKATACHALAM",
                    qualification: "B.SC., B.ED.",
                    experience: "17 Years as Science Faculty",
                    about: "Nachammai has been teaching science for more than a decade across boards. She has the ability to redefine teaching standards and is efficient in using modern teaching aids and result oriented teaching with the average of more than 90% every year.",
                    subject: [ "Biology", "Environmental Science" ],
                    achievements: [
                        "Clean revolution project is selected as one of the top 100 projects among 66,000 entries for 2011.",
                        "Gana bhandar, the storehouse of knowledge- selected for jury mentioned award in 2012.",
                        "Participated in design for change world contest –school challenge for two consecutive years."
                    ],
                    certifications: [
                        "MS OFFICE"
                    ],
                },
                {
                    id: 16,
                    profileImage: PRAVEEN_SHANKER_N,
                    profileDetailsImage: PRAVEEN_SHANKER_N_detailImg,
                    name: "PRAVEEN SHANKER N",
                    qualification: "M.Sc., Pursuing Ph.D. in Physics",
                    experience: "10 + years of Teaching Physics",
                    about: "Mr. Praveen Shankar is a dedicated, resourceful and goal-driven professional educator with a solid commitment to the social and academic growth and development of every child. He is also a researcher - SRF in DRDO. He has been for various boards and competitive exams like JEE, NEET, Foundation Courses etc.",
                    subject: [ "Physics" ],
                    achievements: [
                        "4+ RESEARCH PUBLICATIONS TO CREDIT",
                        "50+ students - admitted in Medical and NIT Institute",
                    ],
                    certifications: [
                        "SRF, DRDO",
                        "M.Sc. PHYSICS, UNIVERSITY OF MADRAS",
                        "B.Sc. PHYSICS, LOYOLA COLLEGE, CHENNAI"
                    ],
                },
                {
                    id: 17,
                    profileImage: Tamilselvan_V,
                    profileDetailsImage: Tamilselvan_V_detailImg,
                    name: "Tamilselvan V",
                    qualification: "MSC Visual communication",
                    experience: "1 year experience in video editing",
                    about: "Develop and manage comprehensive academic support program. Provide guidance to students on academic goals and educational issues. ",
                    subject: [],
                    achievements: [],
                    certifications: [],
                },
                // {
                //     id: 18,
                //     profileImage: S_D_Vishnu_Vardhan,
                //     profileDetailsImage: S_D_Vishnu_Vardhan_detailImg,
                //     name: "S D Vishnu Vardhan",
                //     qualification: "MS in Mathematics",
                //     experience: "18 years of experience",
                //     about: "Have been teaching maths for CBSE, International boards and many US state school curriculum, college students from India and US, JEE and foundation and for entrance exams like SAT, ACT, GMAT for Indian, US, Canadian and other country students for since 2004. Have also been running a software company for a couple of years and doing Organic farming",
                //     subject: [ "Mathematics" ],
                //     achievements: [
                //         "Built and headed content development department for mathematics, whose job included mapping the standards of various US state curriculum, building content for it, designing games for maths and developing them as apps, received full scholarship to do MS in the University of Kentucky, US, had admission into IIT Madras to do PhD with GATE score and scholarship.",
                //     ],
                //     certifications: [
                //         "MS in mathematics from University of Kentucky, Lexington, US",
                //         "MSc Mathematics from Anna University, Chennai, India"
                //     ],
                // },
            ],
            operationsCrewList: [
                {
                    id: 1,
                    profileImage: S_ARAVINDHA_KRISHNAN,
                    profileDetailsImage: _S_ARAVINDHA_KRISHNAN_detailImg,
                    name: "S ARAVINDHA KRISHNAN",
                    qualification: "BE- (CSE)",
                    subject: [ "Computer Science" ],
                    experience: "2+Year’s Experience in TECHNICAL SUPPORT ENGINEER",
                    about: "Personable and knowledgeable IT support technician with over 2+ years of experience assisting customers with various hardware and software related issues. Provided in-depth technical support to clients at a Tier 2 level, solving 100% of issues without transferring to Tier 3 support.To Identifying hardware and software related issues and solving the issues by troubleshooting ,Identifying RCA for issues and taking preventive causes,Resolving day-to-day trouble tickets for operating systems, applications, and network problems,Identifying and solving the user profile corrupted issues, file system issues and recovery of system bootable files ,Ms Outlook configuration, backup & restore and troubleshooting ,Basic Networking, Map drive,Remote assistance ,Configuring and maintaining network printer ,Configure and manage Active Directory",
                    certifications: [
                       "I have completed java course in TCS- ION consultancy Services.",
                       "I have completed BIG-DATA course in JIT."
                    ],
                    achievements: [ "I have completed a BIG DATA (HADOOP 1.2.1 & MAPREDUCE) course and certified with 74% marks organized by ICTACT." ],
                },
                {
                    id: 2,
                    profileImage: Yogeshraj_I,
                    profileDetailsImage: _Yogeshraj_I_detailImg,
                    name: "Yogeshraj. I",
                    qualification: "B.Tech. - Information Technology",
                    subject: [],
                    experience: "1 Year of experience",
                    about: "Yogeshraj is a young talented Technologist having a year of experience with good vision and hope. He is having a strong passion for technology and can handle critical situations in a better way. He has has Proficiency in Python, Java and documentation. He Has a ready to learn attitude and share is learning with the team and grow with the organization",
                    certifications: [
                       "AWS Cloud Practitioner Essentials",
                       "JAVA",
                       "Python"
                    ],
                    achievements: [ "B.Tech. Information Technology" ],
                },
                {
                    id: 3,
                    profileImage: Govarthanan,
                    profileDetailsImage: Govarthanan_detailImg,
                    name: "Govarthanan",
                    qualification: "B.E Computer Science and Engineering",
                    subject: [],
                    experience: "Just Started to Gain Experience ",
                    about: "As a fresher right out of college with high hopes and dreams, he joined our organization. To improve himself and the organization, he is dedicating his time and talents to us. His willingness to learn innovative technologies and share his thoughts with the team will come in handy in the future.",
                    certifications: [
                       "C",
                       "C++",
                       "Basic designing"
                    ],
                },
                {
                    id: 4,
                    profileImage: KANNAN_N,
                    profileDetailsImage: KANNAN_N_detailImg,
                    name: "KANNAN N",
                    qualification: "Bachelor of Technology (IT)",
                    subject: [],
                    experience: "1 year experience",
                    about: "I am Knowledgeable and interesting in front end languages. I am eager to learn about new technologies.Diagnosing and repairing faults. Resolving network issues. Installing and configuring hardware and software.",
                    certifications: [
                       "I have completed  “Build Your Portfolio Website with HTML and CSS” at coursera.",
                       "I have completed  “ Basic Sql ” at coursera.",
                    ],
                    achievements: [ "Internship in the company “UNIQ TECHNOLGIES” for a period of 30 days on web designing." ],
                },
                {
                    id: 5,
                    profileImage: J_Ajith_Kumar,
                    profileDetailsImage: _J_Ajith_Kumar_detailImg,
                    name: "J Ajith Kumar",
                    qualification: "B.Tech (IT)",
                    subject: [],
                    experience: "1+ year experience in TECHNICAL SUPPORT ENGINEER",
                    about: "Personable and knowledgeable IT support technician with over 1 years of experience various hardware and software related issues. Troubleshoot, maintain and analyze computer application and software.",
                },
                {
                    id: 6,
                    profileImage: HARI_KRISHNAN_R,
                    profileDetailsImage: HARI_KRISHNAN_R_detailImg,
                    name: "HARI KRISHNAN R",
                    qualification: "MBA",
                    subject: [ "Human Resources & Finance" ],
                    experience: "9 YEARS + experience",
                    about: "I have 9 year above experience in accounts department. I am very righteousness & dedicated person and adapt to work anywhere. History is an important for everyone, now I am moving one more step for that to make a good accountant.",
                    certifications: [
                       "BSC Mathematics",
                       "MBA",
                       "Tally"
                    ],
                    achievements: [],
                },
                {
                    id: 7,
                    profileImage: Shanmugapriya,
                    profileDetailsImage: Shanmugapriya_detailImg,
                    name: "Shanmugapriya",
                    qualification: "B.E/ Electronics Communications Engineering",
                    subject: [],
                    experience: "Fresher",
                    about: "She is proficient and pioneering mind. Results-driven professional with repeated success in guiding IT projects from start to finish, managing technical support operations and introducing new technologies to promote operational efficiency.",
                    certifications: [
                       "HTML",
                       "CSS",
                       "Python for Beginner",
                    ],
                    achievements: [],
                },
                // {
                //     id: 8,
                //     profileImage: Rahman_Khan_M,
                //     profileDetailsImage: Rahman_Khan_M_detailImg,
                //     name: "Rahman Khan M",
                //     qualification: "Bachelor of Technology (IT)",
                //     subject: [],
                //     experience: "1 year experience",
                //     about: "1+ years of experience in Technical Support Engineer",
                //     certifications: [
                //        "B. Tech (Anna University), Manual & Automation Testing"
                //     ],
                //     achievements: [ "Internship in the company “Voice Snap Services Pvt. Ltd” for a period of 48 days on “Android App Development”. and In plant training in the company “Code Bind Technologies” for a period of 5 days on “Web Designing”." ]
                // },
            ],
            selectedCrewList: [],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.setState({ selectedTestimonial: this.state.testimonialsList[0], selectedCrewList: this.state.leaderShipCrewList });

        const { crewList } = this.state;   
        crewList[0].detail = `${this.state.leaderShipCrewList?.length} Teachers`;
        crewList[1].detail = `${this.state.academicCrewList?.length} Teachers`;
        crewList[2].detail = `${this.state.operationsCrewList?.length} Teachers`;
        this.setState({ crewList: this.state.crewList });
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }
        // Customizable Area End
    }

    // Customizable Area Start
    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false,
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    handleSelectTab = (key: number) => {
        if (key === 0) {
            this.setState({ selectedCrewList: this.state.leaderShipCrewList });
        } else if (key === 1) {
            this.setState({ selectedCrewList: this.state.academicCrewList });
        } else if (key === 2) {
            this.setState({ selectedCrewList: this.state.operationsCrewList });
        }
        this.setState({ selectedIndex: key });
    };

    getJustifyContent = (index: number) => {
        if (index === 0) {
            return "flex-start";
        } else if (index === 2) {
            return "flex-end";
        } else {
            return "center";
        }
    };

    previousArrowClick = () => {
        const { selectedTestimonial, testimonialsList } = this.state;
        const index = testimonialsList.findIndex((item: any) => item.id === selectedTestimonial.id)
        if (index !== 0) {
            this.setState({ selectedTestimonial: testimonialsList[selectedTestimonial.id - 1] })
        }
    }

    nextArrowClick = () => {
        const { selectedTestimonial, testimonialsList } = this.state;
        const index = testimonialsList.findIndex((item: any) => item.id === selectedTestimonial.id)
        if (index !== testimonialsList.length - 1) {
            this.setState({ selectedTestimonial: testimonialsList[selectedTestimonial.id + 1] })
        }
    }
    // Customizable Area End
}
