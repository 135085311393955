import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/common";

import {
  user,
  imageFaculty,
  imageMaterial,
  imageAnalysis,
  imageCalendar,
  imageLaptop,
  imageGroup,
  imagePaper,
  imageTest,
  group28,
  subjectScience,
  subjectMaths,
  subjectChemistry,
  subjectGeo,
  studentGrades,
  businessReport,
  seoReport,
  flasks,
  speedometer,
  career,
  discussion,
  qa,
  hours,
  preparation,
  structured,
} from "../assets";

export interface Props {
  navigation: any;
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  data: any;
  userDetails: any;
  courseFeatures: any;
  courseFees: any;
  hours:any;
  highlightsCourseName:any;
  courseHighlights: any;
  foundationCourseHighlights: any;
  batches: any;
  subjects: any;
  subscriptionList: any;
  selectedIndex: number;
  selectedView: string;
  includedFeatures: any;
  faqDetails: any;
  isFaqExpanded: boolean;
  isOpenPerformanceReport: boolean;
  reportList: any;
  openZohoForm: boolean;
  courseDescription: string;
  courseName: string;
  testimonials: Array<any>;
  open: boolean;
  jeeCompactTestSeries:any;
  neetCompactTestSeries:any;
  jeeMockTestSeries:any;
  neetMockTestSeries:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CourseDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      open: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      data: {},
      testimonials: [],
      userDetails: [
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        // {
        //   imgSource: user,
        //   name: "Vijay Zala",
        //   role: "Parent",
        //   text: "My son loves the sessions and I can already see the change.",
        // },
        // {
        //   imgSource: user,
        //   name: "Vijay Zala",
        //   role: "Parent",
        //   text: "My son loves the sessions and I can already see the change.",
        // },
        // {
        //   imgSource: user,
        //   name: "Vijay Zala",
        //   role: "Parent",
        //   text: "My son loves the sessions and I can already see the change.",
        // },
        // {
        //   imgSource: user,
        //   name: "Vijay Zala",
        //   role: "Parent",
        //   text: "My son loves the sessions and I can already see the change.",
        // },
        // {
        //   imgSource: user,
        //   name: "Vijay Zala",
        //   role: "Parent",
        //   text: "My son loves the sessions and I can already see the change.",
        // },
        // {
        //   imgSource: user,
        //   name: "Vijay Zala",
        //   role: "Parent",
        //   text: "My son loves the sessions and I can already see the change.",
        // },
        // {
        //   imgSource: user,
        //   name: "Vijay Zala",
        //   role: "Parent",
        //   text: "My son loves the sessions and I can already see the change.",
        // },
        // {
        //   imgSource: user,
        //   name: "Vijay Zala",
        //   role: "Parent",
        //   text: "My son loves the sessions and I can already see the change.",
        // },
      ],
      courseFeatures: [
        {
          featureImage: imageAnalysis,
          title: "Master Teachers",
          desc:
            "Faculty with focus to equip students with all inputs needed to succeed in the exam.",
        },

        {
          featureImage: imageMaterial,
          title: "Comprehensive Study Material",
          desc: "In-depth lesson plans with exam-based examples and questions",
        },
        {
          featureImage: imageAnalysis,
          title: "Scorecards and Performance Reports",
          desc: "Keep upgrading yourself with detailed performance report",
        },
        {
          featureImage: imageFaculty,
          title: "Live personalized classes",
          desc:
            "Expert NEET faculty with proven experience will deliver through our Live and Online classes",
        },
      ],
      hours:0,
      highlightsCourseName:"Doubt resolution by JEE Experts",
      courseHighlights: [
        {
          image: hours,
          title: "250+ hours of class",
          text: "",
        },
        {
          image: imageCalendar,
          title: "Proven Teaching Methodologies",
          text: "",
        },
        {
          image: imageLaptop,
          title: "Personalized Mentorship",
          text: "",
        },
        {
          image: imagePaper,
          title: "Enhance Problems solving and application skills",
          text: "",
        },
        {
          image: imageGroup,
          title: "Comprehensive study material and Question Bank",
          text: "",
        },
        {
          image: imageTest,
          title: "Integrated Test and Assessments",
          text: "",
        },
        {
          image: qa,
          title: "",
          text: "",
        },
        {
          image: structured,
          title: "Structured practice module",
          text: "",
        },
        {
          image: preparation,
          title: "Preparation Strategies and Techniques",
          text: "",
        },
        {
          image: discussion,
          title: "Question Paper Discussion",
          text: "",
        },
      ],
      foundationCourseHighlights:[
        {
          image: hours,
          title: "300+ hrs of live online class",
          text: "",
        },
        {
          image: imageCalendar,
          title: "All-inclusive Study Material",
          text: "",
        },
        {
          image: imageLaptop,
          title: "Dedicated online tutors – Individual attention",
          text: "",
        },
        {
          image: imagePaper,
          title: "Recorded videos of online classes to revisit",
          text: "",
        },
        {
          image: imageGroup,
          title: "Instant Doubt resolution",
          text: "",
        },
        {
          image: imageTest,
          title: "Question Paper discussion"
        },
        {
          image: qa,
          title: "Integrated Tests and Assessments",
          text: "",
        },
        {
          image: preparation,
          title: "Individualized Analyzer of Performance",
          text: "",
        },
      ],
      jeeCompactTestSeries:[
        {
          image: hours,
          title: "Duration - 4 Months",
          text: "",
        },
        {
          image: imageCalendar,
          title: "120+ Tests comprises of Chapter, Combined, subject, full syllabus",
          text: "",
        },
        {
          image: imageLaptop,
          title: "Systematic Performance Analysis Report",
          text: "",
        },
        {
          image: imagePaper,
          title: "Supplementary modules",
          text: "",
        },
        {
          image: imageGroup,
          title: "Self explanatory solution ",
          text: "",
        },
      ],
      jeeMockTestSeries:[
        {
          image: hours,
          title: "Duration - 1 Month",
          text: "",
        },
        {
          image: imageCalendar,
          title: "15 Tests Comprises of Subject, Full Syllabus.",
          text: "",
        },
        {
          image: imageLaptop,
          title: "Systematic Performance Analysis Report",
          text: "",
        },
        {
          image: imagePaper,
          title: "Supplementary modules",
          text: "",
        },
        {
          image: imageGroup,
          title: "Self explanatory solution",
          text: "",
        },
      ],
      neetCompactTestSeries:[
        {
          image: hours,
          title: "Duration - 4 Months",
          text: "",
        },
        {
          image: imageCalendar,
          title: "60+ Tests comprises of Chapter, Subject, Section, Full Syllabus",
          text: "",
        },
        {
          image: imageLaptop,
          title: "Systematic Performance Analysis Report",
          text: "",
        },
        {
          image: imagePaper,
          title: "Supplementary modules",
          text: "",
        },
        {
          image: imageGroup,
          title: "Self explanatory solution",
          text: "",
        },
      ],
      neetMockTestSeries:[
        {
          image: hours,
          title: "Duration - 1 Month",
          text: "",
        },
        {
          image: imageCalendar,
          title: "15+ Tests comprises of Subject, Section, Full Syllabus",
          text: "",
        },
        {
          image: imageLaptop,
          title: "Systematic Performance Analysis Report",
          text: "",
        },
        {
          image: imagePaper,
          title: "Supplementary modules",
          text: "",
        },
        {
          image: imageGroup,
          title: "Self explanatory solution",
          text: "",
        },
      ],
      batches: [
        {
          imgSource: group28,
          title: "Batch For Term 2 class 9",
          startDate: "2nd January,2022",
          faculty: "Jitu Rathore, Nita Patel",
        },
        {
          imgSource: group28,
          title: "Batch For Term 2 class 9",
          startDate: "2nd January,2022",
          faculty: "Jitu Rathore, Nita Patel",
        },
        {
          imgSource: group28,
          title: "Batch For Term 2 class 9",
          startDate: "2nd January,2022",
          faculty: "Jitu Rathore, Nita Patel",
        },
      ],
      subjects: [
        {
          imgSource: subjectScience,
          name: "Physics",
        },
        {
          imgSource: subjectChemistry,
          name: "Chemistry",
        },
        {
          imgSource: subjectMaths,
          name: "Mathematics",
        },
        // {
        //   imgSource: subjectChemistry,
        //   name: "Chemistry",
        // },
        {
          imgSource: subjectGeo,
          name: "Geography",
        },
        // {
        //   imgSource: subjectChemistry,
        //   name: "Chemistry",
        // },
      ],
      subscriptionList: [
        // {
        //   key: 0,
        //   label: "Small Group",
        //   detail: "Batch of 8",
        // },
        {
          key: 0,
          label: "Medium Group",
          detail: "Batch of 30",
        },
        // {
        //   key: 2,
        //   label: "Personal Online Coaching",
        //   detail: "1-on-1 online coaching",
        // },
      ],
      selectedIndex: 0,
      selectedView: "yearly",
      includedFeatures: [
        {
          key: 0,
          title: "Live Online Class",
        },
        {
          key: 1,
          title: "Test Series & Analysis",
        },
        {
          key: 2,
          title: "Assignment & Notes",
        },
        {
          key: 3,
          title: "Doubt Solving During Class",
        },
        {
          key: 4,
          title: "Doubt Solving on app",
        },
        {
          key: 5,
          title: "Small Group Coaching",
        },
      ],
      faqDetails: [
        {
          id: 1,
          question: "What will the medium of instruction be in Tamil Nadu?",
          answer: "No Course wise Fees need to be paid.",
        },
        {
          id: 2,
          question: "Are the classes live?",
          answer: "No Course wise Fees need to be paid.",
        },
        {
          id: 3,
          question: "Can I take an hourly rate?",
          answer: "No Course wise Fees need to be paid.",
        },
        {
          id: 4,
          question: "Will I be able to ask doubts to my instructor?",
          answer: "No Course wise Fees need to be paid.",
        },
        {
          id: 5,
          question: "Will I be able to ask doubts to my instructor?",
          answer: "No Course wise Fees need to be paid.",
        },
        {
          id: 6,
          question: "Do I need internet connection for watching the videos?",
          answer: "No Course wise Fees need to be paid.",
        },
        {
          id: 7,
          question:
            "I currently attend on-line classes through my Mobile Phone and have limited Internet Data. Can I use it?",
          answer: "No Course wise Fees need to be paid.",
        },
        {
          id: 8,
          question: "What will the medium of instruction be in Tamil Nadu?",
          answer:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        },
        {
          id: 9,
          question: "What will the medium of instruction be in Tamil Nadu?",
          answer: "No Course wise Fees need to be paid.",
        },
      ],
      isFaqExpanded: false,
      isOpenPerformanceReport: false,
      reportList: [
        {
          reportImage: studentGrades,
          title: "Academic Performance Report",
          desc:
            "Get your overall score and rank, subject score and rank, percentage on your report card to know your progress.",
        },
        {
          reportImage: businessReport,
          title: "Question Performance Report",
          desc:
            "Know the summary of the attempted, un-attempted, correct and incorrect questions to understand the areas of improvement",
        },
        {
          reportImage: seoReport,
          title: "Chapter Performance Report",
          desc:
            "Find out the chapters you can improve and the ones that you are well-versed in to decide your focus of learning",
        },
        {
          reportImage: flasks,
          title: "Subject Performance Report",
          desc: "This report will summarise the level of concept learned.",
        },
        {
          reportImage: speedometer,
          title: "Difficulty Level Performance Report",
          desc:
            "A valuable report to check on the toughness of the course and concepts for you.",
        },
        {
          reportImage: career,
          title: "Progression Graph",
          desc:
            "Know if the amount of effort you are putting is really worth the time.",
        },
      ],
      openZohoForm: false,
      courseFees: "",
      courseDescription: "",
      courseName: "",
    };
    this.userdata = this.userdata.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    console.log("user_data", data);
    this.setState({ data: data });
  };

  async componentDidMount() {
    if (HISTORY.location?.state?.testimonials?.length) {
      this.setState({
        testimonials: HISTORY.location.state.testimonials,
      });
    }
    if (HISTORY.location?.state?.courseInfo?.subjects?.length) {
      console.log(HISTORY.location?.state?.courseInfo?.subjects, "subjects");
      this.setState({
        subjects: [...HISTORY.location.state.courseInfo.subjects],
        courseFees: HISTORY.location.state.courseInfo.feeStructure,
        hours:HISTORY.location.state.courseInfo.hours ,
        highlightsCourseName:HISTORY.location.state.courseName.includes("NEET") 
        ? "Doubt resolution by NEET Experts" 
        : HISTORY.location.state.courseName.includes("JEE")
        ? "Doubt resolution by JEE Experts"
        : HISTORY.location.state.courseName.includes("CA")
        ?  "Doubt resolution by CA Experts"
        : HISTORY.location.state.courseName.includes("Foundation")
        ? "Integrated Tests and Assessments"
        : "Doubt resolution by Experts",
        courseDescription: HISTORY.location.state.courseInfo.description,
        courseName: HISTORY.location.state.courseName,
      });
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  handleToggleFaq = () => {
    this.setState({ isFaqExpanded: !this.state.isFaqExpanded });
  };

  handleSelectTab = (key: number) => {
    this.setState({ selectedIndex: key });
  };

  handleOnChangeView = (view: string) => {
    this.setState({ selectedView: view });
  };

  getJustifyContent = (index: number) => {
    if (index === 0) {
      return "flex-start";
    } else if (index === 2) {
      return "flex-end";
    } else {
      return "center";
    }
  };

  handleCloseZohoFormPopup = () => {
    this.setState({ openZohoForm: false });
  };

  handleOpenZohoFormPopup = () => {
    this.setState({ openZohoForm: true });
  };
  // Customizable Area End
}
