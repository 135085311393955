import { makeStyles } from "@material-ui/core/styles";


const useStylesFooter = makeStyles((theme) => ({
    trialButton : {
        padding: "0.8rem" ,
        fontWeight: "bold",
        transform: "skewX(-6deg)",
        borderRadius: "10px",
        width: "217px",
        height: "61px",
        backgroundColor: "white"
    },
    footerWrapper :{
        justifyContent: "space-evenly",
        // width: "90%",
        backgroundColor: "rgb(238,242,254)",
    },
    innerFooterLeft : {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    innerFooterRight : {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    footerLogo :{
        marginBottom: "25px",
        // width: "80%",
        width: "154.7px",
        height: "63px",
    },
    getInTouchWrapper : {
        display: "flex",
        alignItems: "center",
        paddingTop: "13px",
       
        ".getInTouchWrapper :first-of-type" :{
            marginTop: "130px",
        }
    },
    divider : {
        height: 2, 
        color: "#969696"
    },
    reservedRights : {
        backgroundColor: "rgb(238,242,258)" 
    }
}))


export default useStylesFooter