import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import React from "react";
interface linkIetmProps {
  data: string;
  onClick?: any;
  target?: any;
}
function LinkItem(props: linkIetmProps) {
  return (
    <Box fontSize={{xs:"11px",sm:"13px",md:"14px",lg:"14px"}} className="Link-item-text">
      <Link href={props.onClick} target={props.target} underline="hover" style={{color:"black"}}>
        {props.data}
      </Link>
    </Box>
  );
}

export default LinkItem;
