import React from 'react'
import {
    Grid,
    Button,
    Typography,
  } from "@material-ui/core";
type Props = {
    title : string;
    description : string;
    onClick : () => void;
    imgSrc : string
}

const CarouselCard = (props: Props) => {
  return (
  
    <Grid container spacing={2} className="">
      <Grid
        item
        xs={12}
        sm={6}
        className="flex_column_justify_center"
      >
        <Typography variant="h4" className="textlabel-slider-header">
         {props.title}
        </Typography>
        <Typography className="textlabel-slider-text">
         {props.description}
        </Typography>
        <Button
          className="start_learning_now_btn"
          onClick={props.onClick}
        >
          <Typography className="start_learning_now_btn_text">
            Start Learning Now
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} className="justify_flex_end">
        <img
          src={props.imgSrc}
          alt="Image"
          className="developer-activity-img"
        />
      </Grid>
    </Grid>

  )
}

export default CarouselCard