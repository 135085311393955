import React from "react";
import { styled } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Grid,
  Divider,
  Tabs,
  Box,
  AppBar,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import LandingPageController from "./LandingPage/LandingPageController.web";
import PropTypes from "prop-types";
import {
  onlineLearning,
  bitmap1,
  government,
  exam,
  presentation,
  rightArrow,
  leftArrow,
  group28,
  group35,
  group38,
  developerActivity,
  bitmap2,
  quote,
  aboutneet,
} from "./assets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./CompetetiveExamPage.web.css";
import { HISTORY } from "../../../components/src/common";
import ZohoFormModalPopup from "./ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import ThankYou from "./ThankYou/ThankYou.web";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const root = {

} as const;
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: false,
  autoplaySpeed: 3000,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
  style: {
    padding: "75px 0",
  },
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Reveal = require("react-reveal/Reveal");

class CompetetiveExamPage extends LandingPageController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      courseNeetDetails,
      courseDetails,
      courseDetailsCA,
      courseDetailsJEE,
      courseDetailsFoundation,
      courseHighlights,
      courseHighlightsJEE,
      courseHighlightsCA,
      courseHighlightsPreFoundation,
      teachzePoints,
      userDetails,
      caUserDetails,
      tabsList,
      filteredTabsList,
      selectedIndex,
    } = this.state;
    console.log("selectedIndex", selectedIndex);

    const handleMetaTags = () =>{
      if(selectedIndex === 0){
        return (
          <Helmet>
          <title>Teachze | Leading LIVE Online Classes for Competitive Exams</title>
          <meta name="description" content="Prepare for Competitive exams with Teachze LIVE online classes for NEET and JEE. Our dedicated expert tutors will help and guide to get best results and better ranks in all competitive exams like NEET, JEE and CA. We also offer foundation courses and LIVE online coaching classes for 8th, 9th and 10th students. Avail comprehensive study materials, instant doubt solving session, online tests, personalized attention from master teachers to ace the competitive exams. Enroll today and learn with Teachze." />
         </Helmet>
        )
      } else if(selectedIndex === 1){
        return (
          <Helmet>
          <title>Teachze | Best LIVE online coaching classes for NEET in Tamil Nadu</title>
          <meta name="description" content="Are you a NEET Aspirant? Join NEET online coaching classes at Teachze – the smartest way to crack NEET as it comes with a full package of comprehensive study materials, live personalized classes by master teachers and scorecards to track your performance. We help each student’s to be best prepared in all 3 subjects- physics, chemistry and biology to succeed in NEET. Book a Free Trail Class today!" />
         </Helmet>
        )
      }else if(selectedIndex === 2){
        return (
          <Helmet>
          <title>Teachze | Best LIVE online coaching classes for JEE in Tamil Nadu</title>
          <meta name="description" content="Are you a JEE Aspirant? Join JEE online coaching classes at Teachze – the smartest way to crack JEE as it comes with a full package of comprehensive study materials, live personalized classes by master teachers and scorecards to track your performance. We help each student’s to be best prepared in all 3 subjects- physics, chemistry, mathematics and enhance their skills and techniques to succeed in JEE. Book a Free Trail Class today!" />
         </Helmet>
        )
      }else if(selectedIndex === 3){
        return (
          <Helmet>
          <title>Teachze | Best LIVE online coaching classes for CA in Tamil Nadu</title>
          <meta name="description" content="Are you a CA Aspirant? Join CA online coaching classes at Teachze – the smartest way to prepare for CA exams as it comes with a full package of comprehensive study materials, live personalized classes by CA professional faculty members and scorecards to track your performance. We help each student’s to be best prepared in all 4 subjects for the foundation exam and train them to face the examination with structural learning and robust practice. Book a Free Trail Class today!" />
         </Helmet>
        )
      }else {
        return (
          <Helmet>
          <title>Teachze | JEE & NEET Foundation Course for classes 8th, 9th, 10th</title>
          <meta name="description" content="Teachze Online Foundation Course is the smartest way to prepare for NEET and JEE from the earliest. Join online foundation classes – NEET, JEE for Classes 8th. 9th and 10th. It comes with a full package of comprehensive study materials, live personalized classes by and scorecards to track your performance. Book a Free Trail Class today!" />
         </Helmet>
        )
      }


    }

    return (
      <>
      {handleMetaTags()}
        <div className="root-outer">
          <Grid className="relative" container>
            <Grid className="Competitive-container"
              item
              xs={12}
              
            >
              <Reveal effect="fadeInUp">
                <div className="width-70per">
                  <Grid container>
                    <Grid className="Competetive-info-left"
                      item
                      xs={12}
                      sm={8}
                      md={8}
                     
                    >
                      <Typography variant="h1" className="Lets-Competetive-Ex" >
                        Competitive Exam
                        {/* Let’s <span className="text-style-1">Competitive</span>{" "}
                      Exams with Teachze */}
                      </Typography>

                      <Typography className="textlabel_description_1">
                        With comprehensive learning and innovative learning
                        solutions, ace the competitive exams.
                      </Typography>

                      <Grid className="Search-Bg-Rectangle">
                        <div className="Search-Rectangle">
                          <img title="Search for course"
                            src={bitmap1}
                            alt="Search icon"
                            className="Search-Img"
                          />
                        </div>
                        <input
                          placeholder="Search for course"
                          className="Search-for-course"
                          value={this.state.inputText}
                          onChange={(e) => {
                            this.setState({ inputText: e.target.value });
                            if (
                              e.target.value === "" ||
                              e.target.value === null
                            ) {
                              this.setState({
                                filteredTabsList: tabsList,
                                selectedIndex: 0,
                              });
                            }
                          }}
                        />
                        <Button
                          className="Search-Btn"
                          onClick={this.searchCourseClick}
                        >
                          <Typography className="Search-Text">
                            Search
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <img
                        src={onlineLearning}
                        alt="Student learning" title="Competitive Exam"
                        className="online-learning-img"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Reveal>
            </Grid>

            <Grid className="justifyContent-center"
              item
              xs={12}
              
            >
              <div className="width-70per">
                <Grid container>
                  <Reveal effect="fadeInUp">
                    <Typography  variant="h2" className="Courses">Courses</Typography>
                  </Reveal>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={3}>
                        <Reveal effect="fadeInLeft left cascade">
                          <div className="Courses-Rectangle">
                            <Grid container>
                              <Grid className="xs-4"
                                item
                                xs={4}
                                md={4}
                                
                              >
                                <img src={government} className="Courses-img" title="Competetive Exam" alt="image competetive selected" />
                              </Grid>
                              <Grid item xs={8} md={8}>
                                <Typography
                                  className="Courses-Text"
                                  style={{ color: "#5580ea" }}
                                  onClick={() =>
                                    this.props.handleSelect(
                                      "/Competitive-exams",
                                      1
                                    )
                                  }
                                >
                                  Competitive Exam
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid container>
                              <Grid className="xs-4"
                                item
                                xs={4}
                                md={4}
                               
                              >
                                <img
                                  src={presentation}
                                  className="Courses-img" title="Tuitions" alt="image tuitions"
                                />
                              </Grid>
                              <Grid item xs={8} md={8}>
                                <Typography
                                  className="Courses-Text"
                                  onClick={() =>
                                    this.props.handleSelect("/Tutions", 2)
                                  }
                                >
                                  Tuitions
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid container>
                              <Grid className="xs-4"
                                item
                                xs={4}
                                md={4}
                                
                              >
                                <img src={exam} className="Courses-img" title="Test Series" alt="image test" />
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                md={8}
                                onClick={() =>
                                  this.props.handleSelect("/Test-series", 3)
                                }
                              >
                                <Typography className="Courses-Text">
                                  Test Series
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                        </Reveal>
                      </Grid>
                      <Grid item xs={12} sm={12} md={9}>
                        <Reveal effect="fadeInRight right cascade">
                          <Grid container direction="row">
                            {selectedIndex === -1 ? (
                              <Grid>
                                <Typography
                                
                                >
                                  No course found.
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid className="Competetive-info-left"
                                item
                                xs={12}
                               
                              >
                                <Tabs
                                  // value={"resources"}
                                  // hidden={value !== index}
                                  textColor="secondary"
                                  indicatorColor="secondary"
                                  aria-label="secondary tabs example"
                                  variant="scrollable"
                                  scrollButtons="auto"
                                  TabScrollButtonProps={{
                                    style: {
                                      background: "#5580ea",
                                      color: "white",
                                      width: "30px",
                                      height: "30px",
                                      msTransform: "skewX(-6deg)",
                                      transform: "skewX(-6deg)",
                                      borderRadius: 5,
                                      margin: "0 5px",
                                      alignSelf: "center",
                                      opacity: 1,
                                    },
                                  }}
                                >
                                  {filteredTabsList.map((item: any) => (
                                    <Grid className="tabslist"
                                      style={{
                                       
                                        backgroundColor:
                                          item.key === selectedIndex
                                            ? "#5580ea"
                                            : "#ecefff",
                                        
                                      }}
                                      key={item.key}
                                      onClick={() => {
                                        this.handleSelectTab(
                                          item.value,
                                          item.key
                                        );
                                      }}
                                    >
                                      <Typography className="innertabs"
                                        style={{
                                         
                                          color:
                                            item.key === selectedIndex
                                              ? "white"
                                              : "#5580ea",
                                        }}
                                      >
                                        {item.label}
                                      </Typography>
                                    </Grid>
                                  ))}
                                </Tabs>
                              </Grid>
                            )}
                          </Grid>

                          {selectedIndex === 0 || selectedIndex === 1 ? (
                            <>
                              <Grid container>
                                <Typography variant="h3" 
                                  className="Courses-Header"
                                  style={{ cursor: "pointer" }}
                                >
                                  NEET
                                </Typography>
                              </Grid>

                              {/* <Grid container direction="row">
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Grid container justify="space-around"> */}

                              {/* </Grid> */}
                              {/* </Grid> */}
                              {/* </Grid> */}
                              <Slider
                                className="tiltImportant landing-carousel"
                                {...settings}
                              >
                                {courseNeetDetails &&
                                  courseNeetDetails.map((item: any) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      className="paddingRight20"
                                    >
                                      <Grid
                                        className="Courses-Details"
                                        onClick={() =>
                                          HISTORY.push({
                                            pathname: "/CourseDetails",
                                            state: {
                                              program: "NEET Course",
                                              courseInfo: item.courseDetails,
                                              courseName: item.courseName,
                                              testimonials: [
                                                ...this.state.userDetails,
                                              ],
                                              isFrom: "competitive",
                                            },
                                          })
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <img className="image-course" title="NEET" alt="image course"
                                          src={item.imgSource}
                                        ></img>
                                        <Typography variant="h4" className="Course-Name">
                                          {item.courseName}
                                        </Typography>
                                        <Grid
                                       
                                          className="less-margin-small-screen"
                                        >
                                          <Typography className="Know-More-Text">
                                            Know More
                                          </Typography>
                                          <img className="Arrows" alt="Arrow"
                                            src={rightArrow} 
                                          ></img>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))}
                              </Slider>
                            </>
                          ) : (
                            <></>
                          )}

                          {selectedIndex === 0 || selectedIndex === 2 ? (
                            <>
                              <Grid container>
                                <Typography variant="h3"
                                  className="Courses-Header"
                                  style={{ cursor: "pointer" }}
                                >
                                  JEE
                                </Typography>
                              </Grid>

                              {/* <Grid container direction="row">
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Grid container justify="space-around">
                                   
                                  </Grid>
                                </Grid>
                              </Grid> */}
                              <Slider
                                className="tiltImportant landing-carousel"
                                {...settings}
                              >
                                {courseDetailsJEE &&
                                  courseDetailsJEE.map((item: any) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      className="paddingRight20"
                                    >
                                      <Grid
                                        className="Courses-Details"
                                        onClick={() =>
                                          HISTORY.push({
                                            pathname: "/CourseDetails",
                                            state: {
                                              program: "JEE Course",
                                              courseInfo: item.courseDetails,
                                              courseName: item.courseName,
                                              isFrom: "competitive",
                                              testimonials: [
                                                ...this.state.userDetails,
                                              ],
                                            },
                                          })
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <img className="image-course" title="JEE Course" alt="JEE image"
                                          src={item.imgSource}
                                          
                                        ></img>
                                        <Typography variant="h4" className="Course-Name">
                                          {item.courseName}
                                        </Typography>
                                        <Grid
                                         
                                          className="less-margin-small-screen"
                                        >
                                          <Typography className="Know-More-Text">
                                            Know More
                                          </Typography>

                                          <img className="Arrows" alt="Arrow"
                                            src={rightArrow}
                                           
                                          ></img>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))}
                              </Slider>
                            </>
                          ) : (
                            <></>
                          )}

                          {selectedIndex === 0 || selectedIndex === 3 ? (
                            <>
                              <Grid container>
                                <Typography variant="h3"
                                  className="Courses-Header"
                                 
                                >
                                  CA
                                </Typography>
                              </Grid>
                              <Grid container direction="row">
                                <Grid className="direction-row"
                                  item
                                  xs={12}
                                 
                                >
                                  <Grid container>
                                    {courseDetailsCA &&
                                      courseDetailsCA.map((item: any) => (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          className="paddingRight20"
                                        >
                                          <Grid
                                            className="Courses-Details"
                                            onClick={() =>
                                              HISTORY.push({
                                                pathname: "/CourseDetails",
                                                state: {
                                                  program: "CA Course",
                                                  courseInfo:
                                                    item.courseDetails,
                                                  courseName: item.courseName,
                                                  testimonials: [
                                                    ...this.state.caUserDetails,
                                                  ],
                                                  isFrom: "competitive",
                                                },
                                              })
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img className="image-course" title="CA Course" alt="CA image"
                                              src={item.imgSource}
                                             
                                            ></img>
                                            <Typography variant="h4" className="Course-Name">
                                              {item.courseName}
                                            </Typography>
                                            <Grid
                                            
                                              className="less-margin-small-screen"
                                            >
                                              <Typography className="Know-More-Text">
                                                Know More
                                              </Typography>
                                              <img className="Arrows" alt="Arrow"
                                                src={rightArrow}
                                              
                                              ></img>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          {selectedIndex === 0 || selectedIndex === 4 ? (
                            <>
                              <Grid container>
                                <Typography variant="h3"
                                  className="Courses-Header"
                                  
                                >
                                  Foundation
                                </Typography>
                              </Grid>
                              <Grid container direction="row">
                                <Grid className="direction-row"
                                  item
                                  xs={12}
                                  
                                >
                                  <Grid container>
                                    {courseDetailsFoundation &&
                                      courseDetailsFoundation.map(
                                        (item: any) => (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            className="paddingRight20"
                                          >
                                            <Grid
                                              className="Courses-Details"
                                              onClick={() =>
                                                HISTORY.push({
                                                  pathname: "/CourseDetails",
                                                  state: {
                                                    program:
                                                      "Foundation Course",

                                                    courseInfo:
                                                      item.courseDetails,
                                                    courseName: item.courseName,
                                                    admission: item.admission,
                                                    testimonials: [
                                                      ...this.state
                                                        .foundationUserDetails,
                                                    ],
                                                    isFrom: "competitive",
                                                  },
                                                })
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <img className="image-course" title="Foundation Course" alt="Foundation image"
                                                src={item.imgSource}
                                               
                                              ></img>
                                              <Typography variant="h4" className="Course-Name">
                                                {item.courseName}
                                              </Typography>
                                              <Grid
                                              
                                                className="less-margin-small-screen"
                                              >
                                                <Typography className="Know-More-Text">
                                                  Know More
                                                </Typography>
                                                <img className="Arrows" alt="Arrow"
                                                  src={rightArrow}
                                                 
                                                ></img>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        )
                                      )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                        </Reveal>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {selectedIndex === 0 && (
              <Grid item xs={12} className="spacing-100"></Grid>
            )}
            {selectedIndex === 1 && (
              <>
                <Grid
                  item
                  xs={12}
                  className="spacing-100 course-highlight"
                  
                >
                  <div className="width-70per">
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="Course-Highlights-card">
                          <Reveal effect="fadeInUp">
                            <Grid className="Competetive-info-left"
                              container
                            >
                              <Typography variant="h2" className="Course-Highlights">
                                Course Highlights
                              </Typography>
                              <Typography variant="h3" className="Get-assistance-text">
                                Get assistance in structured learning process
                                from expert faculty
                              </Typography>

                              <Grid
                                container
                                spacing={7}
                                alignItems="center"
                                style={{ marginTop: "20px" }}
                              >
                                {courseHighlights &&
                                  courseHighlights.map((item: any) => (
                                    <Grid item md={4}>
                                      <img className="image-faculty" alt="image faculty"
                                        src={item.image}
                                        
                                      ></img>
                                      <Typography
                                        style={{                                        
                                          width:
                                            item.text ===
                                            "Doubt resolution NEET experts"
                                              ? "60%"
                                              : "100%",
                                        }}
                                        className="md-font-18 xs-font-16 faculty-info text-left"
                                      >
                                        {item.text}
                                      </Typography>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                          </Reveal>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="spacing-100 Competitive-container-info"
                 
                >
                  <div className="width-70per">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <Reveal effect="fadeInLeft left cascade">
                          <img title="About NEET" alt="about image" src={aboutneet} style={{ width: "80%" }}></img>
                        </Reveal>
                      </Grid>
                      <Grid className="Competetive-info-left"
                        item
                        xs={12}
                        sm={12}
                        md={6}
                       
                      >
                        <Reveal effect="fadeInRight right cascade">
                          <Typography variant="h2" className="About-NEET-Text">
                            About NEET
                          </Typography>
                          <Typography className="National-Eligibility-Text">
                            The National Eligibility-cum-Entrance Test (NEET-UG)
                            for admission to the undergraduate medical education
                            will be conducted by the NTA (National Testing
                            Agency). A successful attempt at NEET enables
                            students to avail a seat in any of the medical
                            programmes such as MBBS, BDS, AYUSH, Veterinary and
                            other medical/paramedical courses offered in both
                            government and private institutes of India.
                            {/* The National Eligibility-cum-Entrance Test (NEET-UG) 2021
                      for admission to the undergraduate medical education will
                      be conducted by the NTA (National Testing Agency). */}
                          </Typography>
                          <Typography className="National-Eligibility-Text">
                            {/* A successful attempt at NEET enables students to avail a
                      seat in any of the medical programmes such as MBBS, BDS
                      AYUSH, Veterinary and other medical/paramedical courses
                      offered in both government and private <span className="text-style-10">KNOW MORE</span> */}
                            NEET examination tests the conceptual understanding
                            of the student in the subjects of Physics, Chemistry
                            and Biology. The exam will be based on the common
                            syllabus notified by the Medical Council of India
                            from these subjects{" "}
                          </Typography>
                          <Button
                            // style={{
                            //   maxWidth: "190px",
                            //   height: "60px",
                            //   textAlign: "center",
                            //   color: " #fff",
                            //   background: "#5580ea",
                            //   msTransform: "skewX(-6deg)",
                            //   transform: "skewX(-6deg)",
                            //   marginTop: 30,
                            // }}
                            className="book-trial-btn-small"
                            onClick={this.handleOpenZohoFormPopup}
                          >
                            <Typography
                             
                              className="md-font-18 xs-font-16 start-learning"
                            >
                              Start Learning
                            </Typography>
                          </Button>
                        </Reveal>
                      </Grid>
                    </Grid>

                    {/* <Grid container style={{ justifyContent: "center" }}>
                  <Reveal effect="fadeInUp">
                    <Typography className="Teachze-is-a-pioneer-text">
                      Teachze is a pioneer to provide a comprehensive Virtual
                      Class for the NEET aspirants.
                    </Typography>
                    <img src={group38} style={{ width: "100%" }}></img>
                  </Reveal>
                </Grid> */}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="width-70per">
                    <Reveal effect="fadeInUp">
                      <Grid
                        container
                        className="spacing-100"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography variant="h2" className="Course-Highlights">
                          Why Teachze?
                        </Typography>
                        <Typography variant="h3"
                          className="Get-assistance-text"
                          style={{ margin: "2% 0" }}
                        >
                          Get assistance in structured learning process from
                          expert faculty
                        </Typography>
                        <Grid item xs={12} style={{ marginTop: "2%" }}>
                          <Grid container spacing={6}>
                            {teachzePoints &&
                              teachzePoints.map((item: any) => (
                                <Grid item md={6}>
                                  <Grid className="direction-row"
                                    container
                                   
                                  >
                                    <Grid item xs={2} sm={2} md={2} lg={1}>
                                      <img className="Arrows"
                                        src={item.imgSource}
                                        
                                      ></img>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={10}
                                      sm={10}
                                      md={10}
                                      lg={11}
                                      style={{ paddingLeft: "1%" }}
                                    >
                                      <Typography
                                        
                                        className="md-font-20 sm-font-18 small-title"
                                      >
                                        {item.header}
                                      </Typography>
                                      <Typography
                                       
                                        className="xs-font-14 small-title small-title1"
                                      >
                                        {item.text}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Reveal>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="spacing-100 Competitive-container-info"
                 
                >
                  <Grid container>
                    <Grid className="pl"
                      item
                      xs={12}
                      sm={12}
                      md={8}
                     
                    >
                      <Reveal effect="fadeInLeft left cascade">
                        <div className="padding-right">
                          <Typography variant="h2" className="About-NEET-Text">
                            {/* Learn Using Specialized learning Management System */}
                            Bringing the classroom to you
                          </Typography>
                          <Typography variant="h3" className="This-platform-will-text">
                            Studies and Important Updates on the go. Now, don't
                            miss any Live Sessions or Mock Test Series. Indeed,
                            quality education is at your fingertips.
                          </Typography>
                          <ul>
                            <li className="view_box_content_text_li_2">
                              All Devices
                            </li>
                            <li className="ulList_2">
                              Get unlimited learning and study content from any
                              device, including mobile, tablet, and laptop.
                            </li>
                            <li className="view_box_content_text_li_2">
                              Track Your Progress
                            </li>
                            <li className="view_box_content_text_li_2">
                              Anywhere Access
                            </li>
                          </ul>
                          <Typography className="This-platform-will-text">
                            {/* Our Goal is to take our Coaching Classes to the students
                      wherever they live, irrespective of their geographical
                      location and enable them to study and prepare for the NEET
                      exam to fulfill their aspirations of being a Doctor. */}
                          </Typography>
                          <Button
                            // style={{
                            //   maxWidth: "210px",
                            //   height: "60px",
                            //   textAlign: "center",
                            //   color: " #fff",
                            //   background: "#5580ea",
                            //   msTransform: "skewX(-6deg)",
                            //   transform: "skewX(-6deg)",
                            //   marginTop: 23,
                            // }}
                            className="book-trial-btn-small"
                            onClick={this.handleOpenZohoFormPopup}
                          >
                            <Typography className="start-learning"
                           
                            >
                              Start Learning
                            </Typography>
                          </Button>
                        </div>
                      </Reveal>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      className="bitmap-img-align"
                    >
                      <Reveal effect="fadeInRight right cascade">
                        <img alt="bitmap"
                          src={developerActivity}
                          className="bitmap-img"
                        ></img>
                      </Reveal>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className="dis-jcenter ptop3"
                  item
                  xs={12}
                 
                >
                  <div className="width-70per">
                    <Grid className="relative" container>
                      <Reveal effect="fadeInUp">
                        <img src={quote} className="quote-img"></img>
                        <Typography variant="h2" className="Hear-from-text">
                          Hear from our students and their parents
                        </Typography>
                      </Reveal>
                    </Grid>
                    {/* <Grid container style={{justifyContent:"flex-end"}}>
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <div className="blueTiltSquare"></div>
                  <div className="lightBlueTiltSquare"></div>
                  <div className="lightBlueTiltSquare"></div>
                </Grid>
              </Grid> */}

                    <Slider
                      className="tiltImportant landing-carousel"
                      {...settings}
                    >
                      {/*<Grid container justify="space-around" style={{ padding: "50px 0 100px 0"}}> */}
                      {userDetails &&
                        userDetails.map((item: any) => (
                          <Grid style={{ padding: "5px" }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              className="view_box"
                              style={{ height: "400px" }}
                            >
                              <Grid container>
                                {/* <Grid item xs={4} sm={4} md={4}>
                                  <img
                                    src={item.imgSource}
                                    className="view_img"
                                  ></img>
                                </Grid> */}
                                <Grid  className="col50"
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                 
                                >
                                  <Typography className="view_name">
                                    {item.name}
                                  </Typography>
                                  <Typography className="view_parent">
                                    {item.role}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <div className="view_line"></div>
                              <Typography className="view_text">
                                {item.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      {/* </Grid>*/}
                    </Slider>
                  </div>
                </Grid>
              </>
            )}
            {selectedIndex === 2 && (
              <>
                <Grid 
                  item
                  xs={12}
                  className="spacing-100 course-highlight"
              
                >
                  <div className="width-70per">
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="Course-Highlights-card">
                          <Reveal effect="fadeInUp">
                            <Grid className="Competetive-info-left"
                              container
                              
                            >
                              <Typography variant="h2" className="Course-Highlights">
                                Course Highlights
                              </Typography>
                              <Typography variant="h3" className="Get-assistance-text">
                                Get assistance in structured learning process
                                from expert faculty
                              </Typography>

                              <Grid
                                container
                                spacing={7}
                                alignItems="center"
                                style={{ marginTop: "20px" }}
                              >
                                {courseHighlightsJEE &&
                                  courseHighlightsJEE.map((item: any) => (
                                    <Grid item md={4}>
                                      <img className="item50" alt="image faculty"
                                        src={item.image}
                                        
                                      ></img>
                                      <Typography
                                        style={{
                                         
                                          width:
                                            item.text ===
                                            "Doubt resolution NEET experts"
                                              ? "60%"
                                              : "100%",
                                        }}
                                        className="md-font-18 xs-font-16 faculty-info text-left"
                                      >
                                        {item.text}
                                      </Typography>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                          </Reveal>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="spacing-100 Competitive-container-info"
                 
                >
                  <div className="width-70per">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <Reveal effect="fadeInLeft left cascade">
                          <img title="About NEET" alt="about image" src={aboutneet} style={{ width: "80%" }}></img>
                        </Reveal>
                      </Grid>
                      <Grid className="Competetive-info-left"
                        item
                        xs={12}
                        sm={12}
                        md={6}
                       
                      >
                        <Reveal effect="fadeInRight right cascade">
                          <Typography variant="h2" className="About-NEET-Text">
                            About JEE
                          </Typography>
                          <Typography className="National-Eligibility-Text">
                            Joint Entrance Examination (JEE) is conducted by the
                            National Testing Agency (NTA). The Joint Entrance
                            Examination (JEE) comprises of two papers. The
                            Paper1 (JEE Mains) is conducted for admission to
                            Undergraduate Engineering Programs (B.E/B. Tech) at
                            NITs, IIITs, other Centrally Funded Technical
                            Institutions (CFTIs), Institutions/Universities
                            funded/recognized by participating State
                            Governments, as well as an eligibility test for JEE
                            (Advanced), which is conducted for admission to
                            IITs.
                            {/* The National Eligibility-cum-Entrance Test (NEET-UG) 2021
                      for admission to the undergraduate medical education will
                      be conducted by the NTA (National Testing Agency). */}
                          </Typography>
                          <Typography className="National-Eligibility-Text">
                            {/* A successful attempt at NEET enables students to avail a
                      seat in any of the medical programmes such as MBBS, BDS
                      AYUSH, Veterinary and other medical/paramedical courses
                      offered in both government and private <span className="text-style-10">KNOW MORE</span> */}
                            The JEE (Main) is being conducted in multiple
                            Sessions (February/March/April/May 2021) for
                            admissions in the next academic session. The
                            student’s best of the 2021 NTA Scores will be
                            considered for preparation of Merit List/ Ranking.
                          </Typography>
                          <Button
                            // style={{
                            //   maxWidth: "190px",
                            //   height: "60px",
                            //   textAlign: "center",
                            //   color: " #fff",
                            //   background: "#5580ea",
                            //   msTransform: "skewX(-6deg)",
                            //   transform: "skewX(-6deg)",
                            //   marginTop: 30,
                            // }}
                            className="book-trial-btn-small"
                            onClick={this.handleOpenZohoFormPopup}
                          >
                            <Typography
                           
                              className="md-font-18 xs-font-16 start-learning"
                            >
                              Start Learning
                            </Typography>
                          </Button>
                        </Reveal>
                      </Grid>
                    </Grid>

                    {/* <Grid container style={{ justifyContent: "center" }}>
                  <Reveal effect="fadeInUp">
                    <Typography className="Teachze-is-a-pioneer-text">
                      Teachze is a pioneer to provide a comprehensive Virtual
                      Class for the NEET aspirants.
                    </Typography>
                    <img src={group38} style={{ width: "100%" }}></img>
                  </Reveal>
                </Grid> */}
                  </div>
                </Grid>

                <Grid className="justifyContent-center"
                  item
                  xs={12}
                  
                >
                  <div className="width-70per">
                    <Reveal effect="fadeInUp">
                      <Grid
                        container
                        className="spacing-100 direction-column"
                       
                      >
                        <Typography variant="h2" className="Course-Highlights">
                          Why Teachze?
                        </Typography>
                        <Typography variant="h3"
                          className="Get-assistance-text"
                          style={{ margin: "2% 0" }}
                        >
                          Get assistance in structured learning process from
                          expert faculty
                        </Typography>
                        <Grid item xs={12} style={{ marginTop: "2%" }}>
                          <Grid container spacing={6}>
                            {teachzePoints &&
                              teachzePoints.map((item: any) => (
                                <Grid item md={6}>
                                  <Grid className="direction-row"
                                    container
                                    
                                  >
                                    <Grid item xs={2} sm={2} md={2} lg={1}>
                                      <img className="img-source"
                                        src={item.imgSource}
                                        
                                      ></img>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={10}
                                      sm={10}
                                      md={10}
                                      lg={11}
                                      style={{ paddingLeft: "1%" }}
                                    >
                                      <Typography
                                      
                                        className="md-font-20 sm-font-18 small-title"
                                      >
                                        {item.header}
                                      </Typography>
                                      <Typography
                                       
                                        className="xs-font-14 small-title small-title1"
                                      >
                                        {item.text}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Reveal>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="spacing-100 Competitive-container-info"
                 
                >
                  <Grid container>
                    <Grid className="pl"
                      item
                      xs={12}
                      sm={12}
                      md={8}
                     
                    >
                      <Reveal effect="fadeInLeft left cascade">
                        <div className="padding-right">
                          <Typography variant="h2" className="About-NEET-Text">
                            {/* Learn Using Specialized learning Management System */}
                            Bringing the classroom to you
                          </Typography>
                          <Typography variant="h3" className="This-platform-will-text">
                            Studies and Important Updates on the go. Now, don't
                            miss any Live Sessions or Mock Test Series. Indeed,
                            quality education is at your fingertips.
                          </Typography>
                          <ul>
                            <li className="view_box_content_text_li_2">
                              All Devices
                            </li>
                            <li className="ulList_2">
                              Get unlimited learning and study content from any
                              device, including mobile, tablet, and laptop.
                            </li>
                            <li className="view_box_content_text_li_2">
                              Track Your Progress
                            </li>
                            <li className="view_box_content_text_li_2">
                              Anywhere Access
                            </li>
                          </ul>
                          <Typography className="This-platform-will-text">
                            {/* Our Goal is to take our Coaching Classes to the students
                      wherever they live, irrespective of their geographical
                      location and enable them to study and prepare for the NEET
                      exam to fulfill their aspirations of being a Doctor. */}
                          </Typography>
                          <Button
                            // style={{
                            //   maxWidth: "210px",
                            //   height: "60px",
                            //   textAlign: "center",
                            //   color: " #fff",
                            //   background: "#5580ea",
                            //   msTransform: "skewX(-6deg)",
                            //   transform: "skewX(-6deg)",
                            //   marginTop: 23,
                            // }}
                            className="book-trial-btn-small"
                            onClick={this.handleOpenZohoFormPopup}
                          >
                            <Typography className="start-learning sm-font-18 text-left"
                              
                            >
                              Start Learning
                            </Typography>
                          </Button>
                        </div>
                      </Reveal>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      className="bitmap-img-align"
                    >
                      <Reveal effect="fadeInRight right cascade">
                        <img alt="bitmap"
                          src={developerActivity}
                          className="bitmap-img"
                        ></img>
                      </Reveal>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className="dis-jcenter ptop3"
                  item
                  xs={12}
                
                >
                  <div className="width-70per">
                    <Grid container style={{ position: "relative" }}>
                      <Reveal effect="fadeInUp">
                        <img src={quote} className="quote-img"></img>
                        <Typography variant="h2" className="Hear-from-text">
                          Hear from our students and their parents
                        </Typography>
                      </Reveal>
                    </Grid>
                    {/* <Grid container style={{justifyContent:"flex-end"}}>
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <div className="blueTiltSquare"></div>
                  <div className="lightBlueTiltSquare"></div>
                  <div className="lightBlueTiltSquare"></div>
                </Grid>
              </Grid> */}

                    <Slider
                      className="tiltImportant landing-carousel"
                      {...settings}
                    >
                      {/*<Grid container justify="space-around" style={{ padding: "50px 0 100px 0"}}> */}
                      {userDetails &&
                        userDetails.map((item: any) => (
                          <Grid style={{ padding: "5px" }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              className="view_box"
                              style={{ height: "400px" }}
                            >
                              <Grid container>
                                {/* <Grid item xs={4} sm={4} md={4}>
                                  <img
                                    src={item.imgSource}
                                    className="view_img"
                                  ></img>
                                </Grid> */}
                                <Grid className="col50"
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                 
                                >
                                  <Typography className="view_name">
                                    {item.name}
                                  </Typography>
                                  <Typography className="view_parent">
                                    {item.role}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <div className="view_line"></div>
                              <Typography className="view_text">
                                {item.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      {/* </Grid>*/}
                    </Slider>
                  </div>
                </Grid>
              </>
            )}
            {selectedIndex === 3 && (
              <>
                <Grid
                  item
                  xs={12}
                  className="spacing-100 course-highlight"
                 
                >
                  <div className="width-70per">
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="Course-Highlights-card">
                          <Reveal effect="fadeInUp">
                            <Grid className="Competetive-info-left"
                              container
                              
                            >
                              <Typography variant="h2" className="Course-Highlights">
                                Course Highlights
                              </Typography>
                              <Typography variant="h3" className="Get-assistance-text">
                                Get assistance in structured learning process
                                from expert faculty
                              </Typography>

                              <Grid
                                container
                                spacing={7}
                                alignItems="center"
                                style={{ marginTop: "20px" }}
                              >
                                {courseHighlightsCA &&
                                  courseHighlightsCA.map((item: any) => (
                                    <Grid item md={4}>
                                      <img className="image-faculty"
                                        src={item.image}
                                        
                                      ></img>
                                      <Typography
                                        style={{
                                         
                                          width:
                                            item.text ===
                                            "Doubt resolution NEET experts"
                                              ? "60%"
                                              : "100%",
                                        }}
                                        className="md-font-18 xs-font-16 faculty-info text-left"
                                      >
                                        {item.text}
                                      </Typography>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                          </Reveal>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="spacing-100 Competitive-container-info"
                 
                >
                  <div className="width-70per">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <Reveal effect="fadeInLeft left cascade">
                          <img src={aboutneet} style={{ width: "80%" }}></img>
                        </Reveal>
                      </Grid>
                      <Grid className="Competetive-info-left"
                        item
                        xs={12}
                        sm={12}
                        md={6}
                       
                      >
                        <Reveal effect="fadeInRight right cascade">
                          <Typography variant="h2" className="About-NEET-Text">
                            About CA
                          </Typography>
                          <Typography className="National-Eligibility-Text">
                            Our courses provide complete coverage of the topics
                            in line with ICAI (Institute of Chartered
                            Accountants of India) exam syllabus to make you best
                            prepared in all four subjects for the foundation
                            examination and give ample time and guidance for the
                            students to prepare to face the examination. We
                            provide structured learning and robust practice by
                            CA professional faculty Members. We conduct
                            topic-wise and comprehensive test series and
                            evaluate your progress to enhance exam preparation.{" "}
                            {/* The National Eligibility-cum-Entrance Test (NEET-UG) 2021
                      for admission to the undergraduate medical education will
                      be conducted by the NTA (National Testing Agency). */}
                          </Typography>
                          <Typography className="National-Eligibility-Text">
                            {/* A successful attempt at NEET enables students to avail a
                      seat in any of the medical programmes such as MBBS, BDS
                      AYUSH, Veterinary and other medical/paramedical courses
                      offered in both government and private <span className="text-style-10">KNOW MORE</span> */}
                          </Typography>
                          <Button
                            // style={{
                            //   maxWidth: "190px",
                            //   height: "60px",
                            //   textAlign: "center",
                            //   color: " #fff",
                            //   background: "#5580ea",
                            //   msTransform: "skewX(-6deg)",
                            //   transform: "skewX(-6deg)",
                            //   marginTop: 30,
                            // }}
                            className="book-trial-btn-small"
                            onClick={this.handleOpenZohoFormPopup}
                          >
                            <Typography
                             
                              className="md-font-18 xs-font-16 start-learning"
                            >
                              Start Learning
                            </Typography>
                          </Button>
                        </Reveal>
                      </Grid>
                    </Grid>

                    {/* <Grid container style={{ justifyContent: "center" }}>
                  <Reveal effect="fadeInUp">
                    <Typography className="Teachze-is-a-pioneer-text">
                      Teachze is a pioneer to provide a comprehensive Virtual
                      Class for the NEET aspirants.
                    </Typography>
                    <img src={group38} style={{ width: "100%" }}></img>
                  </Reveal>
                </Grid> */}
                  </div>
                </Grid>

                <Grid className="justifyContent-center"
                  item
                  xs={12}
                 
                >
                  <div className="width-70per">
                    <Reveal effect="fadeInUp">
                      <Grid
                        container
                        className="spacing-100 direction-column"
                       
                      >
                        <Typography variant="h2" className="Course-Highlights">
                          Why Teachze?
                        </Typography>
                        <Typography variant="h3"
                          className="Get-assistance-text"
                          style={{ margin: "2% 0" }}
                        >
                          Get assistance in structured learning process from
                          expert faculty
                        </Typography>
                        <Grid item xs={12} style={{ marginTop: "2%" }}>
                          <Grid container spacing={6}>
                            {teachzePoints &&
                              teachzePoints.map((item: any) => (
                                <Grid item md={6}>
                                  <Grid className="direction-row"
                                    container
                                   
                                  >
                                    <Grid item xs={2} sm={2} md={2} lg={1}>
                                      <img className="img-source"
                                        src={item.imgSource}
                                       
                                      ></img>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={10}
                                      sm={10}
                                      md={10}
                                      lg={11}
                                      style={{ paddingLeft: "1%" }}
                                    >
                                      <Typography
                                       
                                        className="md-font-20 sm-font-18 small-title"
                                      >
                                        {item.header}
                                      </Typography>
                                      <Typography
                                        
                                        className="xs-font-14 small-title small-title1"
                                      >
                                        {item.text}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Reveal>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="spacing-100 Competitive-container-info"
                  
                >
                  <Grid container>
                    <Grid className="pl"
                      item
                      xs={12}
                      sm={12}
                      md={8}
                     
                    >
                      <Reveal effect="fadeInLeft left cascade">
                        <div className="padding-right">
                          <Typography variant="h2" className="About-NEET-Text">
                            {/* Learn Using Specialized learning Management System */}
                            Bringing the classroom to you
                          </Typography>
                          <Typography variant="h3" className="This-platform-will-text">
                            Studies and Important Updates on the go. Now, don't
                            miss any Live Sessions or Mock Test Series. Indeed,
                            quality education is at your fingertips.
                          </Typography>
                          <ul>
                            <li className="view_box_content_text_li_2">
                              All Devices
                            </li>
                            <li className="ulList_2">
                              Get unlimited learning and study content from any
                              device, including mobile, tablet, and laptop.
                            </li>
                            <li className="view_box_content_text_li_2">
                              Track Your Progress
                            </li>
                            <li className="view_box_content_text_li_2">
                              Anywhere Access
                            </li>
                          </ul>
                          <Typography className="This-platform-will-text">
                            {/* Our Goal is to take our Coaching Classes to the students
                      wherever they live, irrespective of their geographical
                      location and enable them to study and prepare for the NEET
                      exam to fulfill their aspirations of being a Doctor. */}
                          </Typography>
                          <Button
                            // style={{
                            //   maxWidth: "210px",
                            //   height: "60px",
                            //   textAlign: "center",
                            //   color: " #fff",
                            //   background: "#5580ea",
                            //   msTransform: "skewX(-6deg)",
                            //   transform: "skewX(-6deg)",
                            //   marginTop: 23,
                            // }}
                            className="book-trial-btn-small"
                            onClick={this.handleOpenZohoFormPopup}
                          >
                            <Typography className="start-learning md-font-18 text-left"
                             
                            >
                              Start Learning
                            </Typography>
                          </Button>
                        </div>
                      </Reveal>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      className="bitmap-img-align"
                    >
                      <Reveal effect="fadeInRight right cascade">
                        <img aria-label="bitmap"
                          src={developerActivity}
                          className="bitmap-img"
                        ></img>
                      </Reveal>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className="dis-jcenter ptop3"
                  item
                  xs={12}
                 
                >
                  <div className="width-70per">
                    <Grid container style={{ position: "relative" }}>
                      <Reveal effect="fadeInUp">
                        <img src={quote} className="quote-img"></img>
                        <Typography variant="h2" className="Hear-from-text">
                          Hear from our students and their parents
                        </Typography>
                      </Reveal>
                    </Grid>
                    {/* <Grid container style={{justifyContent:"flex-end"}}>
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <div className="blueTiltSquare"></div>
                  <div className="lightBlueTiltSquare"></div>
                  <div className="lightBlueTiltSquare"></div>
                </Grid>
              </Grid> */}

                    <Slider
                      className="tiltImportant landing-carousel"
                      {...settings}
                    >
                      {/*<Grid container justify="space-around" style={{ padding: "50px 0 100px 0"}}> */}
                      {caUserDetails &&
                        caUserDetails.map((item: any) => (
                          <Grid style={{ padding: "5px" }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              className="view_box"
                              style={{ height: "400px" }}
                            >
                              <Grid container>
                                {/* <Grid item xs={4} sm={4} md={4}>
                                  <img
                                    src={item.imgSource}
                                    className="view_img"
                                  ></img>
                                </Grid> */}
                                <Grid className="col50"
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                 
                                >
                                  <Typography className="view_name">
                                    {item.name}
                                  </Typography>
                                  <Typography className="view_parent">
                                    {item.role}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <div className="view_line"></div>
                              <Typography className="view_text">
                                {item.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      {/* </Grid>*/}
                    </Slider>
                  </div>
                </Grid>
              </>
            )}
            {selectedIndex === 4 && (
              <>
                <Grid
                  item
                  xs={12}
                  className="spacing-100 course-highlight"
                 
                >
                  <div className="width-70per">
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="Course-Highlights-card">
                          <Reveal effect="fadeInUp">
                            <Grid className="justifyContent-center direction-column"
                              container
                             
                            >
                              <Typography variant="h2" className="Course-Highlights">
                                Course Highlights
                              </Typography>
                              <Typography variant="h3" className="Get-assistance-text">
                                Get assistance in structured learning process
                                from expert faculty
                              </Typography>

                              <Grid
                                container
                                spacing={7}
                                alignItems="center"
                                style={{ marginTop: "20px" }}
                              >
                                {courseHighlightsPreFoundation &&
                                  courseHighlightsPreFoundation.map(
                                    (item: any) => (
                                      <Grid item md={4}>
                                        <img className="image-faculty"
                                          src={item.image}
                                         
                                        ></img>
                                        <Typography
                                          style={{
                                           
                                            width:
                                              item.text ===
                                              "Doubt resolution NEET experts"
                                                ? "60%"
                                                : "100%",
                                          }}
                                          className="md-font-18 xs-font-16 faculty-info text-left"
                                        >
                                          {item.text}
                                        </Typography>
                                      </Grid>
                                    )
                                  )}
                              </Grid>
                            </Grid>
                          </Reveal>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="spacing-100 Competitive-container-info"
                  
                >
                  <div className="width-70per">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <Reveal effect="fadeInLeft left cascade">
                          <img src={aboutneet} style={{ width: "80%" }}></img>
                        </Reveal>
                      </Grid>
                      <Grid className="justifyContent-center direction-column"
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        
                      >
                        <Reveal effect="fadeInRight right cascade">
                          <Typography variant="h2" className="About-NEET-Text">
                            About Foundation
                          </Typography>
                          <Typography className="National-Eligibility-Text">
                            Every successful thing needs a firm foundation. As
                            cracking competitive exams like NEET and JEE is
                            challenging, students need to prepare for such exams
                            from an early age. Our Foundation Programs for JEE
                            and NEET cater to this specific need by ensuring
                            strong fundamentals.{" "}
                            {/* The National Eligibility-cum-Entrance Test (NEET-UG) 2021
                      for admission to the undergraduate medical education will
                      be conducted by the NTA (National Testing Agency). */}
                          </Typography>
                          <Typography className="National-Eligibility-Text">
                            {/* A successful attempt at NEET enables students to avail a
                      seat in any of the medical programmes such as MBBS, BDS
                      AYUSH, Veterinary and other medical/paramedical courses
                      offered in both government and private <span className="text-style-10">KNOW MORE</span> */}
                            Preparing from an early stage also provides ample
                            time for students to understand the complicated
                            aspects of topics. Teachze Foundation program
                            provides strong support to help students get
                            familiar with various aspects of the relevant exams,
                            as well as the associated careers. Our Foundation
                            Programs helps students to have better conceptual
                            understanding and develop the application skill,
                            Computational thinking and Scientific temperament.
                            As a result, every student becomes a strong
                            contender not only for the Board examinations but
                            also for competitive exams like NTSE, Olympiads,
                            KVPY, etc.{" "}
                          </Typography>
                          <Button
                            // style={{
                            //   maxWidth: "210px",
                            //   height: "60px",
                            //   textAlign: "center",
                            //   color: " #fff",
                            //   background: "#5580ea",
                            //   msTransform: "skewX(-6deg)",
                            //   transform: "skewX(-6deg)",
                            //   marginTop: 23,
                            // }}
                            className="book-trial-btn-small"
                            onClick={this.handleOpenZohoFormPopup}
                          >
                            <Typography className="start-learning md-font-18 text-left"
                             
                            >
                              Start Learning
                            </Typography>
                          </Button>
                        </Reveal>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={4} className="bitmap-img-align">
                  <Reveal effect="fadeInRight right cascade">
                    <img src={developerActivity} className="bitmap-img"></img>
                  </Reveal>
                </Grid> */}
                    </Grid>
                  </div>
                </Grid>

                <Grid className="justifyContent-center ptop3"
                  item
                  xs={12}
                  
                >
                  <div className="width-70per">
                    <Grid container style={{ position: "relative" }}>
                      <Reveal effect="fadeInUp">
                        <img src={quote} className="quote-img"></img>
                        <Typography variant="h2" className="Hear-from-text">
                          Hear from our students and their parents
                        </Typography>
                      </Reveal>
                    </Grid>
                    {/* <Grid container style={{justifyContent:"flex-end"}}>
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <div className="blueTiltSquare"></div>
                  <div className="lightBlueTiltSquare"></div>
                  <div className="lightBlueTiltSquare"></div>
                </Grid>
              </Grid> */}

                    <Slider
                      className="tiltImportant landing-carousel"
                      {...settings}
                    >
                      {/*<Grid container justify="space-around" style={{ padding: "50px 0 100px 0"}}> */}
                      {this.state.foundationUserDetails &&
                        this.state.foundationUserDetails.map((item: any) => (
                          <Grid style={{ padding: "5px" }}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              className="view_box"
                              style={{ height: "400px" }}
                            >
                              <Grid container>
                                {/* <Grid item xs={4} sm={4} md={4}>
                                  <img
                                    src={item.imgSource}
                                    className="view_img"
                                  ></img>
                                </Grid> */}
                                <Grid className="col50"
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                 
                                >
                                  <Typography className="view_name">
                                    {item.name}
                                  </Typography>
                                  <Typography className="view_parent">
                                    {item.role}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <div className="view_line"></div>
                              <Typography className="view_text">
                                {item.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      {/* </Grid>*/}
                    </Slider>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou open={this.state.open} handleClose={this.handleClose} />
      </>
    );
  }
  handleMetaTags() {
    throw new Error("Method not implemented.");
  }
}

export const competetiveExamPageStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    },
  },
};

export default CompetetiveExamPage;
