import React, { Children } from "react";
import {
  Avatar,
  IconButton,
  Link,
  MenuItem,
  Grid,
  Button,
  Typography,
  Container,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import "./MiniCard.style.css";
import SubjectTags from "../Tags/SubjectTags.web";

type Props = {
  onMouseHover: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
  className ?: string
  imgSrc: string;
  children: React.ReactNode;
};

const MiniCard = (props: Props) => {
  return (
    <>
      <Box
        className={`miniCard ${props.className}`}
        onMouseOver={props.onMouseHover}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
      >
        <Grid container className="grid-padding-2">
          <Grid item xs={12} sm={4} md={4}>
            <img
              src={props.imgSrc}
              alt="Image"
              className="book-img"
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} style={{ alignSelf: "center" }}>
            {
                props.children
            }
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MiniCard;
