import React from "react";
import { withRouter } from 'react-router-dom'
import {
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import BlogPageController from "./BlogController.web";
import {
  search,
  leftArrow,
  rightArrow,
  image_flag_wide
} from '../assets'
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "./BlogPage.web.css";
import { HISTORY } from "../../../../components/src/common";
import { createBrowserHistory } from 'history';
const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;
const settingsForTeacherBlock = {
  dots: false,
  infinite: false,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
  style: {
    padding: "5% 0",
  },
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Reveal = require("react-reveal/Reveal");
class BlogPage extends BlogPageController {

  // Customizable Area Start
  // Customizable Area End
  history = createBrowserHistory();
  render() {
    const push = (category : string, data : any) => {
     
      HISTORY.push(`/Blogdetail/${category}/${data.title}`,  data)
    }
    const { blogs } = this.state;

    return (
      <div style={root}>
        <Helmet>
          <title>Blog | Best LIVE Online Tuitions and Coaching Classes - Teachze</title>
          <meta name="description" content="Know more about Teachze and the services provided in digital learning platform. Give it a read and know how our LIVE Online classes help the students exactly. Get instant updates on Languages and study, technologies and latest news related to online learning and live online classes." />
        </Helmet>
        <Grid container style={{ position: "relative" }}>
          <Grid className="heading-blog"
            item
            xs={12}
            >
            <div className="width-70per">
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Reveal effect="fadeInUp">
                    <div className="pl">
                      <Typography variant="h1" className="textlabel_heading">
                        Teachze Blog
                      </Typography>
                    </div>
                  </Reveal>
                </Grid>
              </Grid>
            </div>

          </Grid>
          <Grid
            item
            xs={12}
          >
            <Reveal effect="fadeInUp">
              <div className="dis-jcenter">
                <div className="width-70per">
                  <Grid container>
                    <Grid item xs={12} sm={8} md={6}>
                      <div className="Search-Bg-Rectangle_blog">
                        <input
                          placeholder="Search Blog.."
                          className="Search-for-course_blog"
                        />
                        <Button variant="contained" color="primary" className="search-Btn-icon">
                          <img title="Search Blog.."
                            src={search}
                            alt="Search icon"
                            className="Search-Img-icon"
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="width-70per">
              <Grid  container style={{ display: "flex"}}>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={10}
                >
                  <Reveal effect="fadeInRight right cascade"><div >
                    <Typography variant="h2" className="textlabel_batches_blog">
                    Languages and study
                    </Typography></div></Reveal>
                </Grid>

                <Grid container item xs={12} >
                  <Reveal effect="fadeInUp">
                    {/* <Slider
                      className="tiltImportant teach-you"
                      {...settingsForTeacherBlock}
                    > */}
                       <Grid container spacing={3} style={{ marginTop : "30px", marginBottom : "30px"}}>
                      {blogs?.length &&
                        blogs[0]["Languages and study"].map((item: any) => (
                             <Grid item xs={12} sm={6} md={4}>
                                <Grid onClick={() => push("Languages and study", item)} className="batch-view-box-blog">
                                  <div className="container">
                                    <img title="Bridging the Educational Inequality" alt="Bridging image"
                                      src={item.imgSource}
                                      style={{
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                    ></img>
                                    <div className="topright">{item.date}</div>
                                  </div>

                                  <Grid className="batch-view-box-blog-content">

                                    <Typography variant="h3" className="textlabel_blog">
                                      {item.title}
                                    </Typography>


                                    {/* <Typography className="textlabel_subtitle_blog">
                                      {item.subTitle}...READ MORE
                                    </Typography> */}

                                    {/* <Grid
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        marginTop: "20px"
                                      }}
                                    >
                                      <img
                                        src={item.authorImg}
                                        style={{
                                          width: 44,
                                          height: 44,
                                          objectFit: "contain",
                                          marginRight: 18,
                                        }}
                                      ></img>
                                      <Grid
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        <Typography className="author_name">
                                          {item.authorName}
                                        </Typography>
                                        <Typography className="author">
                                          Author
                                        </Typography>
                                      </Grid>
                                    </Grid> */}

                                  </Grid>
                                </Grid>
                            </Grid>
                           
                        ))}
                          </Grid>
                    {/* </Slider> */}
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid className="dis-jcenter"
            item
            xs={12}
            
          >
            <div className="width-70per" style={{ position: "relative" }}>
              <Grid className="direction-row" container>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={10}
                >
                  <Reveal effect="fadeInRight right cascade">
                    <div className="direction-column" >
                      <Typography variant="h2" className="textlabel_batches_blog">
                      Technology
                      </Typography>
                    </div>
                  </Reveal>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                >
                  {/* <Reveal effect="fadeInRight right cascade">
                    <div style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}>
                      <Button className="view_viewallbox">
                        <Typography className="textlabel_viewall">
                          View All
                        </Typography>
                      </Button> 
                      
                      </div>
                  </Reveal> */}
                </Grid>
              </Grid>

              <Grid container direction="row" style={{ padding: "3% 0" }}>
                <Grid
                  item
                  xs={12}
                >
                  <Reveal effect="fadeInUp">
                    <div className="direction-row">
                      <Grid container style={{ cursor: "pointer" }}  spacing={3} >
                        {blogs &&
                          blogs[0]["Technology"].map((item: any) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Grid className="batch-view-box-blog" onClick={() => push("Technology", item)}>
                                <div className="container">
                                  <img className="image-course" title="Digitizing Education" alt="Digitizing Education image"
                                    src={item.imgSource}
                                    
                                  ></img>
                                  <div className="topright">{item.date}</div>
                                </div>
                                <Grid className="batch-view-box-blog-content">

                                  <Typography variant="h3" className="textlabel_blog">
                                    {item.title}
                                  </Typography>

                                  {/* <Typography className="textlabel_subtitle_blog">
                                    {item.subTitle}...READ MORE
                                  </Typography> */}

                                  {/* <Grid
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      marginTop: "20px"
                                    }}
                                  >
                                    <img
                                      src={item.authorImg}
                                      style={{
                                        width: 30,
                                        height: 30,
                                        objectFit: "contain",
                                        marginRight: 18,
                                      }}
                                    ></img>
                                    <Grid
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <Typography className="author_name">
                                        {item.authorName}
                                      </Typography>
                                      <Typography className="author">
                                        Author
                                      </Typography>
                                    </Grid>
                                  </Grid> */}

                                </Grid>

                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid className="dis-jcenter"
            item
            xs={12}
            
          >
            <div className="width-70per" style={{ position: "relative" }}>
              <Grid className="direction-row" container>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={10}
                > <Reveal effect="fadeInRight right cascade"> <div className="direction-column">
                  <Typography variant="h3" className="textlabel_batches_blog">
                  Latest News
                  </Typography>
                </div>
                  </Reveal>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                >
                  {/* <Reveal effect="fadeInRight right cascade">
                    <div style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}>
                      <Button className="view_viewallbox">
                        <Typography className="textlabel_viewall">
                          View All
                        </Typography>
                      </Button>
                    </div>
                  </Reveal> */}
                </Grid>
              </Grid>

              <Grid container direction="row" style={{ padding: "3% 0" }}>
                <Grid
                  item
                  xs={12}
                >  <Reveal effect="fadeInUp">
                   

                    <div className="direction-row">
                      <Grid container  spacing={3}>
                        {blogs &&
                          blogs[0]["Latest News"].map((item: any) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Grid className="batch-view-box-blog" onClick={() => push("Latest News", item)} style={{ cursor: "pointer" }}>
                                <div className="container">
                                  <img className="image-course" title="Latest News" alt="Blog image"
                                    src={item.imgSource}
                                   
                                  ></img>
                                  <div className="topright">{item.date}</div>
                                </div>
                                <Grid className="batch-view-box-blog-content">

                                  <Typography variant="h3" className="textlabel_blog">
                                    {item.title}
                                  </Typography>
                                  {/* <Typography className="textlabel_subtitle_blog">
                                    {item.subTitle}...READ MORE
                                  </Typography> */}

                                  {/* <Grid
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      marginTop: "20px"
                                    }}
                                  >
                                    <img
                                      src={item.authorImg}
                                      style={{
                                        width: 30,
                                        height: 30,
                                        objectFit: "contain",
                                        marginRight: 18,
                                      }}
                                    ></img>
                                    <Grid
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <Typography className="author_name">
                                        {item.authorName}
                                      </Typography>
                                      <Typography className="author">
                                        Author
                                      </Typography>
                                    </Grid>
                                  </Grid> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

    );
  }
}

export const BlogPageStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    }
  }
}

export default  withRouter( BlogPage);

