import React from "react";
// Customizable Area Start
import LandingPageController from './LandingPage/LandingPageController.web'
import {
    Box
} from "@material-ui/core";
import LandingPageHeader from "./LandingPage/LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "./LandingPage/LandingPageFooter/LandingPageFooter.web";
import HomePageFooter from "./LandingPage/HomePageFooter.web";
import LandingPage from "./LandingPage/LandingPage.web";
import CompetetiveExamPage from "./CompetetiveExamPage.web"
import TutionPageWeb from "./TutionPage.web";
import TestSeriesPage from "./TestSeriesPage.web";
import "./LandingPage.web.css";
// Customizable Area End
const root = {
    flexGrow: 1,
    overflowX: "hidden",
    position: "relative"
} as const;

export default class TestSeriesMainPage extends LandingPageController {
    // Customizable Area Start
    render() {
        const { mainTabIndex } = this.state
        return (
            <div style={root}>
                <LandingPageHeader mainTabIndex={mainTabIndex} handleSelectMainTab={this.handleSelectMainTab} />
                {/* <Box>{mainTabIndex === 0 &&  */}
                <TestSeriesPage classes={undefined} navigation={undefined} tabIndex={0} handleSelect={this.handleSelectMainTab} />
                {/* <Box>{mainTabIndex === 1 && <CompetetiveExamPage classes={undefined} navigation={undefined} tabIndex={1} handleSelect={this.handleSelectMainTab} />}</Box>
                <Box>{mainTabIndex === 2 && <TutionPageWeb classes={undefined} navigation={undefined} tabIndex={2} handleSelect={this.handleSelectMainTab} />}</Box>
                <Box>{mainTabIndex === 3 && <TestSeriesPage classes={undefined} navigation={undefined} tabIndex={3} handleSelect={this.handleSelectMainTab} />}</Box> */}
                {mainTabIndex === 0 ? <HomePageFooter mainTabIndex={0} handleSelectMainTab={undefined}/> : <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} /> }
            </div>
        );
    }
}


// Customizable Area End
