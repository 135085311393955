import React from "react";
import { Grid, Typography,Dialog,
    DialogContent,
    DialogContentText,  Paper,Button } from "@material-ui/core"
import { customerService } from '../assets'
import './ThankYou.web.css'
import { HISTORY } from "../../../../components/src/common";

function PaperComponent(props: any) {
    return (
      <div >
        <Paper {...props} />
        {/* <Draggable>
        </Draggable> */}
      </div>
    );
  }
const ThankYou = (props:any) => {

    const onClose=()=>{

    }
    return (
        <Dialog
          hideBackdrop
          open={props.open}
          onClose={props.handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          disableBackdropClick
        >
          <DialogContent
           
          >
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={customerService}
                  className="img-customer-service"
                ></img>
                <Typography className="Thankyou-for-sharing">
                  Thankyou for sharing your details.
                </Typography>
                <Typography className="Our-team-would-get-text">
                  Our team would get in touch with you shortly
                </Typography>
                <Button
                  className="ok-btn-small"
                  onClick={async ()=>{
                      await props.handleClose(),
                      await HISTORY.push("/")}}
                >
                  <Typography className="book-trial-text-small">
                    {/* Schedule Your Class! */}
                    Ok
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
    );
};

export default ThankYou;