import React from "react";
import BatchDetailsController from "./BatchDetailsController.web";
import CourseDetailsHeader from "./CourseDetailsHeader.web";
import LandingPageFooter from "../LandingPage/LandingPageFooter/LandingPageFooter.web";
import BatchDetails from "./BatchDetails.web";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;

export default class BatchDetailsMainPage extends BatchDetailsController {
  // Customizable Area Start
  render() {
    return (
      <div style={root}>
        <CourseDetailsHeader navigation={undefined} />
        <BatchDetails navigation={undefined} />
        <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
      </div>
    );
  }
}

// Customizable Area End
