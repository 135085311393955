import React from 'react'
import {
    Avatar,
    IconButton,
    Link,
    MenuItem,
    Grid,
    Button,
    Typography,
    Container,
    Box,
    Dialog,
    DialogActions,
    DialogContent
  } from "@material-ui/core";
import './SubjectTags.style.css'
type Props = {
    text : string;
    className ?: string;
    textClassName?: string;
}

const SubjectTags = (props: Props) => {
  return (
    <Box className={props.className}>
    <Typography
      className={`font-14 subjectTags ${props.textClassName}`}
    >
     {props.text}
    </Typography>
  </Box>
  )
}

export default SubjectTags