import React from 'react'
import "../MiniCard.style.css";
import {
    Grid,
    Button,
    Typography,
    Box,
  } from "@material-ui/core";
  import CountUp from "react-countup";

type Props = {
   
    title : string;
    iconSrc : string;
    quantity : number;
    children ?: React.ReactNode;
    className  ?: string


}

const MiniStatsCard = (props: Props) => {
  return (
    <Grid
    item
    sm={6}
    xs={12}
    md={4}
   
    className="justify-content-center-small-screen"
  >
    <div className="Mask">
      <Grid container>
        <Grid className="icon-center"
          item
          xs={4}
          md={4}
          
        >
          <img src={props.iconSrc} alt="Learning Image" className="kImage" />
        </Grid>
        <Grid className="align-last"
          item
          xs={8}
          md={8}
         
        >
          <Typography variant="h5" className="kHeader">
            {/* {this.state.examTakenCounter} */}
            <CountUp
              end={props.quantity}
              duration={10}
              // formattingFn={(value: number) =>
              //   this.kFormatter(value)
              // }
            />
            {props.children}
          </Typography>

          <Typography className="examTaken">
           {
               props.title
           }
          </Typography>
        </Grid>
      </Grid>
    </div>
  </Grid>
  )
}

export default MiniStatsCard