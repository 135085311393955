import React from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Divider,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { logoSidePlaced, vectorMask, call, email } from "../../assets";
import SocialMedia from "../../SocialMedia.web";
import LinkItem from "../../LinkedItem.web";
import LandingPageHeaderController, {
  Props,
} from "../LandingPageHeaderController.web";
import { HISTORY } from "../../../../../components/src/common";
import ZohoFormModalPopup from "../../ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import { Footer } from "../../Components/Footer/Footer.web";
import CustomButton from "../../Components/CustomButton";
import ThankYou from "../../ThankYou/ThankYou.web";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default class LandingPageFooter extends LandingPageHeaderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {/* Footer DIv */}
        <Box>
          {/* Top Div */}
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              // backgroundColor: "rgb(87,131,231)",
              backgroundImage: `url(${vectorMask})`,
              backgroundSize: "contain",
              width: "100%",
            }}
            p={{ xs: "1.5rem", sm: "3rem", md: "5rem" }}
          >
            {/*
                  Left Div */}
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={8} md={8}>
                    <Typography className="Start-Learning-with-footer"
                      variant="h2"
                    >
                      Teachze, Learn more, achieve more!
                    </Typography>
                    <Typography className="Discover-a-new-way-footer"
                      variant="subtitle2"
                    >
                      Discover a new way of Learning! Welcome to Teachze's unique
                      dynamic and interactive classes within a top-notch educational
                      environment
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} style={{ display: "flex", alignItems: "center" }}>
                    <CustomButton
                    handleClick={this.handleOpenZohoFormPopup}
                    >
                    BOOK A FREE TRIAL
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Box
              style={{
                width: "55%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography className="Start-Learning-with-footer"
                variant="h2"    
              >
                Start Learning with Teachze
              </Typography>
              <Typography className="Discover-a-new-way-footer"
                variant="subtitle2"
            
              >
                Discover a new way of Learning! Welcome to Teachze's unique
                dynamic and interactive classes within a top-notch educational
                environment
              </Typography>
            </Box> */}
            {/* Right Div */}
            {/* <Box
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  padding: "0.8rem",
                  fontWeight: "bold",
                  transform: "skewX(-6deg)",
                  borderRadius: "10px",
                  width: "217px",
                  height: "61px",
                  backgroundColor: "white",
                }}
                className="book-a-free-trial-small-btn"
                variant="outlined"
              >
                <span id="trial" className="BOOK-A-FREE-TRIAL-footer">BOOK A FREE TRIAL</span>
              </Button>
            </Box> */}
          </Box>
          {/*
              Bottom Div */}
       <Footer/>
        </Box>
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou
        open={this.state.open}
        handleClose={this.handleClose}
        />
      </React.Fragment>
    );
  }
}
