import React from "react";
import { MenuItem, Tabs, Tab, Hidden, Typography, Box, Menu, Popper, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuAppBarCollapse from "./MenuAppBarCollapse.web";
import { image_dropdown_arrow, image_dropdown_arrow_blue } from "./assets";
import { HISTORY } from "../../../components/src/common";
import { makeStyles } from '@material-ui/core/styles';

const AppBarCollapse = (props: any) => {
  const { mainTabIndex, handleSelectMainTab } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorElCompExam, setAnchorElCompExam] = React.useState(null);
  const [openCompExamMenu, setOpenCompExamMenu] = React.useState(false);
  const [anchorElTution, setAnchorElTution] = React.useState(null);
  const [openTutionMenu, setOpenTutionMenu] = React.useState(false);
  const [anchorElTestSeries, setAnchorElTestSeries] = React.useState(null);
  const [openTestSeriesMenu, setOpenTestSeriesMenu] = React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOnOverCompExam = (event: any) => {
    setAnchorElCompExam(event.currentTarget);
    setOpenCompExamMenu(true);
  };

  const handleCloseCompExamMenu = (path:any) => {
    setAnchorElCompExam(null);
    setOpenCompExamMenu(false);
    HISTORY.push(path)
  };

  const handleOnOverTution = (event: any) => {
    setAnchorElTution(event.currentTarget);
    setOpenTutionMenu(true);
  };

  const handleCloseTution = () => {
    setAnchorElTution(null);
    setOpenTutionMenu(false);
  };

  const handleOnOverTestSeries = (event: any) => {
    setAnchorElTestSeries(event.currentTarget);
    setOpenTestSeriesMenu(true);
  };

  const handleCloseTestSeries = () => {
    setAnchorElTestSeries(null);
    setOpenTestSeriesMenu(false);
  };

  return (
    <div>
      <MenuAppBarCollapse anchorEl={null} handleSelectMainTab={handleSelectMainTab} mainTabIndex={mainTabIndex}>
      </MenuAppBarCollapse>
      <Hidden smDown>
        <div style={{
          width: "100%",
          background: "transparent"
        }} id="appbar-collapse">
          <Tabs
            value={mainTabIndex}
            style={{ overflow: "inherit" }}
            TabIndicatorProps={{ style: { background: "#5987f5" } }}>
            <Tab label="Home" onClick={() => handleSelectMainTab("/", 0)} />
            <Tab onMouseOver={handleOnOverCompExam} onMouseLeave={handleCloseCompExamMenu}
              onClick={() => {
                HISTORY.push("/Competitive-exams")
              }
              }
              icon={
                <>
                  <span className="header-font">
                    Competitive Exam<img src={mainTabIndex === 1 ? image_dropdown_arrow_blue : image_dropdown_arrow} className="more_dropdown_arrow"></img>
                  </span>
                  <Popper
                    anchorEl={anchorElCompExam}
                    keepMounted
                    open={openCompExamMenu}
                    className="more-item-list"
                  >
                    <Paper>
                      <MenuItem className="more-list-item" onClick={(e) => {
                        e.stopPropagation()
                        handleCloseCompExamMenu("/Competitive-exams/Neet")
                      }}>NEET</MenuItem>
                      <MenuItem className="more-list-item" onClick={(e) => {
                        e.stopPropagation()
                        handleCloseCompExamMenu("/Competitive-exams/Jee")
                      }}>JEE</MenuItem>
                      <MenuItem className="more-list-item" onClick={(e) => {
                        e.stopPropagation()
                        handleCloseCompExamMenu("/Competitive-exams/CA")
                      }}>CA</MenuItem>
                      <MenuItem className="more-list-item" onClick={(e) => {
                        e.stopPropagation()
                        handleCloseCompExamMenu("/Competitive-exams/Foundation")
                      }}>Foundation</MenuItem>
                    </Paper>
                  </Popper>
                </>
              } />
            <Tab onMouseOver={handleOnOverTution} onMouseLeave={handleCloseTution}
              onClick={() =>
                // handleSelectMainTab("/Tutions", 2)
                HISTORY.push("/Tutions")

              }
              icon={
                <>
                  <span className="header-font">
                    Tuitions<img src={mainTabIndex === 2 ? image_dropdown_arrow_blue : image_dropdown_arrow} className="more_dropdown_arrow"></img>
                  </span>
                  <Popper
                    anchorEl={anchorElTution}
                    keepMounted
                    open={openTutionMenu}
                    className="more-item-list"
                  >
                    <Paper>
                      <MenuItem className="more-list-item" onClick={(e) => {
                         e.stopPropagation()
                         handleCloseCompExamMenu("/Tutions/CBSE")
                      }}>CBSE</MenuItem>
                      <MenuItem className="more-list-item" onClick={(e) => {
                          e.stopPropagation()
                          handleCloseCompExamMenu("/Tutions/TN-Board")
                      }}>TN Board</MenuItem>
                      {/* <MenuItem className="more-list-item" onClick={(e) => {
                          e.stopPropagation()
                          handleCloseCompExamMenu("/Tutions/Foundation")
                      }}>Foundation Courses</MenuItem> */}
                    </Paper>
                  </Popper>
                </>
              } />
            <Tab onMouseOver={handleOnOverTestSeries} onMouseLeave={handleCloseTestSeries}
              onClick={() =>
                HISTORY.push("/Test-series")

              }
              icon={
                <>
                  <span className="header-font">
                    Test Series<img src={mainTabIndex === 3 ? image_dropdown_arrow_blue : image_dropdown_arrow} className="more_dropdown_arrow"></img>
                  </span>
                  <Popper
                    anchorEl={anchorElTestSeries}
                    keepMounted
                    open={openTestSeriesMenu}
                    className="more-item-list"
                  >
                    <Paper>
                      <MenuItem className="more-list-item" onClick={(e) => {
                         e.stopPropagation()
                         handleCloseCompExamMenu("/Test-series/Neet")
                      }}>NEET</MenuItem>
                      <MenuItem className="more-list-item" onClick={(e) => {
                         e.stopPropagation()
                         handleCloseCompExamMenu("/Test-series/Jee")
                      }}>JEE</MenuItem>
                    </Paper>
                  </Popper>
                </>
              } />
            <Tab onMouseOver={handleClick} onMouseLeave={handleClose} icon={
              <>
                <span className="header-font">
                  More<img src={mainTabIndex === 4 ? image_dropdown_arrow_blue : image_dropdown_arrow} className="more_dropdown_arrow"></img>
                </span>
                <Popper
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  // onClose={handleClose}
                  // anchorOrigin={{
                  //   vertical: "bottom",
                  //   horizontal: "center"
                  // }}
                  // transformOrigin={{
                  //   vertical: "top",
                  //   horizontal: "center"
                  // }}
                  className="more-item-list"
                >
                  <Paper>
                    <MenuItem className="more-list-item" onClick={() => {
                      handleClose()
                      HISTORY.push('/AboutUs', 4)
                    }}>About Us</MenuItem>
                    <MenuItem className="more-list-item" onClick={() => {
                      handleClose()
                      HISTORY.push('/Faculties', 4)
                    }}>Our Team</MenuItem>
                    <MenuItem className="more-list-item" onClick={() => {
                      handleClose()
                      handleSelectMainTab("/More", 4)
                      HISTORY.push('/Technology')
                    }}>Technology</MenuItem>
                    <MenuItem className="more-list-item" onClick={() => {
                      handleClose()
                      handleSelectMainTab("/More", 4)
                      HISTORY.push('/Blogs')
                    }}>Blogs</MenuItem>
                  </Paper>
                </Popper>
              </>
            } />
          </Tabs>
        </div>
      </Hidden>
    </div>
  )
};

export default AppBarCollapse;

