import React from "react";
import { Typography, Grid, Avatar, Button } from "@material-ui/core";
import AboutUsController from "./AboutUsController.web";
import { workHard, bestOfficeEmployee, rightArrow, leftArrow } from "../assets";
import "./AboutUs.web.css";
import Lottie from "react-lottie-player";
import lottieJson from "../../best-office-employee.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
};
const Reveal = require("react-reveal/Reveal");

class AboutUs extends AboutUsController {
  render() {
    const {
      features,
      selectedForItem,
      forItemsList,
      forStudentList,
      forParentList,
      forSocietyList,
      leadersList,
    } = this.state;
    const cardDisplayBlock = (id: number) => {
      const layerElement = document.getElementById(`layer-id-${id}`);
      if (layerElement) {
        layerElement.style.display = "flex";
      }
    };

    const cardDisplayAbsolute = (id: number) => {
      const layerElement = document.getElementById(`layer-id-${id}`);
      if (layerElement) {
        layerElement.style.display = "none";
      }
    };

    const setSelectedEducator = (item: any) => {
      const { selectedEducator } = this.state;
      selectedEducator.teacherName = item.name;
      selectedEducator.qualifications = item.qualification;
      selectedEducator.experience = item.experience;
      selectedEducator.about = item.about;
      selectedEducator.certifications = item.certifications;
      selectedEducator.achievements = item.achievements;
      selectedEducator.subjects = item.subject;
      selectedEducator.profileImg = item.profileImage;
      selectedEducator.role = item.role;
      selectedEducator.profileDetailsImage = item.profileDetailsImage;
      this.setState({
        isOpenEducatorDetails: true,
        selectedEducator: selectedEducator,
      });
    };

    return (
      <div style={root}>
         <Helmet>
          <title>About | Teachze Live Online Classes | Digital Learning Simplified</title>
          <meta name="description" content="With a vision of bringing high – quality education to the doorstep of the students irrespective of where they are from, Teachze offers face-to-face LIVE online classes and interactive online tuitions. Discover the new way of online learning with comprehensive and innovative eLearning. Learn anything from anywhere at any time with a personalized focus from dedicated master teachers. Book a free trial LIVE online coaching classes for NEET, JEE and CA; LIVE online tuitions for classes 6th – 12th. You can also join online foundation courses – NEET, JEE for Classes 8th. 9th and 10th. " />
        </Helmet>
        <Grid container style={{ position: "relative" }}>
          <Grid className="banner-container"
            item
            xs={12}
            
          >
            <Grid container>
              <Grid className="banner-content-outer"
                item
                xs={12}
                sm={6}
                md={7}
              
              >
                <Reveal effect="fadeInLeft left cascade">
                  <Typography variant="h1" className="textlabel_about_us_heading">
                    <span>Personalized,</span>
                    <br />
                    <span>live and interactive</span> classes right from your
                    home
                  </Typography>
                </Reveal>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Reveal effect="fadeInRight right cascade">
                  {/* <Lottie
                                        loop
                                        animationData={lottieJson}
                                        play
                                        className="img_work_hard"
                                    // style={{ width: 150, height: 150 }}
                                    /> */}
                  <img title="Personalized,
live and interactive classes right from your home" alt="Work Hard" src={workHard} className="img_work_hard"></img>
                </Reveal>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="study-materials"
            item
            xs={12}
           
          >
            <div className="width-70per">
              <Reveal effect="fadeInUp">
                <Grid container className="features_view_box">
                  <Grid item xs={12}>
                    <Typography variant="h2" className="If-a-sporting-activity-text">
                      If a sporting activity or a cinema could reach the home of
                      the students, why not good education?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      {features &&
                        features.map((item: any, index: any) => (
                          <Grid className="view_box-outer"
                            item
                            md={6}
                            
                          >
                            <img alt="Right study materials" title=""
                              src={item.featureImage}
                              className="feature_image_source"
                            ></img>
                            <Typography variant="h4" className="feature_title_text">
                              {item.title}
                            </Typography>
                            <Typography
                              className="feature_desc_text"
                              style={{ marginTop: index === 3 ? "28px" : "" }}
                            >
                              {item.desc}
                            </Typography>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Reveal>
            </div>

            <div className="width-70per">
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className="centered-menu-item"
                >
                  <Reveal effect="fadeInLeft left cascade">
                    <div
                      className={
                        selectedForItem === "student"
                          ? "For-item-selected-title"
                          : "For-item-not-selected-title"
                      }
                      onClick={() =>
                        this.setState({
                          selectedForItem: "student",
                          forItemsList: forStudentList,
                        })
                      }
                    >
                      <p 
                        className={
                          selectedForItem === "student"
                            ? "selected-line"
                            : "not-selected-line"
                        }
                      >
                        For Student
                      </p>
                    </div>
                    <div
                      className={
                        selectedForItem === "parent"
                          ? "For-item-selected-title"
                          : "For-item-not-selected-title"
                      }
                      onClick={() =>
                        this.setState({
                          selectedForItem: "parent",
                          forItemsList: forParentList,
                        })
                      }
                    >
                      <p
                        className={
                          selectedForItem === "parent"
                            ? "selected-line"
                            : "not-selected-line"
                        }
                      >
                        For Parents
                      </p>
                    </div>
                    <div
                      className={
                        selectedForItem === "society"
                          ? "For-item-selected-title"
                          : "For-item-not-selected-title"
                      }
                      onClick={() =>
                        this.setState({
                          selectedForItem: "society",
                          forItemsList: forSocietyList,
                        })
                      }
                    >
                      <p
                        className={
                          selectedForItem === "society"
                            ? "selected-line"
                            : "not-selected-line"
                        }
                      >
                        For Society
                      </p>
                    </div>
                  </Reveal>
                </Grid>
                <Grid item xs={12} sm={12} md={8} style={{ marginTop: "5%" }}>
                  <Reveal effect="fadeInRight right cascade">
                    <Grid container spacing={4}>
                      <Typography variant="h3" className="for_student_title">
                        {selectedForItem === "student" && (
                          <>Reinvention of Blended and Innovative Learning</>
                        )}
                        {selectedForItem === "parent" && (
                          <>More involvement in student’s progress</>
                        )}
                        {selectedForItem === "society" && (
                          <>Making world a knowledgeable place to live in</>
                        )}
                      </Typography>
                      {forItemsList &&
                        forItemsList.map((item: any) => (
                          <Grid item xs={12} sm={6} md={6}>
                            <div className="for_student_view_box">
                              <Typography className="for_student_details">
                                {item}
                              </Typography>
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="width-70per">
              <Grid container style={{ padding: "5% 0" }}>
                <Grid item xs={12} sm={6} md={6}>
                  <Reveal effect="fadeInLeft left cascade">
                    <Lottie
                      loop
                      animationData={lottieJson}
                      play
                      className="best-office-employee-img"
                    />
                    {/* <img src={bestOfficeEmployee} className="best-office-employee-img"></img> */}
                  </Reveal>
                </Grid>
                <Grid className="values-ethos-box pl2"
                  item
                  xs={12}
                  sm={6}
                  md={6}
                 
                >
                  <Reveal effect="fadeInRight right cascade">
                    <Typography variant="h2" className="textlabel_values_ethos">
                      Values & Ethos
                    </Typography>
                    <Typography className="textlabel_values_ethos_desc">
                      Teachze is built for offering end-to-end digital education
                      infrastructure. Our goal is to make high-quality and
                      cohesive learning accessible to everyone.
                    </Typography>
                  </Reveal>
                </Grid>
              </Grid>

              <Grid container style={{ padding: "5% 0" }}>
                <Grid item xs={12}>
                  <div className="Our_values_rectangle">
                    <Typography className="pause_icon">"</Typography>
                    <Typography variant="h2" className="Our_values_textlabel">
                      Our Values
                    </Typography>
                    <Slider
                      className="tiltSliderDots about-carousel"
                      {...settings}
                    >
                      <div>
                        <Typography className="With_the_student_text">
                          With the student-first approach, whether you have just
                          started or want to ace the exam, we carefully work
                          with each student to recognize the needs and
                          understand the learning style.
                        </Typography>
                      </div>
                      <div>
                        <Typography className="With_the_student_text">
                          Our intellectual faculty and enthusiastic students are
                          our biggest assets. We help the learners define the
                          path of their dreams and achieve them successfully.
                        </Typography>
                      </div>
                      <div>
                        <Typography className="With_the_student_text">
                          We believe that hardwork is the mainstay of success.
                          Thus, we try to put in dedicated and relentless effort
                          in fostering their learning needs.
                        </Typography>
                      </div>
                    </Slider>
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Reveal effect="fadeInUp">
                    <Typography variant="h2" className="leadership_team_textlabel">
                      Leadership Team
                    </Typography>
                    <Typography className="leadership_team_details">
                      Coupling experience with impactful results, meet our
                      dynamic leaders who believe that education is the most
                      powerful weapon to bring the desired change in the
                      society.
                    </Typography>
                    <Grid
                      container
                      spacing={4}
                      style={{ paddingTop: "10%", margin: "4% 0" }}
                      className="small-screen-leader-spacing"
                    >
                      {leadersList &&
                        leadersList.map((item: any) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            style={{
                              paddingRight:
                                Math.abs(item.id % 2) === 1 ? "2%" : 0,
                              paddingLeft:
                                Math.abs(item.id % 2) === 0 ? "2%" : 0,
                              marginTop:
                                Math.abs(item.id % 2) === 0 ? "6%" : "-6%",
                            }}
                            className="small-screen-no-space"
                          >
                            <div
                              className="leader_rectangle"
                              onMouseEnter={() => cardDisplayBlock(item.id)}
                              onMouseLeave={() => cardDisplayAbsolute(item.id)}
                              onClick={() => setSelectedEducator(item)}
                            >
                              <Grid container>
                                <Grid item xs={12} className="display-style">
                                  {item.profile ? (
                                    <img title="Profile Name" alt="profile image"
                                      src={item.profile}
                                      className="faculty-profile-img"
                                    ></img>
                                  ) : (
                                    <Avatar
                                      src={"/"}
                                      className="faculty-profile-avatar"
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={12} className="display-style">
                                  <Typography className="faculty-name">
                                    {item.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} className="display-style">
                                  <Typography className="faculty-location-text">
                                    {item.experience}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} className="display-style">
                                  <Button className="view-details-btn">
                                    <Typography className="view-details-text">
                                      View Details
                                    </Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                              <div
                                className="layer faculty-desc-text"
                                ref="layer"
                                id={`layer-id-${item.id}`}
                              >
                                {item.desc}
                              </div>
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AboutUs;
