import { createStyles } from "@material-ui/core/styles";


const useStylesLandingPageHeader = createStyles({
    landingPageHeaderContainer : {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f8f9ff",
    }
})


export default useStylesLandingPageHeader