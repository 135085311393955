import React from "react";
import {
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import CourseDetailsController from "./CourseDetailsController.web";
import {
  quote,
  imageHeader,
  rightArrow,
  calendar,
  imgUser,
  imageTick,
  upArrowImg,
} from "../assets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { HISTORY } from "../../../../../../src/packages/components/src/common";
import FaqExpansionPanel from "./Faq/FaqExpansionPanel.web";
import PerformanceReport from "./PerformanceReport.web";
import ZohoFormModalPopup from "../ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import ThankYou from "../ThankYou/ThankYou.web";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;
const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  style: {
    padding: "6% 0",
  },
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Reveal = require("react-reveal/Reveal");

class CourseDetails extends CourseDetailsController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      userDetails,
      courseFeatures,
      courseHighlights,
      foundationCourseHighlights,
      jeeMockTestSeries,
      neetCompactTestSeries,
      neetMockTestSeries,
      jeeCompactTestSeries,
      batches,
      subjects,
      subscriptionList,
      selectedIndex,
      selectedView,
      includedFeatures,
      testimonials,
    } = this.state;
    const highlights = HISTORY.location.state.courseName.includes("Foundation")
      ? foundationCourseHighlights
      : HISTORY.location.state.courseName.includes("NEET Compact") 
      ? neetCompactTestSeries
      : HISTORY.location.state.courseName.includes("NEET Mock") 
      ? neetMockTestSeries
      : HISTORY.location.state.courseName.includes("JEE Compact")
      ? jeeCompactTestSeries
      : HISTORY.location.state.courseName.includes("JEE Mock") 
      ? jeeMockTestSeries
      :courseHighlights;
    return (
      <div style={root}>
        <Grid container style={{ position: "relative" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#f8f9ff",
              padding: "3% 0 3% 0",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <Reveal effect="fadeInLeft left cascade">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="padding-right width-70per">
                      <Typography className="textlabel_heading_course">
                        Course Description
                      </Typography>

                      <Typography className="our-repeater-course-text">
                        {this.state.courseDescription ||
                          "Get the best academic support from our online tutors to nourish your Class 6 academic preparation. Teachze tutoring provides academic support for both the CBSE and TN SBSE boards. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child."}
                      </Typography>

                      {/* <a href="#">
                        <Typography className="learn-more-text">
                          Learn More
                        </Typography>
                      </a> */}

                      <Button
                        style={{
                          width: "37%",
                          textAlign: "center",
                          color: " #fff",
                          background: "#5580ea",
                          msTransform: "skewX(-6deg)",
                          transform: "skewX(-6deg)",
                          marginTop: 23,
                          padding: "3.5%",
                        }}
                        onClick={this.handleOpenZohoFormPopup}
                      >
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontSize: "1.4vw",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            msTransform: "skewX(1deg)",
                            transform: "skewX(1deg)",
                            textTransform: "capitalize",
                          }}
                          className="enroll-now-small-text"
                        >
                          Enroll Now
                        </Typography>
                      </Button>
                    </div>
                  </div>{" "}
                </Reveal>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Reveal effect="fadeInRight right cascade">
                  <img src={imageHeader} className="header-img"></img>
                </Reveal>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Reveal effect="fadeInUp">
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="margin-top-minus"
              >
                <div className="width-70per">
                  <Grid container justify="space-between" spacing={3}>
                    {courseFeatures &&
                      courseFeatures.map((item: any) => (
                        <Grid item xs={6} sm={6} md={3}>
                          <div className="feature_box">
                            <img
                              src={item.featureImage}
                              className="feature_img"
                            ></img>
                            <div className="feature_title_box">
                              <Typography className="feature_title_course">
                                {item.title}
                              </Typography>
                            </div>
                            <div className="feature_desc_box">
                              <Typography className="feature_desc">
                                {item.desc}
                              </Typography>
                            </div>

                            <a href="#">
                              <Typography
                                className="textlabel_knowmore"
                                onClick={() =>
                                  this.setState({
                                    isOpenPerformanceReport: true,
                                  })
                                }
                              >
                                {item.title ===
                                "Scorecards and Performance Reports"
                                  ? "Know more"
                                  : ""}
                              </Typography>
                            </a>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </Reveal>
          </Grid>

          <Grid item xs={12}>
            {" "}
            <Reveal effect="fadeInRight right cascade">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "3% 0",
                }}
              >
                <div className="width-70per">
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography className="textlabel_batches">
                          Course Highlights
                        </Typography>
                        <Typography className="get_assi_text">
                          Get assistance in structured learning process from
                          expert faculty
                        </Typography>

                        <Grid
                          container
                          spacing={4}
                          alignItems="center"
                          style={{ marginTop: "20px" }}
                        >
                          {highlights &&
                            highlights.map((item: any, index: any) => (
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={4}
                                className="small-screen-less-padding"
                              >
                                <Grid
                                  container
                                  className="course-highlight-view-box"
                                >
                                  <Grid
                                    item
                                    xs={4}
                                    md={4}
                                    style={{ alignSelf: "center" }}
                                  >
                                    <img
                                      src={item.image}
                                      alt="Image"
                                      className="course-highlight-image"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    md={8}
                                    style={{
                                      alignSelf: "center",
                                      textAlignLast: "left",
                                    }}
                                  >
                                    <Typography className="course-highlight-header">
                                      {index === 0 && this.state.hours != "0"
                                        ? `${this.state.hours} hrs of live online class`
                                        : index === 6
                                        ? this.state.highlightsCourseName
                                        : item.title}
                                    </Typography>

                                    <Typography className="course-highlight-text">
                                      {item.text}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Reveal>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              background: "linear-gradient(0deg, #f8f9ff 65%, #fff 35%)",
            }}
          >
            <div className="width-70per">
              <Grid container className="course_sub_view_box">
                <Grid item xs={12}>
                  {" "}
                  <Reveal effect="fadeInUp">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography className="textlabel_batches">
                        Course Subscription
                      </Typography>
                      <Typography className="get_assi_text">
                        Subscribe to a plan that best suits your learning
                        requirements.
                      </Typography>

                      {HISTORY.location?.state?.isFrom?.toLowerCase() ===
                        "tution" && (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "5% 0",
                          }}
                        >
                          {subscriptionList.map((item: any, index: number) => (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: this.getJustifyContent(index),
                              }}
                            >
                              <Grid
                                className="sub_item_view_box"
                                style={{
                                  backgroundColor:
                                    item.key === selectedIndex
                                      ? "#5580ea"
                                      : "#fff",
                                  boxShadow:
                                    item.key === selectedIndex
                                      ? "box-shadow: 0 3px 36px 13px rgba(85, 128, 234, 0.2)"
                                      : "0 3px 36px 13px rgba(145, 167, 208, 0.05)",
                                }}
                                key={item.key}
                                onClick={() => {
                                  this.handleSelectTab(item.key);
                                }}
                              >
                                <Typography
                                  className="textlabel_personal"
                                  style={{
                                    color:
                                      item.key === selectedIndex
                                        ? "white"
                                        : "#394052",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                                <Typography
                                  className="textlabel_details"
                                  style={{
                                    color:
                                      item.key === selectedIndex
                                        ? "white"
                                        : "#394052",
                                  }}
                                >
                                  {item.detail}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      )}

                      <Grid
                        container
                        style={{ display: "flex", flexDirection: "row" }}
                        className="features_included_view_box_border"
                      >
                        <Grid item xs={12}>
                          <Grid container style={{ padding: "4% 5% 4% 8%" }}>
                            <Grid item xs={12} sm={8} md={9}>
                              <Typography className="textlabel_features">
                                Features
                              </Typography>
                              <Typography className="textlabel_features">
                                Included
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                              <Grid className="view_subscription">
                                <Button
                                  className={
                                    selectedView === "yearly"
                                      ? "view_selected"
                                      : ""
                                  }
                                  style={{ width: "100%" }}
                                  onClick={() =>
                                    this.handleOnChangeView("yearly")
                                  }
                                >
                                  <Typography
                                    className={
                                      selectedView === "yearly"
                                        ? "textlabel_selected"
                                        : "textlabel_notselected"
                                    }
                                  >
                                    Yearly
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid
                            container
                            style={{
                              borderTop: "0.5px solid #557fe9",
                              borderBottom: "0.5px solid #557fe9",
                            }}
                          >
                            {includedFeatures &&
                              includedFeatures.map((item: any) => (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    background:
                                      item.key === 1 || item.key === 3
                                        ? "#f8f9ff"
                                        : "#fff",
                                  }}
                                >
                                  <Grid
                                    container
                                    style={{ padding: "0 5% 0 8%" }}
                                  >
                                    <Grid item xs={10} sm={10} md={10}>
                                      <Typography className="textlabel_live">
                                        {item.title}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        src={imageTick}
                                        className="imgTick"
                                      ></img>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid
                            container
                            style={{
                              padding: "4% 5% 4% 8%",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "end",
                                marginRight: "5%",
                              }}
                            >
                              <Typography className="textlabel_price">
                                {this.state.courseFees
                                  ? `${
                                      this.state.courseFees === "Free"
                                        ? ""
                                        : "₹"
                                    }${this.state.courseFees}`
                                  : "-"}
                              </Typography>
                              {/* <Typography className="textlabel_discount">
                                19% off{" "}
                                <span
                                  style={{
                                    color: "#677788",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  ₹30,000
                                </span>
                              </Typography> */}
                            </Grid>

                            <Button
                              onClick={this.handleOpenZohoFormPopup}
                              className="btn_buyfullcourse"
                            >
                              <Typography className="textlabel_buyfullcourse">
                                START TODAY
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>{" "}
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#f8f9ff",
              paddingBottom: "5%",
            }}
          >
            <div className="width-70per">
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "5%",
                }}
              >
                <Grid item xs={12} sm={8} md={10}>
                  {" "}
                  <Reveal effect="fadeInUp">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography className="textlabel_batches">
                        Subject Included
                      </Typography>
                      <Typography className="get_assi_text">
                        Unlimited access to masterclass learning for all
                        subjects.
                      </Typography>
                    </div>
                  </Reveal>{" "}
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button className="view_viewallbox_course">
                    <Typography className="textlabel_viewall_course">
                      View All
                    </Typography>
                  </Button>
                </Grid> */}
              </Grid>

              <Grid container direction="row" style={{ padding: "5% 0" }}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid container spacing={1}>
                    {subjects &&
                      subjects.map((item: any) => (
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={2}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="view_box_subject">
                            <img
                              src={item.imgSource}
                              style={{
                                width: 90,
                                height: 90,
                                objectFit: "contain",
                                padding: "10% 0",
                              }}
                              className="subject_image"
                            ></img>
                            <Typography className="textlabel_subjectname">
                              {item.name}
                            </Typography>
                            {/* <Typography className="textlabel_viewsyllabus">
                              View Syllabus
                            </Typography> */}
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {/* <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <div className="width-70per">
              <Grid container>
                <Grid item xs={12}>
                  {" "}
                  <Reveal effect="fadeInUp">
                    <div
                      className="overlap_faq_box"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Accordion
                          elevation={0}
                          expanded={this.state.isFaqExpanded}
                          onChange={this.handleToggleFaq}
                        >
                          <AccordionSummary
                            expandIcon={
                              this.state.isFaqExpanded ? (
                                <img src={upArrowImg} />
                              ) : (
                                <img src={rightArrow} />
                              )
                            }
                            aria-controls="panel-content"
                          >
                            <div>
                              <div
                                style={{
                                  fontFamily: "Inter",
                                  color: "#394052",
                                }}
                              >
                                <Typography className="textlabel_batches">
                                  FAQ
                                </Typography>
                              </div>

                              <div className="faq-below-text">
                                <span>
                                  Have any questions on your mind. Here are some
                                  of the common ones that we have answered.
                                </span>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container direction="row">
                              <Grid item xs={12}>
                                {this.state.faqDetails?.map((item: any) => (
                                  <FaqExpansionPanel details={item} />
                                ))}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>{" "}
                  </Reveal>{" "}
                </Grid>
              </Grid>
            </div>
          </Grid> */}
          {/* <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="width-70per">
              <Grid container style={{ display: "flex", flexDirection: "row" }}>
                <Grid item xs={12} sm={8} md={10}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Reveal effect="fadeInUp">
                      <Typography className="textlabel_batches">
                        Suggested Batches
                      </Typography>
                      <Typography className="get_assi_text">
                        Don’t miss out! Check out the upcoming batches of online
                        private tuition for this course.
                      </Typography>
                    </Reveal>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button className="view_viewallbox_course">
                    <Typography className="textlabel_viewall_course">
                      View All
                    </Typography>
                  </Button>
                </Grid>
              </Grid>

              <Grid container direction="row" className="spacing-60">
                <Grid item xs={12}>
                  {" "}
                  <Reveal effect="fadeInUp">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Grid container justify="space-around" spacing={3}>
                        {batches &&
                          batches.map((item: any) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Grid
                                className="batch-view-box"
                                onClick={() => HISTORY.push("/BatchDetails")}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={item.imgSource}
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                ></img>
                                <Typography className="textlabel_batchname">
                                  {item.title}
                                </Typography>

                                <Grid
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={calendar}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      objectFit: "contain",
                                      marginRight: 18,
                                    }}
                                  ></img>
                                  <Typography className="textlabel_startdate">
                                    Start Date: {item.startDate}
                                  </Typography>
                                </Grid>

                                <Grid
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={imgUser}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      objectFit: "contain",
                                      marginRight: 18,
                                    }}
                                  ></img>
                                  <Typography className="textlabel_startdate">
                                    Faculty: {item.faculty}
                                  </Typography>
                                </Grid>

                                <Grid
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    margin: "20px 0 30px 0",
                                  }}
                                >
                                  <div className="subject_view_box">
                                    <Typography className="textlabel_language_hindi">
                                      Hindi
                                    </Typography>
                                  </div>
                                  <div
                                    className="subject_view_box"
                                    style={{ marginLeft: 12 }}
                                  >
                                    <Typography className="textlabel_language_hindi">
                                      English
                                    </Typography>
                                  </div>
                                </Grid>

                                <Grid
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "20px 0px",
                                  }}
                                  className="less-margin-small-screen"
                                >
                                  <Typography className="textlabel_viewsschedule">
                                    View Full Schedule
                                  </Typography>
                                  <img
                                    src={rightArrow}
                                    style={{
                                      width: "31px",
                                      height: "27px",
                                      objectFit: "contain",
                                    }}
                                  ></img>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </div>{" "}
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid> */}

          <Grid item xs={12}>
            <Reveal effect="fadeInUp">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "3%",
                  backgroundColor: "white",
                  // "#f8f9ff",
                }}
              >
                <div className="width-70per">
                  <Grid container style={{ position: "relative" }}>
                    <img src={quote} className="quote-image"></img>
                    <Typography className="Hear-from-our-text">
                      Hear from our students and their parents
                    </Typography>
                  </Grid>

                  <Slider className="tiltImportant" {...settings}>
                    {testimonials?.length > 0 &&
                      testimonials.map((item: any) => (
                        <Grid style={{ padding: "5px" }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="view_box"
                            style={{ height: "450px" }}
                          >
                            <Grid container>
                              {/* <Grid item xs={4} sm={4} md={4}>
                                <img
                                  src={item.imgSource}
                                  className="view_img"
                                ></img>
                              </Grid> */}
                              <Grid
                                item
                                xs={8}
                                sm={8}
                                md={8}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  paddingLeft: "3%",
                                }}
                              >
                                <Typography className="view_name_text">
                                  {item.name}
                                </Typography>
                                <Typography className="view_parent_text">
                                  {item.role}
                                </Typography>
                              </Grid>
                            </Grid>

                            <div className="view_line"></div>
                            <Typography className="view_text">
                              {item.text}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Slider>
                </div>
              </div>
            </Reveal>
          </Grid>
          <PerformanceReport
            onCloseModal={() => {
              this.setState({ isOpenPerformanceReport: false });
            }}
            open={this.state.isOpenPerformanceReport}
            reportList={this.state.reportList}
            enrollNowClick={this.handleOpenZohoFormPopup}
          />
          <ZohoFormModalPopup
            open={this.state.openZohoForm}
            openThankyouModal={this.handleClickOpen}
            closeThankYou={this.handleClose}
            onCloseModal={this.handleCloseZohoFormPopup}
            isFirstTime={true}
          />
          <ThankYou open={this.state.open} handleClose={this.handleClose} />
        </Grid>
      </div>
    );
  }
}

export default CourseDetails;
