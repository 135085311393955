import React from "react";
import {
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import BatchDetailsController from "./BatchDetailsController.web";
import {
  quote,
  rightArrow,
  leftArrow,
  calendar,
  imgUser,
  group28,
  thumbnail,
  event1,
  imageLaptop,
  imageTest,
  batchTeacher,
  likes,
  videoPlayIcon,
} from "../assets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./BatchDetails.web.css";
import ExpansionPanel from "./BatchDetailsCommonComponents/ExpansionPanel.web";
import EducatorDetails from "./BatchDetailsCommonComponents/EducatorDetails.web";
import ScheduleDetails from "./BatchDetailsCommonComponents/ScheduleDetails.web";
import ZohoFormModalPopup from "../ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import ThankYou from "../ThankYou/ThankYou.web";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
  style: {
    padding: "75px 0",
  },
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Reveal = require("react-reveal/Reveal");

const settingsForTeacherBlock = {
  dots: false,
  infinite: false,
  arrows: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
  style: {
    padding: "5% 0",
  },
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class BatchDetails extends BatchDetailsController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      userDetails,
      batches,
      subjectList,
      selectedSubject,
      teacherDetails,
    } = this.state;

    return (
      <div style={root}>
        <Grid container style={{ position: "relative" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#f8f9ff",
            }}
          >
            <div className="width-70per">
              <Grid container style={{ padding: "2% 0 12% 0" }}>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={7}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Reveal effect="fadeInLeft left cascade">
                    <Typography className="textlabel_batch_heading">
                      Batch for Class 11th JEE Main & Advanced 2023
                    </Typography>

                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={calendar}
                        style={{
                          width: 20,
                          height: 20,
                          objectFit: "contain",
                          marginRight: 18,
                        }}
                      ></img>
                      <Typography className="textlabel_startdate">
                        Start Date: 2nd January,2022
                      </Typography>
                    </Grid>

                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={imgUser}
                        style={{
                          width: 20,
                          height: 20,
                          objectFit: "contain",
                          marginRight: 18,
                        }}
                      ></img>
                      <Typography className="textlabel_startdate">
                        Faculty: Jitu Rathore, Nita Patel
                      </Typography>
                    </Grid>

                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        margin: "20px 0 30px 0",
                      }}
                    >
                      <div className="language_view_box">
                        <Typography className="language_hindi">
                          Hindi
                        </Typography>
                      </div>
                      <div
                        className="language_view_box"
                        style={{ marginLeft: 12 }}
                      >
                        <Typography className="language_hindi">
                          English
                        </Typography>
                      </div>
                    </Grid>
                  </Reveal>
                  <Reveal effect="fadeInLeft left cascade">
                    <Button
                      style={{
                        width: "228px",
                        height: "60px",
                        textAlign: "center",
                        color: " #fff",
                        background: "#5580ea",
                        msTransform: "skewX(-6deg)",
                        transform: "skewX(-6deg)",
                        marginTop: "3%",
                        padding: "3.5%",
                      }}
                      className="batch_enroll_now_btn"
                      onClick={this.handleOpenZohoFormPopup}
                    >
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontSize: 22,
                          fontWeight: 600,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          msTransform: "skewX(1deg)",
                          transform: "skewX(1deg)",
                          textTransform: "capitalize",
                        }}
                        className="batch_enroll_now_btn_text"
                      >
                        Enroll Now
                      </Typography>
                    </Button>
                  </Reveal>
                </Grid>

                <Grid item xs={12} sm={4} md={5}>
                  <Reveal effect="fadeInRight right cascade">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                      }}
                    >
                      {/* <img src={group28} className="bitmap-video-img"></img> */}
                      <ReactPlayer
                        url="https://player.vimeo.com/progressive_redirect/playback/670524444/rendition/540p/540p.mp4?loc=external&oauth2_token_id=1401671315&signature=6345b05d131e84b0af96a1d0bf2aa51b1ffd4dd1ac1bac44748069b7fd3acbfd"
                        className="react-player"
                        style={{ borderRadius: "8px" }}
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                        }}
                        playing
                        width="100%"
                        height="310px"
                        light={thumbnail}
                        controls
                        playIcon={<img src={videoPlayIcon} />}
                      />{" "}
                    </div>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Reveal effect="fadeInUp">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="width-70per">
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className="overlap_view_box"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img src={event1} className="img-event-1"></img>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography className="morning-batch-text">
                            Morning Batch
                          </Typography>
                          <Typography
                            className="view-schedule-text"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({ isOpenScheduleDetails: true })
                            }
                          >
                            View Schedule
                          </Typography>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img src={imageLaptop} className="img-event-1"></img>
                        <Typography className="morning-batch-text">
                          50+ Live Class
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img src={imageTest} className="img-event-1"></img>
                        <Typography className="morning-batch-text">
                          5 Test
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Reveal>
          </Grid>

          <Grid item xs={12}>
            <Reveal effect="fadeInRight right cascade">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <div className="width-70per">
                  <Grid
                    container
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography className="textlabel_batches">
                        Subject Included
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "40px",
                      }}
                    >
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={12} sm={3}>
                          <List style={{ padding: "0px" }} dense>
                            {subjectList.length &&
                              subjectList.map((subject: any) => (
                                <ListItem
                                  style={{
                                    boxShadow:
                                      selectedSubject?.id === subject.id
                                        ? "0 0 4px 2px rgb(185 202 243 / 25%)"
                                        : "none",
                                  }}
                                  className="subjects"
                                  button
                                  onClick={() =>
                                    this.handleSelectedSubjectInfo(subject.id)
                                  }
                                >
                                  <ListItemAvatar>
                                    <img
                                      alt="subject"
                                      src={subject.subjectLogo}
                                      className="listitem_subjectlogo"
                                    />
                                  </ListItemAvatar>
                                  <ListItemText>
                                    <span className="listitem_subjectname">
                                      {subject.subjectName}
                                    </span>
                                  </ListItemText>
                                </ListItem>
                              ))}
                          </List>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Grid container direction="row">
                            {selectedSubject?.chapterList?.length &&
                              selectedSubject.chapterList.map(
                                (chapter: any) => (
                                  <Grid
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    item
                                    xs={12}
                                  >
                                    <div
                                      style={{
                                        width: "95%",
                                        marginBottom: "5%",
                                      }}
                                    >
                                      <ExpansionPanel details={chapter} />
                                    </div>
                                  </Grid>
                                )
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#f8f9ff",
            }}
          >
            <div className="width-70per">
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "3%",
                }}
              >
                <Grid item xs={12}>
                  {" "}
                  <Reveal effect="fadeInRight right cascade">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography className="textlabel_batches">
                        Who will teach you?
                      </Typography>
                      <Typography className="get_assi_text">
                        Get assistance in structured learning process from
                        expert faculty
                      </Typography>
                    </div>
                  </Reveal>
                </Grid>
                <Grid item xs={12}>
                  <Slider
                    className="tiltImportant teach-you"
                    {...settingsForTeacherBlock}
                  >
                    {teacherDetails?.length &&
                      teacherDetails.map((item: any) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <Reveal effect="fadeInUp">
                              <div
                                className="batch_teach_you teacher-block"
                                style={{
                                  width: "95%",
                                  backgroundColor: "#fff",
                                  boxShadow:
                                    "0 2px 5px 2px rgba(227, 227, 227, 0.5)",
                                  borderRadius: "3px",
                                  height: "290px",
                                }}
                              >
                                <Grid
                                  container
                                  style={{
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Grid item xs={6}>
                                    <img
                                      src={item.image}
                                      className="batch_teacher_img batch-teacher-small"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      paddingLeft: "3%",
                                      fontFamily: "Inter",
                                      color: "#394052",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginTop: "8%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.teacherName}
                                      </span>
                                      <br />
                                      <span
                                        style={{ fontSize: "14px" }}
                                        className="font-12"
                                      >
                                        {item.qualifications}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: "5%",
                                      }}
                                    >
                                      <span
                                        style={{ fontSize: "14px" }}
                                        className="font-12"
                                      >
                                        {item.experience}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: "8%",
                                        display: "flex",
                                      }}
                                    >
                                      <span style={{ marginRight: "3%" }}>
                                        <img
                                          src={likes}
                                          style={{ height: 24 }}
                                        />
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: 600,
                                        }}
                                        className="font-14"
                                      >
                                        {item.no_of_likes > 3000
                                          ? `${item.no_of_likes}+ Likes`
                                          : `${item.no_of_likes} Likes`}
                                      </span>
                                    </div>
                                    <div style={{ marginTop: "10%" }}>
                                      <Typography
                                        style={{
                                          textDecoration: "underline",
                                          color: "#5580ea",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                          cursor: "pointer",
                                        }}
                                        component="span"
                                        onClick={() =>
                                          this.setState({
                                            isOpenEducatorDetails: true,
                                            selectedEducator: item,
                                          })
                                        }
                                      >
                                        View Profile
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </Reveal>
                          </Grid>
                        </Grid>
                      ))}
                  </Slider>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="width-70per" style={{ position: "relative" }}>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "3%",
                }}
              >
                <Grid item xs={12} sm={8} md={10}>
                  <Reveal effect="fadeInRight right cascade">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography className="textlabel_batches">
                        Other Batches
                      </Typography>
                      <Typography className="get_assi_text">
                        Get assistance in structured learning process from
                        expert faculty
                      </Typography>
                    </div>
                  </Reveal>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  {" "}
                  <Reveal effect="fadeInRight right cascade">
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <Button className="view_viewallbox_course">
                        <Typography className="textlabel_viewall_course">
                          View All
                        </Typography>
                      </Button>
                    </div>
                  </Reveal>
                </Grid>
              </Grid>

              <Grid container direction="row" className="spacing-60">
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid container justify="space-around" spacing={3}>
                    {batches &&
                      batches.map((item: any) => (
                        <Grid item xs={12} sm={6} md={4}>
                          <Reveal effect="fadeInUp">
                            <Grid className="batch-view-box">
                              <img
                                src={item.imgSource}
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              ></img>
                              <Typography className="textlabel_batchname">
                                {item.title}
                              </Typography>

                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={calendar}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    objectFit: "contain",
                                    marginRight: 18,
                                  }}
                                ></img>
                                <Typography className="textlabel_startdate">
                                  Start Date: {item.startDate}
                                </Typography>
                              </Grid>

                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={imgUser}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    objectFit: "contain",
                                    marginRight: 18,
                                  }}
                                ></img>
                                <Typography className="textlabel_startdate">
                                  Faculty: {item.faculty}
                                </Typography>
                              </Grid>

                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  margin: "20px 0 30px 0",
                                }}
                              >
                                <div className="subject_view_box">
                                  <Typography className="textlabel_language_hindi">
                                    Hindi
                                  </Typography>
                                </div>
                                <div
                                  className="subject_view_box"
                                  style={{ marginLeft: 12 }}
                                >
                                  <Typography className="textlabel_language_hindi">
                                    English
                                  </Typography>
                                </div>
                              </Grid>

                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  margin: "20px 0px",
                                }}
                                className="less-margin-small-screen"
                              >
                                <Typography className="textlabel_viewsschedule">
                                  View Full Schedule
                                </Typography>
                                <img
                                  src={rightArrow}
                                  style={{
                                    width: "31px",
                                    height: "27px",
                                    objectFit: "contain",
                                  }}
                                ></img>
                              </Grid>
                            </Grid>
                          </Reveal>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Reveal effect="fadeInRight right cascade">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "3%",
                  backgroundColor: "#f8f9ff",
                }}
              >
                <div className="width-70per">
                  <Grid container style={{ position: "relative" }}>
                    <img src={quote} className="quote-image"></img>
                    <Typography className="Hear-from-our-text">
                      Hear from our students and their parents
                    </Typography>
                  </Grid>

                  <Slider
                    className="tiltImportant landing-carousel"
                    {...settings}
                  >
                    {userDetails &&
                      userDetails.map((item: any) => (
                        <Grid style={{ padding: "5px" }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="view_box"
                          >
                            <Grid container>
                              {/* <Grid item xs={4} sm={4} md={4}>
                            <img
                              src={item.imgSource}
                              className="view_img"
                            ></img>
                          </Grid> */}
                              <Grid
                                item
                                xs={8}
                                sm={8}
                                md={8}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  paddingLeft: "3%",
                                }}
                              >
                                <Typography className="view_name_text">
                                  {item.name}
                                </Typography>
                                <Typography className="view_parent_text">
                                  {item.role}
                                </Typography>
                              </Grid>
                            </Grid>

                            <div className="view_line"></div>
                            <Typography className="view_text">
                              {item.text}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Slider>
                </div>
              </div>
            </Reveal>{" "}
          </Grid>

          <EducatorDetails
            onCloseModal={() => {
              this.setState({ isOpenEducatorDetails: false });
            }}
            open={this.state.isOpenEducatorDetails}
            educatorSubjects={this.state.educatorSubjects}
            testimonialsList={this.state.testimonialsList}
            selectedTestimonial={this.state.selectedTestimonial}
            previousArrowClick={this.previousArrowClick}
            nextArrowClick={this.nextArrowClick}
            selectedEducator={this.state.selectedEducator}
          />

          <ScheduleDetails
            onCloseModal={() => {
              this.setState({ isOpenScheduleDetails: false });
            }}
            open={this.state.isOpenScheduleDetails}
            scheduleList={this.state.scheduleList}
          />

          <ZohoFormModalPopup
            open={this.state.openZohoForm}
            openThankyouModal={this.handleClickOpen}
            closeThankYou={this.handleClose}
            onCloseModal={this.handleCloseZohoFormPopup}
            isFirstTime={true}
          />
          <ThankYou open={this.state.open} handleClose={this.handleClose} />
        </Grid>
      </div>
    );
  }
}

export default BatchDetails;
