import * as React from 'react';
import  { Box ,  Typography, IconButton , Divider,} from "@material-ui/core"
import LinkItem from '../../LinkedItem.web';
import { call, email, logoSidePlaced } from '../../assets';
import SocialMedia from '../../SocialMedia.web';
import useStylesFooter from './styles/Footer.style';

// export interface IAppProps {
// }

// const Degress = ['JEE', 'NEET', 'CBSE', 'STATE BOARD']
// const OtherLinks = [
//     {
//         link : "Blog",
//         path : "/Blog"
//     }
// ]


export const Footer = () =>  {

 const Classes = useStylesFooter()

  return (
    <>
    <Box
    className={Classes.footerWrapper}
    p={{ xs: "1.5rem", sm: "3rem", md: "5rem" }}
   
    display={{ xs: "block", sm: "flex", md: "flex", lg: "flex" }}
  >
    {/* 5 columns */}
    <Box
    className={Classes.innerFooterLeft}
      width={{ xs: "100%", sm: "35%", md: "35%", lg: "35%" }}
      // p={{ sm: "0rem", md: "2rem", lg: "2rem" }}
      pt={{ sm: "0rem", md: "0rem", lg: "0rem" }}
      mb={2}
      mr={{ sm: "2rem", md: "20px", lg: "100px" }}
    >
      <img
      className={Classes.footerLogo}
        src={logoSidePlaced}
        alt="Logo"
      />
      <Typography
        className="Learn-from-education-footer"
      >
        At Teachze, we provide the best face-to-face online coaching for JEE, NEET, CA and Tuitions for CBSE and State Boards. Our personalized live classes are conducted by experienced and highly qualified teachers who will easily help you crack the exam. Enroll now!
      </Typography>
    </Box>
    <Box
    className={Classes.innerFooterRight}
      width={{ xs: "100%", md: "65%", lg: "65%" }}
    >
      <Box width={{ xs: "50%", sm: "50%", md: "25%", lg: "25%" }}>
        <Typography variant="h5"
          className="Links-header-text"
        >
          Links
        </Typography>
        <LinkItem data="Home" onClick="/" />
        <LinkItem data="About" onClick="/AboutUs" />
        {/* <LinkItem data="Careers" /> */}
      </Box>
      <Box width={{ xs: "50%", sm: "50%", md: "25%", lg: "25%" }}>
        <Typography variant="h5"
          className="Links-header-text"
        >
          Courses
        </Typography>
        <LinkItem data="JEE" onClick={"/Competitive-exams/Jee"}/>
        <LinkItem data="NEET" onClick={"/Competitive-exams/Neet"}/>
        <LinkItem data="CBSE" onClick={"/Tutions/CBSE"}/>
        <LinkItem data="STATE BOARD" onClick={"/Tutions/TN-Board"}/>
      </Box>
      <Box
        width={{ xs: "50%", sm: "50%", md: "25%", lg: "25%" }}
        mt={{ xs: "25px", sm: "25px", md: "0px", lg: "0px" }}
      >
        <Typography variant="h5"
          className="Links-header-text"
        >
          Other Links
        </Typography>
        <LinkItem data="Blog" onClick="/Blogs" />
        {/* <LinkItem data="Privacy" onClick="/PrivacyPolicy" /> */}
        <LinkItem data="Terms & Privacy" onClick={"/PrivacyPolicy"} />
        {/* <LinkItem data="Telegram" /> */}
      </Box>
      <Box
        width={{ xs: "50%", sm: "50%", md: "25%", lg: "25%" }}
        mt={{ xs: "25px", sm: "25px", md: "0px", lg: "0px" }}
      >
        <Typography variant="h5"
          className="Links-header-text"
        >
          Get In Touch
        </Typography>
        <Box
        
        className={Classes.getInTouchWrapper}
        >
          <IconButton color="primary" aria-label="add to shopping cart" className="get-in-touch-padding">
            <img src={call} className="call-email-img"></img>
          </IconButton>
          <Typography
         
            className="get-in-touch-item-text"
          >
            <a href="tel:+91 9513317652" className="get-in-touch-item-text">+91 9513317652</a>

          </Typography>
        </Box>
        <Box 
      className={Classes.getInTouchWrapper}
          
        >
          <IconButton color="primary" aria-label="add to shopping cart" className="get-in-touch-padding" >
            <img src={email} className="call-email-img"></img>
          </IconButton>
          <Typography
            className="get-in-touch-item-text"
          >
            <a href="mailto:hello@teachze.com" className="get-in-touch-item-text">hello@teachze.com</a>

          </Typography>
        </Box>
        <SocialMedia />
      </Box>
    </Box>
  </Box>
    <Divider className={Classes.divider} />
    <Box className={Classes.reservedRights}>
      <Typography
        className="All-rights-reserve-text"
      >
        © 2022 All rights reserved.
      </Typography>
    </Box>
    </>
  );
}
