import React from "react";
// Customizable Area Start
import LandingPageController from '../LandingPage/LandingPageController.web'
import {
    Box
} from "@material-ui/core";
import LandingPageHeader from "../LandingPage/LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "../BlogPageFooter.web";
import "../LandingPage.web.css";
import BlogPageDetail from "./Blogdetail.web";
import { HISTORY } from "../../../../components/src/common";
// Customizable Area End
const root = {
    flexGrow: 1,
    overflowX: "hidden",
    position: "relative"
} as const;

export default class BlogDetailMainPage extends LandingPageController {
    // Customizable Area Start
    render() {
        const { mainTabIndex } = this.state
        return (
            <div style={root}>
                <LandingPageHeader mainTabIndex={4} handleSelectMainTab={this.handleSelectMainTab} />
                <Box><BlogPageDetail classes={undefined} navigation={undefined} mainTabIndex={mainTabIndex}  /></Box>
                <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
            </div>
        );
    }
}


// Customizable Area End
