import  React, { ReactChild, ReactChildren } from 'react';
import { Button } from "@material-ui/core"
import useStylesButton from './styles/CustomButtonStyles';


export interface IAppProps {
    children : ReactChildren | ReactChild
   handleClick : () => void
   className ?: string,
   spanClass ?: string
}

export default function CustomButton ({ children , handleClick , className, spanClass}: IAppProps) {

    const classes = useStylesButton()
  return (
    <Button
    className={classes.trialButton}
    variant="outlined"
    onClick={handleClick}
  >
    <span id="trial" className={spanClass}>{children}</span>
  </Button>
  );
}
