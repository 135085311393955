import React, { useState } from "react";
// Customizable Area Start
import Lottie from "react-lottie-player";
import lottieJson from "../../landingpage_gif.json";
import LandingPageController from "./LandingPageController.web";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {
  developerActivity,
  book1,
  onlineClass,
  elearning,
  videoPlayer,
  groups,
  customerService,
  rightArrow,
  leftArrow,
} from "../assets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LandingPage.style.css";
import "../LandingPage.web.css";
import ZohoFormModalPopup from "../ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import DraggableDialog from "../AdPopup";
import MiniCard from "../Components/InformationCard/MiniCard";
import SubjectTags from "../Components/Tags/SubjectTags.web";
import CarouselCard from "../Components/InformationCard/CarouselCard/CarouselCard.web";
import MiniStatsCard from "../Components/InformationCard/Stats/MiniStatsCard";
import { HISTORY } from "../../../../components/src/common";
import ThankYou from "../ThankYou/ThankYou.web";

// Customizable Area End
const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
  overflow: "hidden",
} as const;

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
};

const Reveal = require("react-reveal/Reveal");

export default class LandingPage extends LandingPageController {
  // Customizable Area Start
  render() {
    const {
      bookOver,
      schoolOver,
      documentOver,
      bookShadow,
      schoolShadow,
      documentShadow,
      carouselCardData,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>Teachze | Best Live Online Coaching Classes and Online Tuitions</title>
          <meta name="description" content="Join Teachze LIVE Online Classes led by dedicated master tutors ad prepare for your exam more effectively.  Personalized face-to-face online coaching for NEET, JEE, CA | Online Tuitions for classes 6th -12th (CBSE, TN Board) |Book a Free Trial today." />
        </Helmet>
        {/* <DraggableDialog /> */}
        <Box style={root}>
          <Grid container alignItems="center" className="landingPageContainer">
            <Reveal effect="fadeInUp">
              <Box className="landingPageHeader">
                <Typography variant="h1" className="Taking-Forward-The-N">
                  {/* Get expert <span className="text-style-1">one-on-one</span>{" "}
                  live coaching at your doorstep! */}
                  Get Best <span className="text-style-1">Face-to-Face</span>{" "}
                  live coaching at your doorstep!
                </Typography>

                {/* <Typography className="Taking-Forward-The-N">
                  Best Live Online Classes by Top Teachers for NEET, JEE, CA, CBSE & State Boards  | Teachze
                </Typography> */}
                <Typography className="textlabel_header-copy">
                  Discover a new way of online learning! Collaborating blended,
                  comprehensive and innovative digital learning with a strong
                  focus on personalized attention.
                  {/* At Teachze, we provide the best face-to-face online coaching for JEE, NEET, CA and Tuitions for CBSE and State Boards. Our personalized live classes are conducted by experienced and highly qualified teachers who will easily help you crack the exam. Enroll now! */}
                </Typography>
                <Button
                  className="book-trial-btn-small"
                  onClick={this.handleOpenZohoFormPopup}
                >
                  <Typography className="book-trial-text-small">
                    {/* Schedule Your Class! */}
                    Book Free Trial
                  </Typography>
                </Button>
              </Box>
            </Reveal>

            <Reveal effect="fadeInUp">
              <Lottie
                loop
                animationData={lottieJson}
                play
                className="animation_gif"
              />
            </Reveal>
          </Grid>
          <Grid className="relative" container>
            <Grid item xs={12} className="landingPage-attend-block">
              <Box className="width-70per">
                <Grid className="pb-5" container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="center-text-small-screen"
                  >
                    <Reveal effect="fadeInLeft left cascade">
                      <Typography variant="h2" className="Learn-from-Expert">
                        Learn Anything, Anywhere, Anytime.
                      </Typography>

                      <Typography className="Discover-a-new-way">
                        Explore our range of courses and select your preferred
                        course to get started. Get help for school learning or
                        assistance for competitive examinations. Create your
                        personalized online learning with Teachze.
                      </Typography>
                    </Reveal>
                    <Reveal effect="fadeInLeft">
                      <Button
                        className="attend-now-btn-small"
                        onClick={this.handleOpenZohoFormPopup}
                      >
                        <Typography className="attend-now-text-small">
                          Attend Now
                        </Typography>
                      </Button>
                    </Reveal>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <Reveal effect="fadeInRight right cascade">
                      <MiniCard
                        onMouseHover={this.competitiveExamCardMouseHover}
                        onMouseLeave={this.competitiveExamCardMouseLeave}
                        className={"competitiveExam"}
                        onClick={() => {
                          this.props.handleSelect("/Competitive-exams", 1);
                        }}
                        imgSrc={this.state.bookOver}
                      >
                        <Typography variant="h3" className="Competetive-Exam">
                          Competitive Exams
                        </Typography>

                        <Grid className="allRectangles">
                          {["JEE", "NEET", "CA"].map((name, index) => {
                            return (
                              <SubjectTags
                                key={index * 11}
                                text={name}
                                className="examRectangle"
                                textClassName="competitiveTags"
                              />
                            );
                          })}
                        </Grid>
                      </MiniCard>

                      <MiniCard
                        onMouseHover={this.schoolTutionCardMouseHover}
                        onMouseLeave={this.schoolTutionMouseLeave}
                        className={"schoolTution"}
                        onClick={() => {
                          this.props.handleSelect("/Tutions", 2);
                        }}
                        imgSrc={schoolOver}
                      >
                        <Typography variant="h3" className="Competetive-Exam">
                          Tuitions for 6 to 12
                        </Typography>

                        <Grid className="allRectangles">
                          {["TN-Board", "CBSE"].map((name, index) => {
                            return (
                              <SubjectTags
                                key={index * 34}
                                text={name}
                                className="tutionRectangle"
                                textClassName="tutionTags"
                              />
                            );
                          })}
                        </Grid>
                      </MiniCard>

                      <MiniCard
                        onMouseHover={this.examTestSeriesMouseHover}
                        onMouseLeave={this.examTestSeriesMouseLeave}
                        className={"examTestSeries"}
                        onClick={() => {
                          this.props.handleSelect("/Test-series", 3);
                        }}
                        imgSrc={documentOver}
                      >
                        <Typography variant="h3" className="Competetive-Exam">
                          Test Series
                        </Typography>

                        <Grid className="allRectangles">
                          {["JEE", "NEET"].map((name, index) => {
                            return (
                              <SubjectTags
                                key={index * 23}
                                text={name}
                                className="testSeriesRectangle"
                                textClassName="examSeriesTags"
                              />
                            );
                          })}
                        </Grid>
                      </MiniCard>
                    </Reveal>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container className="liveClassContainer">
            <Grid item xs={12} className="liveClassHeader">
              <Box className="width-70per">
                <Reveal duration={3000} effect="fadeInUp up cascade">
                  <Typography variant="h2" className="Committed-to-textlabel">
                    Live online classes, Study Materials, Online Tests, Exams
                    and more.
                  </Typography>
                  <Typography className="Become-a-part-textlabel">
                    Become a part of a collaborative community and a go-to
                    source of dedicated education leaders.
                  </Typography>
                </Reveal>
              </Box>
              <Box className="card width-70per">
                <Grid className="center-text-small-screen carouselContainer">
                  <Slider className="landing-carousel" {...settings}>
                    {carouselCardData.map((item: any, index: number) => (
                      <CarouselCard
                        key={index * 2}
                        title={item.title}
                        description={item.description}
                        onClick={this.handleOpenZohoFormPopup}
                        imgSrc={item.imgSrc}
                      />
                    ))}
                  </Slider>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid className="Unleash-outer"
            container

          >
            <Grid className="Potential-content"
              item
              xs={12}

            >
              <div className="width-70per">
                <Grid container>
                  <Grid item xs={12}>
                    <Reveal duration={3000} effect="fadeInUp up cascade">
                      <Typography variant="h2" className="startLearningWith">
                        Unleash the Full Learning Potential with{" "}
                        <span> Teachze</span>
                      </Typography>

                      <Typography className="getUnlimitedAccess">
                        Some Figures That Prove That We Are The Best In What We
                        Do
                      </Typography>
                    </Reveal>
                  </Grid>
                  <Grid className="flex-row"
                    item
                    xs={12}

                  >
                    {/* <Reveal duration={3000} effect="flipInX cascade"> */}
                    <Grid container justify="center">
                      <MiniStatsCard
                        iconSrc={book1}
                        title="Number of students"
                        quantity={this.state.examTaken}
                      />
                      <MiniStatsCard
                        iconSrc={onlineClass}
                        title="Trusted institutions"
                        quantity={this.state.onlineExamTaken}
                      />
                      <MiniStatsCard
                        iconSrc={elearning}
                        title="Assignment"
                        quantity={this.state.videoLessons}
                        className="flex-end"
                      >
                        <span style={{ color: "#fea801" }}>+</span>
                      </MiniStatsCard>
                      <MiniStatsCard
                        iconSrc={videoPlayer}
                        title="Classes"
                        quantity={this.state.minimumWatches}
                        className=""
                      >
                        <span style={{ color: "#fe5586" }}>+ </span>hrs
                      </MiniStatsCard>
                      <MiniStatsCard
                        iconSrc={groups}
                        title="E-Contents"
                        quantity={this.state.numberOfEducators}
                        className=""
                      >
                        <span style={{ color: "#369bf0" }}>+</span>
                      </MiniStatsCard>
                    </Grid>
                    {/* </Reveal> */}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className="spacing-100 classroomWrapper">
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                className="classroomInformationContainer"
              >
                <Reveal effect="fadeInLeft left cascade">
                  <div className="padding-right">
                    <Typography variant="h2" className="About-NEET-Text">
                      Bringing the classroom to you
                    </Typography>
                    <Typography className="This-platform-will-text">
                      Studies and Important Updates on the go. Now, don't miss
                      any Live Sessions or Mock Test Series. Indeed, quality
                      education is at your fingertips.
                    </Typography>
                    <ul>
                      <li className="view_box_content_text_li">All Devices</li>
                      <li className="ulList">
                        Get unlimited learning and study content from any
                        device, including mobile, tablet, and laptop.
                      </li>
                      <li className="view_box_content_text_li">
                        Track Your Progress
                      </li>
                      <li className="view_box_content_text_li">
                        Anywhere Access
                      </li>
                    </ul>
                    <Button
                      className="classroomInformation-button"
                      onClick={this.handleOpenZohoFormPopup}
                    >
                      <Typography className="classroom-button-label">
                        Start Learning
                      </Typography>
                    </Button>
                  </div>
                </Reveal>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="bitmap-img-align">
                <Reveal effect="fadeInRight right cascade">
                  <img src={developerActivity} className="bitmap-img" alt="Bitmap" />
                </Reveal>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou open={this.state.open} handleClose={this.handleClose} />
      </>
    );
  }
}
// Customizable Area End
