import React from "react";
import CourseDetailsController from "./CourseDetailsController.web";
import CourseDetailsHeader from "./CourseDetailsHeader.web";
import LandingPageFooter from "../LandingPage/LandingPageFooter/LandingPageFooter.web";
import CourseDetails from "./CourseDetails.web";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;

export default class CourseDetailsMainPage extends CourseDetailsController {
  // Customizable Area Start
  render() {
    return (
      <div style={root}>
        <CourseDetailsHeader navigation={undefined} />
        <CourseDetails navigation={undefined} />
        <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
      </div>
    );
  }
}

// Customizable Area End
