import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {
  user,
  group28,
  subjectChemistry,
  subjectMaths,
  subjectScience,
  subjectGeo,
  ellipse9,
  lecturer1,
  lecturer2,
  lecturer3,
  ellipse10,
  ellipse8,
  batchTeacher,
  group17,
} from "../assets";

export interface Props {
  navigation: any;
}

interface S {
  // Customizable Area Start
  open:boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  data: any;
  userDetails: any;
  batches: any;
  subjectList: any;
  selectedSubject: any;
  teacherDetails: any;
  isOpenEducatorDetails: boolean;
  educatorSubjects: any;
  testimonialsList: any;
  selectedTestimonial: any;
  isOpenScheduleDetails: boolean;
  scheduleList: any;
  selectedEducator: any;
  openZohoForm: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CourseDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      open:false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      data: {},
      userDetails: [
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
        {
          imgSource: user,
          name: "Vijay Zala",
          role: "Parent",
          text: "My son loves the sessions and I can already see the change.",
        },
      ],
      batches: [
        {
          imgSource: group28,
          title: "Batch For Term 2 class 9",
          startDate: "2nd January,2022",
          faculty: "Jitu Rathore, Nita Patel",
        },
        {
          imgSource: group28,
          title: "Batch For Term 2 class 9",
          startDate: "2nd January,2022",
          faculty: "Jitu Rathore, Nita Patel",
        },
        {
          imgSource: group28,
          title: "Batch For Term 2 class 9",
          startDate: "2nd January,2022",
          faculty: "Jitu Rathore, Nita Patel",
        },
      ],
      subjectList: [
        {
          id: "1",
          subjectName: "Chemistry",
          subjectLogo: subjectChemistry,
          chapterList: [
            {
              id: "1",
              chapterName: "Physical Chemistry",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
            {
              id: "2",
              chapterName: "Organic Chemistry",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
            {
              id: "3",
              chapterName: "Inorganic Chemistry",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
          ],
        },
        {
          id: "2",
          subjectName: "Mathematics",
          subjectLogo: subjectMaths,
          chapterList: [
            {
              id: "1",
              chapterName: "Trigonometry",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
            {
              id: "2",
              chapterName: "Algebra",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
            {
              id: "3",
              chapterName: "Straight Lines",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
          ],
        },
        {
          id: "3",
          subjectName: "Geography",
          subjectLogo: subjectGeo,
          chapterList: [
            {
              id: "1",
              chapterName: "World Map",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
            {
              id: "2",
              chapterName: "Oceans and Seas",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
            {
              id: "3",
              chapterName: "Topography",
              topics: [
                { id: "1", name: "General Topics & Mole Concept" },
                { id: "2", name: "Redox Reactions" },
                { id: "3", name: "General Topics & Mole Concept" },
                { id: "4", name: "Redox Reactions" },
                { id: "5", name: "General Topics & Mole Concept" },
                { id: "6", name: "Redox Reactions" },
              ],
            },
          ],
        },
      ],
      selectedSubject: {},
      teacherDetails: [
        {
          id: "1",
          image: group17,
          teacherName: "Vijay Sinha",
          qualifications: "M Tech , IIT Bombay",
          experience:
            "18 years experience of teaching. Mathematics graduation from Delhi University",
          no_of_likes: 3000,
        },
        {
          id: "2",
          image: batchTeacher,
          teacherName: "Nish Mishra",
          qualifications: "M Tech , IIT Bombay",
          experience:
            "18 years experience of teaching. Mathematics graduation from Delhi University",
          no_of_likes: 3500,
        },
        {
          id: "3",
          image: group17,
          teacherName: "Vijay Sinha 11",
          qualifications: "M Tech , IIT Bombay",
          experience:
            "18 years experience of teaching. Mathematics graduation from Delhi University",
          no_of_likes: 3000,
        },
        {
          id: "4",
          teacherName: "Nish Mishra 11",
          image: batchTeacher,
          qualifications: "M Tech , IIT Bombay",
          experience:
            "18 years experience of teaching. Mathematics graduation from Delhi University",
          no_of_likes: 3500,
        },
        {
          id: "5",
          image: group17,
          teacherName: "Vijay Sinha 22",
          qualifications: "M Tech , IIT Bombay",
          experience:
            "18 years experience of teaching. Mathematics graduation from Delhi University",
          no_of_likes: 3000,
        },
        {
          id: "6",
          teacherName: "Nish Mishra 22",
          image: batchTeacher,
          qualifications: "M Tech , IIT Bombay",
          experience:
            "18 years experience of teaching. Mathematics graduation from Delhi University",
          no_of_likes: 3500,
        },
      ],
      isOpenEducatorDetails: false,
      educatorSubjects: [
        { 
          imgSource: subjectScience,
          name: "Science"      
        },
        { 
          imgSource: subjectChemistry,
          name: "Chemistry"      
        },
        { 
          imgSource: subjectMaths,
          name: "Mathematics"      
        },
      ],
      testimonialsList: [
        {
          id: 0,
          profileImage: ellipse9,
          name: "Gamaho Gemoy",
          type: "Student",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
          id: 1,
          profileImage: ellipse8,
          name: "Gamaho Gemoy 2",
          type: "Student",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
          id: 2,
          profileImage: ellipse10,
          name: "Gamaho Gemoy 3",
          type: "Student",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        }
      ],
      selectedTestimonial: {},
      isOpenScheduleDetails: false,
      scheduleList: [
       {
        title: "Physical Chemistry - Lecture 1",
        unit: "Unit 1",
        date: "3 January,2022",
        time: "10:00 AM",
        lecturerImage: lecturer1,
        lecturerName: "Nisha Mishra",
        borderLeftColor: "#5580ea"
       },
       {
        title: "Physical Chemistry - Lecture 2",
        unit: "Unit 1",
        date: "3 January,2022",
        time: "10:00 AM",
        lecturerImage: lecturer2,
        lecturerName: "Jitu Mishra",
        borderLeftColor: "#55eaea"
       },
       {
        title: "Physical Chemistry - Lecture 3",
        unit: "Unit 1",
        date: "3 January,2022",
        time: "10:00 AM",
        lecturerImage: lecturer3,
        lecturerName: "Jit Rathore",
        borderLeftColor: "#eab044"
       },
       {
        title: "Physical Chemistry - Lecture 1",
        unit: "Unit 1",
        date: "4 January,2022",
        time: "10:00 AM",
        lecturerImage: lecturer1,
        lecturerName: "Nisha Mishra",
        borderLeftColor: "#ea8355"
       },
       {
        title: "Physical Chemistry - Lecture 1",
        unit: "Unit 1",
        date: "5 January,2022",
        time: "10:00 AM",
        lecturerImage: lecturer1,
        lecturerName: "Nisha Mishra",
        borderLeftColor: "#5580ea"
       },
       {
        title: "Physical Chemistry - Lecture 2",
        unit: "Unit 1",
        date: "5 January,2022",
        time: "10:00 AM",
        lecturerImage: lecturer2,
        lecturerName: "Jitu Mishra",
        borderLeftColor: "#eab044"
       },
       {
        title: "Physical Chemistry - Lecture 1",
        unit: "Unit 1",
        date: "26 January,2022",
        time: "10:00 AM",
        lecturerImage: lecturer2,
        lecturerName: "Jitu Mishra",
        borderLeftColor: "#eab044"
       },
       {
        title: "Physical Chemistry - Lecture 1",
        unit: "Unit 1",
        date: "27 January,2022",
        time: "10:00 AM",
        lecturerImage: lecturer2,
        lecturerName: "Jitu Mishra",
        borderLeftColor: "#eab044"
       },
       {
        title: "Physical Chemistry - Lecture 1",
        unit: "Unit 1",
        date: "2 February,2022",
        time: "10:00 AM",
        lecturerImage: lecturer2,
        lecturerName: "Jitu Mishra",
        borderLeftColor: "#eab044"
       },
      ],
      selectedEducator: null,
      openZohoForm: false,
    };
    this.userdata = this.userdata.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({ selectedSubject: this.state.subjectList[0], selectedTestimonial: this.state.testimonialsList[0] });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    
  };
  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    console.log("user_data", data);
    this.setState({ data: data });
  };

  handleSelectedSubjectInfo = (subjectId: any) => {
    const selectedSubjectInfo = this.state.subjectList.find(
      (subject: any) => subject.id === subjectId
    );
    this.setState({ selectedSubject: selectedSubjectInfo });
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  previousArrowClick = () => {
    const { selectedTestimonial, testimonialsList} = this.state;
    const index = testimonialsList.findIndex((item: any) => item.id === selectedTestimonial.id)
    if(index !== 0){
      this.setState({ selectedTestimonial: testimonialsList[selectedTestimonial.id-1] })    
    }
  }

  nextArrowClick = () => {
    const { selectedTestimonial, testimonialsList } = this.state;
    const index = testimonialsList.findIndex((item: any) => item.id === selectedTestimonial.id)
    if(index !== testimonialsList.length - 1){
      this.setState({ selectedTestimonial: testimonialsList[selectedTestimonial.id+1] })
    } 
  }

  handleCloseZohoFormPopup = () => {
    this.setState({ openZohoForm: false });
  };

  handleOpenZohoFormPopup = () => {
    this.setState({ openZohoForm: true });
  };
  // Customizable Area End
}
