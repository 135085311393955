import React from "react";
import {
    Typography,
    Grid,
    Button,
    Avatar
} from "@material-ui/core";
import FacultiesController from './FacultiesController.web'
import { facultyMainImage, group10 } from '../assets'
import Lottie from "react-lottie-player";
import lottieJson from "../../meetourFaculty.json";
import './Faculties.web.css'
import EducatorDetails from "./EducatorDetails.web";
import { Helmet } from "react-helmet";

const root = {
    flexGrow: 1,
    overflowX: "hidden",
    position: "relative"
} as const;
const Reveal = require("react-reveal/Reveal");

class Faculties extends FacultiesController {
    render() {
        const { crewList, selectedIndex, selectedCrewList } = this.state
        const cardDisplayBlock = (id: number) => {
            const layerElement = document.getElementById(`layer-id-${id}`)
            if (layerElement) {
                layerElement.style.display = "flex"
            }
        };

        const cardDisplayAbsolute = (id: number) => {
            const layerElement = document.getElementById(`layer-id-${id}`)
            if (layerElement) {
                layerElement.style.display = "none"
            }
        };

        const setSelectedEducator = (item: any) => {
            const { selectedEducator } = this.state
            selectedEducator.teacherName = item.name;
            selectedEducator.qualifications = item.qualification
            selectedEducator.experience = item.experience
            selectedEducator.about = item.about
            selectedEducator.certifications = item.certifications
            selectedEducator.achievements = item.achievements
            selectedEducator.subjects = item.subject
            selectedEducator.profileImg = item.profileImage
            selectedEducator.role = item.role
            selectedEducator.profileDetailsImage = item.profileDetailsImage
            this.setState({ isOpenEducatorDetails: true, selectedEducator: selectedEducator })
        }

        return (
            <div style={root}>
                 <Helmet>
          <title>Dedicated Expert Tutors | Teachze Live Online Classes | Teachze</title>
          <meta name="description" content="Meet our dedicated team of highly qualified master teachers with an abundant experience in each subject. Teachze LIVE online tuitions and Online coaching classes are handled by these expert tutors with a personalized attention to help your children understand, learn and score better." />
        </Helmet>
                <Grid container style={{ position: "relative" }}>
                    <Grid item xs={12} className="policy-bg-mask">
                        <Grid container>
                            <Grid item xs={12} sm={8} md={8} className="small-screen-centered">
                                <Reveal effect="fadeInLeft left cascade">
                                    <Typography variant="h1" className="policy_textlabel_heading">Meet Our Faculty</Typography>
                                </Reveal>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} className="small-screen-justify-center">
                                <Reveal effect="fadeInRight right cascade">
                                    <Lottie
                                        loop
                                        animationData={lottieJson}
                                        play
                                        className="policy-image"
                                    // style={{ width: 150, height: 150 }}
                                    />
                                    {/* <img src="https://assets2.lottiefiles.com/packages/lf20_shlj58ec.json" className="policy-image"></img> */}
                                </Reveal>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className="leadership-crew"
                        item
                        xs={12}     
                    >
                        <div className="width-70per">
                            <Reveal effect="fadeInUp">
                                <Grid className="direction-row m5" container >
                                    {crewList.map((item: any, index: number) => (
                                        <Grid className="align-items-center"
                                            item
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            style={{
                                               
                                                justifyContent: this.getJustifyContent(index),
                                            }}
                                        >
                                            <Grid
                                                className="crew_view_box"
                                                style={{
                                                    backgroundColor:
                                                        item.key === selectedIndex ? "#5580ea" : "#fff",
                                                    boxShadow:
                                                        item.key === selectedIndex
                                                            ? "box-shadow: 0 3px 36px 13px rgba(85, 128, 234, 0.2)"
                                                            : "0 3px 36px 13px rgba(145, 167, 208, 0.05)",
                                                }}
                                                key={item.key}
                                                onClick={() => {
                                                    this.handleSelectTab(item.key);
                                                }}
                                            >
                                                <Typography variant="h2"
                                                    className="crew_view_box_header"
                                                    style={{
                                                        color:
                                                            item.key === selectedIndex
                                                                ? "white"
                                                                : "#394052",
                                                    }}
                                                >
                                                    {item.label}
                                                </Typography>
                                                {/* <Typography
                                                    className="crew_view_box_details"
                                                    style={{
                                                        color:
                                                            item.key === selectedIndex
                                                                ? "white"
                                                                : "#394052",
                                                    }}
                                                >
                                                     {item.detail}
                                                </Typography> */}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Reveal>

                            <Reveal effect="fadeInUp">
                                <Grid className="direction-row" container spacing={4}>
                                    {selectedCrewList.map((item: any, index: number) => (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <div className="faculty-card"
                                                onMouseEnter={() => cardDisplayBlock(item.id)}
                                                onMouseLeave={() => cardDisplayAbsolute(item.id)}
                                                onClick={() => setSelectedEducator(item)}
                                                >
                                                <Grid container>
                                                    <Grid item xs={12} className="display-style">
                                                        {item.profileImage ?
                                                            <img title="Profile Name" alt="Profile image" src={item.profileImage} className="faculty-profile-img"></img>
                                                            :
                                                            <Avatar
                                                                src={"/"}
                                                                className="faculty-profile-avatar"
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} className="display-style">
                                                        <Typography variant="h4" className="faculty-name">{item.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="display-style">
                                                        <Typography className="faculty-role-text">{item.qualification}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="display-style">
                                                        <Typography className="faculty-location-text">{item.experience}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="display-style">
                                                        <Button className="view-details-btn">
                                                            <Typography className="view-details-text">View Details</Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <div className="layer faculty-desc-text" ref="layer" id={`layer-id-${item.id}`}>
                                                    {item.about}
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Reveal>
                            
                        </div>
                    </Grid>

                    <EducatorDetails
                        onCloseModal={() => {
                            this.setState({ isOpenEducatorDetails: false });
                        }}
                        open={this.state.isOpenEducatorDetails}
                        educatorSubjects={this.state.educatorSubjects}
                        testimonialsList={this.state.testimonialsList}
                        selectedTestimonial={this.state.selectedTestimonial}
                        previousArrowClick={this.previousArrowClick}
                        nextArrowClick={this.nextArrowClick}
                        selectedEducator={this.state.selectedEducator}
                    />
                </Grid>
            </div>
        );
    }
}

export default Faculties;