import React from "react";
// Customizable Area Start
import LandingPageController from '../LandingPage/LandingPageController.web'
import LandingPageHeader from "../LandingPage/LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "../LandingPage/LandingPageFooter/LandingPageFooter.web";
import Faculties from "./Faculties.web";
import "../LandingPage.web.css";
import { HISTORY } from "../../../../components/src/common";
// Customizable Area End
const root = {
    flexGrow: 1,
    overflowX: "hidden",
    position: "relative"
} as const;

export default class FacultiesMainPage extends LandingPageController {
    // Customizable Area Start
    render() {
        const { mainTabIndex } = this.state

        return (
            <div style={root}>
                <LandingPageHeader mainTabIndex={4} handleSelectMainTab={this.handleSelectMainTab} />
                <Faculties navigation={undefined} />
                <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
            </div>
        );
    }
}

// Customizable Area End
