export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const img21kSchholLogo = require("../assets/logo.png");
export const imgloginScreenBackground = require("../assets/5960511fc2d87d4daf4cc703ead7575b56e784c2.png");
export const manImage = require("../assets/e6c715c3911a4b94bcec01de06660f7797f20906.png");
export const bigCloud = require("../assets/1648e95fb4228d91b398ec2537155970ea177e68.png");
export const smallCloud = require("../assets/c06e06dbe043cae67d56fbfc0834974f5831a8c7.png");
export const ic_username = require("../assets/5ddfa7323d356462634a28de23eb0c29b2ca7e1a.png");
export const ic_password = require("../assets/9e7a3dfc2792cfef7e2c8d7cc656a114831ebd8c.png");
export const ic_google = require("../assets/c22861285cbbeaf7baa9173dd3e49bb5fca4eff9.png");
export const ic_facebook = require("../assets/c6ddb9cf1b26d20e4b3af945eff313d7f1f88279.png");
export const ic_apple = require("../assets/c878dc011ac5307ddb42928be0f663af9c85267b.png");
export const ic_microsoft = require("../assets/65a9a767ae479c43a94f8e3b96d51e88c37b5cb3.png");
export const ic_arrowdown = require("../assets/fe250fb9202a6f45c0eb878928f0f8bed3b71daf.png");
export const landingPage1 = require("../assets/9898.png");
export const book = require("../assets/112-book-morph-outline.png");
export const school = require("../assets/486-school-outline.png");
export const editDocument = require("../assets/245-edit-document-outline.png");
export const developerActivity = require("../assets/Bitmap@2x (1).png");
export const book1 = require("../assets/book-1.png");
export const headerlogo = require("../assets/headerlogo.jpg");
export const onlineClass = require("../assets/online-class.png");
export const elearning = require("../assets/elearning-1.png");
export const videoPlayer = require("../assets/video-player.png");
export const groups = require("../assets/groups.png");
export const animation_gif = require("../assets/animation_640_kxoiv5x9.gif");
export const book_gif = require("../assets/112-book-morph-outline (2).gif");
export const school_gif = require("../assets/486-school-outline (2).gif");
export const editDocument_gif = require("../assets/245-edit-document-outline (1).gif");
export const onlineLearning = require("../assets/online-learning.png");
export const bitmap1 = require("../assets/bitmap1.png");
export const nwe = require("../assets/bitmap-3.png");
export const hhh = require("../assets/hhh.png");
export const me = require("../assets/image-user.png");
export const tweet = require("../assets/twee.png");
export const insta = require("../assets/instaa.png");
export const fbk = require("../assets/fb.png");
export const share = require("../assets/sharee.png");
export const zoho_register_left_panel = require("../assets/zoho-register-left-panel.svg");
export const government = require("../assets/government.png");
export const governmentDisable = require("../assets/govermentDisable.png");
export const exam = require("../assets/exam-6.png");
export const examEnable = require("../assets/image-test-selected.png");
export const presentation = require("../assets/presentation.png");
export const presentationEnable = require("../assets/presentationEnable.png");
export const rightArrow = require("../assets/group-31.png");
export const leftArrow = require("../assets/left-arrow.png");
export const downArrowImg = require("../assets/down-arrow-image.png");
export const upArrowImg = require("../assets/up-arrow-image.png");
export const expandIcon = require("../assets/expand-icon.svg");
export const collapseIcon = require("../assets/line.svg");
export const group28 = require("../assets/group-28.png");
export const success = require("../assets/success.png");
export const conversation = require("../assets/conversation.png");
export const onlineTest = require("../assets/online-test.png");
export const feedback = require("../assets/feedback.png");
export const rising = require("../assets/rising.png");
export const webinar = require("../assets/webinarr.png");
export const instant = require("../assets/instant.png");
export const bookk = require("../assets/bookk.png");
export const findd = require("../assets/finddd.png");
export const aboutneet = require("../assets/aboutneet.png");
export const Facetoface = require("../assets/Facetoface.png");

export const group35 = require("../assets/group-35.png");
export const group38 = require("../assets/group-38.png");
export const videoEdition = require("../assets/video-edition.png");
export const videoMarketing = require("../assets/video-marketing-1.png");
export const group9 = require("../assets/group-9.png");
export const books = require("../assets/books.png");
export const bitmap2 = require("../assets/bitmap-2.png");
export const quote = require("../assets/bitmap-quote.png");
export const user = require("../assets/image-user.png");
export const vectorMask = require("../assets/vector-mask.png");
export const logoSidePlaced = require("../assets/Logo-PNG2.png");
export const call = require("../assets/call.png");
export const email = require("../assets/email.png");
export const facebook = require("../assets/facebook.png");
export const instagram = require("../assets/instagram.png");
export const twitter = require("../assets/twitter.png");
export const youtube = require("../assets/youtube.png");
export const group28_2 = require("../assets/group-281 (2).png");
export const group28_3 = require("../assets/group-28-2.png");

export const imageHeader = require("../assets/image-header.png");
export const imageFaculty = require("../assets/image-faculty.png");
export const imageMaterial = require("../assets/image-material.png");
export const imageAnalysis = require("../assets/image-analysis.png");
export const imageCalendar = require("../assets/image-calender.png");
export const imageLaptop = require("../assets/image-laptop.png");
export const imageGroup = require("../assets/image-group.png");
export const imagePaper = require("../assets/image-paper.png");
export const imageTest = require("../assets/image-test.png");
export const calendar = require("../assets/image-calander.png");
export const imgUser = require("../assets/img-user.png");
export const subjectScience = require("../assets/image-subject-science.png");
export const subjectChemistry = require("../assets/image-subject-chemistry.png");
export const subjectMaths = require("../assets/image-subject-maths.png");
export const subjectGeo = require("../assets/image-subject-geo.png");
export const imageTick = require("../assets/image-tick.png");
export const event1 = require("../assets/event-1.png");
export const bulletin = require("../assets/view-bullatine.svg");
export const batchTeacher = require("../assets/batch_teach_you.png");
export const likes = require("../assets/like-1.png");
export const group34 = require("../assets/group-34.png");
export const job = require("../assets/job.png");
export const experience = require("../assets/experience.png");
export const checkBox = require("../assets/check-box.png");
export const wreath = require("../assets/wreath.png");
export const bookmark = require("../assets/bookmark.png");
export const group22 = require("../assets/group-22.png");
export const ellipse9 = require("../assets/ellipse-9.png");
export const ellipse8 = require("../assets/ellipse-8.png");
export const ellipse10 = require("../assets/ellipse-10.png");
export const imageArrowRight = require("../assets/image-arrow.png");
export const videoPlayIcon = require("../assets/video-play-icon.png");
export const thumbnail = require("../assets/thumbnail.png");
export const lecturer1 = require("../assets/lecturer1.png");
export const lecturer2 = require("../assets/lecturer2.png");
export const lecturer3 = require("../assets/lecturer3.png");
export const policyMainImage = require("../assets/screenshot-2022-01-21-at-3-43-29-pm.png");
export const onlineLearningPana = require("../assets/online-learning-pana.png");
export const webinarPana = require("../assets/webinar-pana.png");
export const groupVideoPana = require("../assets/group-video-pana.png");
export const webinarBro = require("../assets/webinar-bro-2.png");
export const telecommuting = require("../assets/telecommuting.png");
export const discussion = require("../assets/discussion.png");
export const hours = require("../assets/hours.png");
export const preparation = require("../assets/Preparation.png");
export const qa = require("../assets/q&a.png");
export const structured = require("../assets/Structured.png");
export const group10 = require("../assets/group-10.png");
export const group17 = require("../assets/group-17.png");
export const customerService = require("../assets/customer-service-1.png");
export const workHard = require("../assets/87979-work-hard-1.png");
export const responsive2 = require("../assets/responsive-2.png");
export const trophy1 = require("../assets/trophy-1.png");
export const bestOfficeEmployee = require("../assets/best-office-employee.png");
export const teamSanthosh = require("../assets/team-santhosh.png");
export const teamYeshwanth = require("../assets/team-yeshwanth.png");
export const teamDinesh = require("../assets/team-dinesh.png");
export const teamJoshi = require("../assets/team-joshi.png");
export const Anywhere_learning = require("../assets/Anywhere learning@3x.png");
export const Self_paced_Learning_img = require("../assets/Self paced Learning@3x.png");
export const Vernacular_Coaching_img = require("../assets/Vernacular Coaching@3x.png");
export const Simplistic_Approach_img = require("../assets/Simplistic Approach@3x.png");
export const Thought_Transparency_img = require("../assets/Thought Transparency@3x.png");
export const Interactive_Advanced_Platform_img = require("../assets/Interactive Advanced Platform@3x.png");
export const Detailed_Knowledge_Base_img = require("../assets/Detailed Knowledge Base@3x.png");
export const Dinesh_Kumar_square = require("../assets/Dinesh_Kumar_square.png");
export const Joshi_kumar_square = require("../assets/Joshi_kumar_square.png");
export const Mrs_Vaishnavi_Narayanan_square = require("../assets/Mrs_Vaishnavi_Narayanan_square.png");

//for performance report
export const studentGrades = require("../assets/student-grades.png");
export const businessReport = require("../assets/business-report.png");
export const seoReport = require("../assets/seo-report.png");
export const flasks = require("../assets/flasks.png");
export const speedometer = require("../assets/speedometer.png");
export const career = require("../assets/career.png");

//for blog
export const image_child = require("../assets/image_child.png");
export const image_family = require("../assets/image_family.png");
export const image_flag = require("../assets/image_flag.png");
export const image_user_blog = require("../assets/image_user_blog.png");
export const facultyMainImage = require("../assets/screenshot-2022-01-21-at-5-02-49-pm.png");
export const group2 = require("../assets/group-2.png");
export const group2_1 = require("../assets/group-2(1).png");
export const group2_2 = require("../assets/group-2(2).png");
export const image_dropdown_arrow = require("../assets/image-dropdown-arrow.png");
export const image_dropdown_arrow_blue = require("../assets/image-dropdown-arrow-blue.png");
export const search = require("../assets/search-2.png");
export const Detailed_Knowledge_Base = require("../assets/Detailed Knowledge Base.svg");
export const Interactive_Advanced_Platform = require("../assets/Interactive Advanced Platform.svg");
export const Self_paced_Learning = require("../assets/Self paced Learning.svg");
export const Simplistic_Approach = require("../assets/Simplistic Approach.svg");
export const Thought_Transparency = require("../assets/Thought Transparency.svg");
export const Vernacular_Coaching = require("../assets/Vernacular Coaching.svg");
export const image_flag_wide = require("../assets/image_flag_wide.png");
export const image_26 = require("../assets/image_26.png");
export const bridging = require("../assets/bridging.png")
export const digitizing = require("../assets/digitizing.png")
export const institutions = require("../assets/institutions.png")
export const teachers = require("../assets/teachers.png")
export const educating = require("../assets/educating.png")
export const customizing = require("../assets/customizing.png")

//for faculty
export const Dinesh_Kumar = require("../assets/Dinesh Kumar@3x.png");
export const Mrs_Vaishnavi_Narayanan = require("../assets/Mrs. Vaishnavi Narayanan@3x.png");
export const S_Subramaniam = require("../assets/S.Subramaniam@3x.png");
export const Govarthanan = require("../assets/Govarthanan@3x.png");
export const HARI_KRISHNAN_R = require("../assets/HARI KRISHNAN R@3x.png");
export const J_Ajith_Kumar = require("../assets/J Ajith Kumar@3x.png");
export const KANNAN_N = require("../assets/KANNAN N@3x.png");
export const Rahman_Khan_M = require("../assets/Rahman Khan M@3x.png");
export const S_ARAVINDHA_KRISHNAN = require("../assets/S ARAVINDHA KRISHNAN@3x.png");
export const Shanmugapriya = require("../assets/Shanmugapriya@3x.png");
export const Yogeshraj_I = require("../assets/Yogeshraj. I@3x.png");
export const Ms_Bala_Divya = require("../assets/Ms. Bala Divya@3x.png");
export const Kalpana_Alla = require("../assets/Kalpana Alla@3x.png");
export const Dr_Gurumoorthy = require("../assets/Dr. Gurumoorthy@3x.png");
export const CA_D_Abhinandh_Kumar = require("../assets/CA.D.Abhinandh Kumar@3x.png");
export const CA_Kameswaran = require("../assets/CA Kameswaran@3x.png");
export const Dr_Chitra_Natarajan = require("../assets/Dr. Chitra Natarajan@3x.png");
export const Dr_P_Jothi = require("../assets/Dr. P Jothi @3x.png");
export const K_NARENDIRAN = require("../assets/K. NARENDIRAN@3x.png");
export const K_Tamil_oli = require("../assets/K.Tamil oli@3x.png");
export const Kavitha_Sarma_Kolachana = require("../assets/Kavitha Sarma Kolachana@3x.png");
export const Mrs_Lalitha = require("../assets/Mrs Lalitha@3x.png");
export const MRS_NACHAMMAI_VENKATACHALAM = require("../assets/MRS.NACHAMMAI VENKATACHALAM@3x.png");
export const PRAVEEN_SHANKER_N = require("../assets/PRAVEEN SHANKER N@3x.png");
export const S_D_Vishnu_Vardhan = require("../assets/S D Vishnu Vardhan@3x.png");
export const Tamilselvan_V = require("../assets/Tamilselvan V@3x.png");
export const Vahitha_Banu_M_A = require("../assets/Vahitha Banu M A @3x.png");
export const Dinesh_Kumar_detailImg = require("../assets/Dinesh_Kumar.png");
export const Mrs_Vaishnavi_Narayanan_detailImg = require("../assets/Mrs._Vaishnavi_Narayanan.png");
export const S_Subramaniam_detailImg = require("../assets/_S.Subramaniam.png");
export const _J_Ajith_Kumar_detailImg = require("../assets/_J_Ajith_Kumar-removebg-preview.png");
export const _S_ARAVINDHA_KRISHNAN_detailImg = require("../assets/_S_ARAVINDHA_KRISHNAN-removebg-preview.png");
export const _Yogeshraj_I_detailImg = require("../assets/_Yogeshraj._I-removebg-preview.png");
export const Govarthanan_detailImg = require("../assets/Govarthanan-removebg-preview.png");
export const HARI_KRISHNAN_R_detailImg = require("../assets/HARI_KRISHNAN_R-removebg-preview.png");
export const KANNAN_N_detailImg = require("../assets/KANNAN_N-removebg-preview.png");
export const Rahman_Khan_M_detailImg = require("../assets/Rahman_Khan_M-removebg-preview.png");
export const Shanmugapriya_detailImg = require("../assets/Shanmugapriya-removebg-preview.png");
export const _Dr_Gurumoorthy_detailImg = require("../assets/_Dr._Gurumoorthy.png");
export const _Dr_P_Jothi_detailImg = require("../assets/_Dr._P_Jothi.png");
export const _K_NARENDIRAN_detailImg = require("../assets/_K._NARENDIRAN.png");
export const _Mrs_Lalitha_detailImg = require("../assets/_Mrs_Lalitha.png");
export const CA_D_Abhinandh_Kumar_detailImg = require("../assets/CA.D.Abhinandh_Kumar.png");
export const CA_Kameswaran_detailImg = require("../assets/CA_Kameswaran.png");
export const Dr_Chitra_Natarajan_detailImg = require("../assets/Dr._Chitra_Natarajan.png");
export const K_Tamil_oli_detailImg = require("../assets/K.Tamil_oli.png");
export const Kalpana_Alla_detailImg = require("../assets/Kalpana_Alla.png");
export const Kavitha_Sarma_Kolachana_detailImg = require("../assets/Kavitha_Sarma_Kolachana.png");
export const MRS_NACHAMMAI_VENKATACHALAM_detailImg = require("../assets/MRS.NACHAMMAI_VENKATACHALAM.png");
export const Ms_Bala_Divya_detailImg = require("../assets/Ms._Bala_Divya.png");
export const PRAVEEN_SHANKER_N_detailImg = require("../assets/PRAVEEN_SHANKER_N.png");
export const S_D_Vishnu_Vardhan_detailImg = require("../assets/S_D_Vishnu_Vardhan.png");
export const Tamilselvan_V_detailImg = require("../assets/Tamilselvan_V.png");
export const Vahitha_Banu_M_A_detailImg = require("../assets/Vahitha_Banu_M_A.png");

//for subjects
export const dataScience = require("../assets/data-science.png");
export const chemistry = require("../assets/chemistry.png");
export const electronicCircuit = require("../assets/electronic-circuit.png");
export const Biology = require("../assets/Biology.png");
export const account = require("../assets/account.png");
export const economics = require("../assets/economics.png");
export const Enviourmental = require("../assets/Enviourmental.png");
export const justice = require("../assets/justice.png");
export const tax = require("../assets/tax.png");

// course card image
export const JEE_21 = require("../assets/JEE_21.png");
export const NEET_21 = require("../assets/NEET_21.png");
export const CBSE_21 = require("../assets/CBSE_21.png");
export const CA_21 = require("../assets/CA_21.png");
export const FOUNDATION_21 = require("../assets/FOUNDATION_21.png");
export const IGCSE_21 = require("../assets/IGCSE_21.png");
export const TNB_21 = require("../assets/TNB_21.png");
export const NEETTS_21 = require("../assets/NEETTS_21.png");
export const JEETS_21 = require("../assets/JEETS_21.png");

export const Pop_up_Img = require("../assets/POPUP-TSAT-WEBSITE.png");
export const Pop_up__New_Img = require("../assets/add_popup.png");

export const Header_Call = require("../assets/callHeader.png")
