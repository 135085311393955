import React, { useState, useEffect } from "react";
import { Modal, Grid, Typography, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ZohoRegistrationForm from "./ZohoRegistrationForm.web";

interface IZohoForm {
  onCloseModal: any;
  closeThankYou:any;
  openThankyouModal:any;
  open: any;
  isFirstTime?: boolean;
}

const ZohoFormModalPopup: React.FC<IZohoForm> = ({
  open,
  openThankyouModal,
  closeThankYou,
  onCloseModal,
  isFirstTime = false,
}) => {
  const [isFirstUrlInIframe, setIsFirstUrlInIframe] = useState(isFirstTime);
  const handleCloseForm: React.ReactEventHandler<HTMLIFrameElement> = (e) => {
    if (!isFirstUrlInIframe) {
      setTimeout(() => {
        onCloseModal();
      }, 1000);
    } else setIsFirstUrlInIframe(false);
  };

  useEffect(() => {
    if (open) setIsFirstUrlInIframe(isFirstTime);
  }, [isFirstTime, open]);

  return (
    <Modal
      id="zoho-form-modal"
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      aria-modal={true}
    >
      <div className="form-modal">
        <ZohoRegistrationForm openThankyouModal={openThankyouModal} closeThankYou={closeThankYou} onCloseModal={onCloseModal} />
        {/* 
        <iframe
          frameBorder={0}
          id="iframe_zoho"
          onLoad={handleCloseForm}
          style={{ height: "inherit", width: "100%", border: "none" }}
          src="https://forms.zohopublic.in/teachze/form/TeachzeJEETestSeriesBuilder/formperma/WcT-RJVKTT_KNnBDkzYVYiudqxPl_zx9veyteB1fCJk"
        ></iframe> */}
      </div>
    </Modal>
  );
};

export default ZohoFormModalPopup;
