import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/common";
import axios from "axios";
import { toast } from "react-toastify";
import {} from "../assets";

export interface Props {
  navigation?: any;
  openThankyouModal: () => void;
  onCloseModal: () => void;
  closeThankYou: () => void;
}

interface S {
  // Customizable Area Start
  alert: boolean;
  alertContent: string;
  open: boolean;
  data: any;
  studentName: string;
  studentnameerror: boolean;
  studentnameerrorText: string;
  parentName: string;
  parentnameerror: boolean;
  parentnameerrorText: string;
  phonenumber: string;
  phoneerrorText: string;
  phoneerror: boolean;
  email: string;
  emailerror: boolean;
  emailerrorText: string;
  dialCode: any;
  countries: Array<any>;
  countryError: string;
  schoolName: string;
  schoolnameerror: boolean;
  schoolnameerrorText: string;
  cityName: string;
  citynameerror: boolean;
  citynameerrorText: string;
  boards: Array<any>;
  boardError: boolean;
  boardErrorText: string;
  selectedBoard: any;
  states: Array<any>;
  stateError: boolean;
  stateErrorText: string;
  selectedState: any;
  coachingCoursesOptions: Array<any>;
  filteredcoachingCoursesOptions: Array<any>;
  commerceClassesOptiona: Array<any>;
  testSeriesOptions: Array<any>;
  tuitionClassesOptions: Array<any>;
  tsatClassesOptions: Array<any>;
  courseError: boolean;
  selectedCourse: any;
  courseErrorText: string;
  privacyPolicy: boolean;
  currentClassesList: Array<any>;
  classError: boolean;
  classErrorText: string;
  selectedCurrentClass: any;
  programList: Array<any>;
  programError: boolean;
  programErrorText: string;
  selectedProgram: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export const configJSON = require("./../config.js");
export default class ZohoRegistrationForm extends BlockComponent<Props, S, SS> {
  postFormDetails: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      alert: false,
      alertContent: "",
      open: false,
      data: "",
      studentName: "",
      studentnameerror: false,
      studentnameerrorText: "",
      parentName: "",
      parentnameerror: false,
      parentnameerrorText: "",
      phonenumber: "",
      phoneerrorText: "",
      phoneerror: false,
      schoolName: "",
      schoolnameerror: false,
      schoolnameerrorText: "",
      cityName: "",
      citynameerror: false,
      citynameerrorText: "",
      email: "",
      emailerror: false,
      emailerrorText: "",
      countries: [],
      countryError: "",
      boards: [
        {
          id: 1,
          name: "TN State Board",
        },
        {
          id: 2,
          name: "CBSE",
        },
        {
          id: 3,
          name: "Others",
        },
      ],
      boardError: false,
      boardErrorText: "",
      selectedBoard: "",

      states: [
        {
          id: "AN",
          name: "Andaman and Nicobar Islands",
        },
        {
          id: "AP",
          name: "Andhra Pradesh",
        },
        {
          id: "AR",
          name: "Arunachal Pradesh",
        },
        {
          id: "AS",
          name: "Assam",
        },
        {
          id: "BR",
          name: "Bihar",
        },
        {
          id: "CH",
          name: "Chandigarh",
        },
        {
          id: "CT",
          name: "Chhattisgarh",
        },
        {
          id: "DN",
          name: "Dadra and Nagar Haveli",
        },
        {
          id: "DD",
          name: "Daman and Diu",
        },
        {
          id: "DL",
          name: "Delhi",
        },
        {
          id: "GA",
          name: "Goa",
        },
        {
          id: "GJ",
          name: "Gujarat",
        },
        {
          id: "HR",
          name: "Haryana",
        },
        {
          id: "HP",
          name: "Himachal Pradesh",
        },
        {
          id: "JK",
          name: "Jammu and Kashmir",
        },
        {
          id: "JH",
          name: "Jharkhand",
        },
        {
          id: "KA",
          name: "Karnataka",
        },
        {
          id: "KL",
          name: "Kerala",
        },
        {
          id: "LA",
          name: "Ladakh",
        },
        {
          id: "LD",
          name: "Lakshadweep",
        },
        {
          id: "MP",
          name: "Madhya Pradesh",
        },
        {
          id: "MH",
          name: "Maharashtra",
        },
        {
          id: "MN",
          name: "Manipur",
        },
        {
          id: "ML",
          name: "Meghalaya",
        },
        {
          id: "MZ",
          name: "Mizoram",
        },
        {
          id: "NL",
          name: "Nagaland",
        },
        {
          id: "OR",
          name: "Odisha",
        },
        {
          id: "PY",
          name: "Puducherry",
        },
        {
          id: "PB",
          name: "Punjab",
        },
        {
          id: "RJ",
          name: "Rajasthan",
        },
        {
          id: "SK",
          name: "Sikkim",
        },
        {
          id: "TN",
          name: "Tamil Nadu",
        }, {
          id: "TG",
          name: "Telangana",
        }, {
          id: "TR",
          name: "Tripura",
        }, {
          id: "UP",
          name: "Uttar Pradesh",
        },
        {
          id: "UT",
          name: "Uttarakhand",
        },
        {
          id: "WB",
          name: "West Bengal",
        },
        
      ],
      stateError: false,
      stateErrorText: "",
      selectedState: "",
      filteredcoachingCoursesOptions:[],
      coachingCoursesOptions: [
        {
          id: 0,
          name: "NEET/JEE Coaching Class",
          isOptGrp: true,
          programType:"",
          from:"competitive" 
        },
        {
          id: 1,
          name: "NEET 1 Year",
          isOptGrp: false,
          programType:"NEET Course",
          from:"competitive" 
        },
        {
          id: 2,
          name: "NEET 2 Years",
          isOptGrp: false,
          programType:"NEET Course",
          from:"competitive" 

        },
           {
          id: 6,
          name: "NEET Crash Course",
          isOptGrp: false,
          programType:"NEET Course",
          from:"competitive" 
        },
        {
          id: 3,
          name: "NEET Repeater Course",
          isOptGrp: false,
          programType: "NEET Course",
          from:"competitive" 


        },
        {
          id: 4,
          name: "JEE 1 Year",
          isOptGrp: false,
          programType:"JEE Course",
          from:"competitive" 


        },
        {
          id: 5,
          name: "JEE 2 Years",
          isOptGrp: false,
          programType: "JEE Course" ,
          from:"competitive" 


        },
        {
          id: 6,
          name: "JEE Crash Course",
          isOptGrp: false,
          programType: "JEE Course",
          from:"competitive" 


        },
     
        
        {
          id: 5,
          name: "Commerce Class",
          isOptGrp: true,
          programType:"",
          from:"competitive" 

        },
        {
          id: 6,
          name: "CA Foundation",
          isOptGrp: false,
          programType:"CA Course",
          from:"competitive" 

        },
        {
          id: 7,
          name: "CA Intermediate",
          isOptGrp: false,
          programType:"CA Course",
          from:"competitive" 


        },
        // {
        //   id: 11,
        //   name: "CA Final",
        //   isOptGrp: false,
        // },
        // {
        //   id: 12,
        //   name: "CMA Foundation",
        //   isOptGrp: false,
        // },
        // {
        //   id: 13,
        //   name: "CMA Intermediate",
        //   isOptGrp: false,
        // },
        // {
        //   id: 14,
        //   name: "CMA Final",
        //   isOptGrp: false,
        // },
        // {
        //   id: 15,
        //   name: "CS Foundation",
        //   isOptGrp: false,
        // },
        // {
        //   id: 16,
        //   name: "CS Intermediate",
        //   isOptGrp: false,
        // },
        // {
        //   id: 17,
        //   name: "CS Final",
        //   isOptGrp: false,
        // },
        {
          id: 8,
          name: "Test Series",
          isOptGrp: true,
          programType:"",
          from:"tuition" 

        },
        {
          id: 9,
          name: "NEET Test Series",
          isOptGrp: false,
          programType: "NEET Course",
          from:"testseries" 

        },
        {
          id: 10,
          name: "JEE Test Series",
          isOptGrp: false,
          programType: "JEE Course",
          from:"testseries" 

        },
        {
          id: 11,
          name: "Tuition",
          isOptGrp: true,
          programType:"",
          from:"tuition" 
        },
        {
          id: 12,
          name: "Class 6",
          isOptGrp: false,
          programType:"Tuition",
          from:"tuition" 

        },
        {
          id: 13,
          name: "Class 7",
          isOptGrp: false,
          programType:"Tuition",
          from:"tuition" 

        },
        {
          id: 14,
          name: "Class 8",
          isOptGrp: false,
          programType:"Tuition",
          from:"tuition" 

        },
        {
          id: 15,
          name: "Class 9",
          isOptGrp: false,
          programType:"Tuition",
          from:"tuition" 

        },
        {
          id: 16,
          name: "Class 10",
          isOptGrp: false,
          programType:"Tuition",
          from:"tuition" 

        },
        {
          id: 17,
          name: "Class 11",
          isOptGrp: false,
          programType:"Tuition",
          from:"tuition" 

        },
        {
          id: 18,
          name: "Class 12",
          isOptGrp: false,
          programType:"Tuition",
          from:"tuition" 

        },
        // {
        //   id: 29,
        //   name: "Class XII Tuition Science",
        //   isOptGrp: false,
        // },
        // {
        //   id: 30,
        //   name: "Class XII Tuition Commerce",
        //   isOptGrp: false,
        // },
        {
          id: 19,
          name: "Foundation Course ",
          isOptGrp: true,
          programType:"",
          from:"competitive" 

        },
        {
          id: 20,
          name: "Foundation Class 8",
          isOptGrp: false,
          programType:"Foundation Course",
          from:"competitive" 

        },
        {
          id: 21,
          name: "Foundation Class 9 & 10",
          isOptGrp: false,
          programType:"Foundation Course",
          from:"competitive" 

        },
        // {
        //   id: 21,
        //   name: "Foundation Class 10",
        //   isOptGrp: false,
        // },
        // {
        //   id: 34,
        //   name: "TSAT",
        //   isOptGrp: true,
        // },
        // {
        //   id: 35,
        //   name: "Grade VII",
        //   isOptGrp: false,
        // },
        // {
        //   id: 36,
        //   name: "Grade VIII",
        //   isOptGrp: false,
        // },
        // {
        //   id: 37,
        //   name: "Grade IX",
        //   isOptGrp: false,
        // },
        // {
        //   id: 38,
        //   name: "Grade X",
        //   isOptGrp: false,
        // },
        // {
        //   id: 39,
        //   name: "Grade XI",
        //   isOptGrp: false,
        // },
      ],
      commerceClassesOptiona: [
        {
          id: 1,
          name: "CA Foundation",
        },
        {
          id: 2,
          name: "CA Intermediate",
        },
        {
          id: 3,
          name: "CA Final",
        },
        {
          id: 4,
          name: "CMA Foundation",
        },
        {
          id: 5,
          name: "CMA Intermediate",
        },
        {
          id: 6,
          name: "CMA Final",
        },
        {
          id: 7,
          name: "CS Foundation",
        },
        {
          id: 8,
          name: "CS Intermediate",
        },
        {
          id: 9,
          name: "CS Final",
        },
      ],
      testSeriesOptions: [
        {
          id: 1,
          name: "NEET Test Series",
        },
        {
          id: 2,
          name: "JEE Test Series",
        },
      ],
      tuitionClassesOptions: [
        {
          id: 1,
          name: "Class VI Tuition",
        },
        {
          id: 2,
          name: "Class VII Tuition",
        },
        {
          id: 3,
          name: "Class VIII Tuition",
        },
        {
          id: 4,
          name: "Class IX Tuition",
        },
        {
          id: 5,
          name: "Class X Tuition",
        },
        {
          id: 6,
          name: "Class XI Tuition",
        },
        {
          id: 7,
          name: "Class XI Tuition",
        },
        {
          id: 8,
          name: "Class XII Tuition Science",
        },
        {
          id: 9,
          name: "Class XII Tuition Commerce",
        },
        {
          id: 10,
          name: "Foundation Course Class VIII",
        },
        {
          id: 11,
          name: "Foundation Course Class IX",
        },
        {
          id: 12,
          name: "Foundation Course Class X",
        },
      ],
      tsatClassesOptions: [
        {
          id: 1,
          name: "Grade VII",
        },
        {
          id: 2,
          name: "Grade VIII",
        },
        {
          id: 3,
          name: "Grade IX",
        },
        {
          id: 4,
          name: "Grade X",
        },
        {
          id: 5,
          name: "Grade XI",
        },
      ],
      courseError: false,
      selectedCourse: "",
      courseErrorText: "",
      currentClassesList: [
        {
          id: 1,
          name: "Class 6",
        },
        {
          id: 2,
          name: "Class 7",
        },
        {
          id: 3,
          name: "Class 8",
        },
        {
          id: 4,
          name: "Class 9",
        },
        {
          id: 5,
          name: "Class 10",
        },
        {
          id: 6,
          name: "Class 11",
        },
        {
          id: 3,
          name: "Class 12",
        },
      ],
      classError: false,
      selectedCurrentClass: "",
      classErrorText: "",
      programList: [
        {
          id: 1,
          name: "NEET Course",
          value: "NEET Course",
        },
        {
          id: 2,
          name: "JEE Course",
          value: "JEE Course",
        },
        {
          id: 3,
          name: "CA Course",
          value: "CA Course",
        },
        {
          id: 4,
          name: "Foundation Course",
          value: "Foundation Course",
        },
        {
          id: 5,
          name: "Tuition",
          value: "tuition",
        },
        // {
        //   id: 6,
        //   name: "TN Board",
        //   value: "tuition",

        // },
      ],
      programError: false,
      selectedProgram: "",
      programErrorText: "",
      privacyPolicy: false,
      dialCode: "af",
    };
    this.userdata = this.userdata.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleTogglePrivacyPolicy = () => {
    this.setState({ privacyPolicy: !this.state.privacyPolicy });
  };

  validateFormDetails = () => {
    let isValid = true;
    const {
      studentName,
      parentName,
      email,
      phonenumber,
      cityName,
      selectedBoard,
      selectedState,
      selectedCourse,
      selectedCurrentClass,
      selectedProgram,
      schoolName,
    } = this.state;

    if (!studentName) {
      isValid = false;
      this.setState({
        studentnameerror: true,
        studentnameerrorText: "Student name is required.",
      });
    }
    if (!parentName) {
      isValid = false;
      this.setState({
        parentnameerror: true,
        parentnameerrorText: "Parent name is required.",
      });
    }
    if (!email) {
      isValid = false;
      this.setState({ emailerror: true, emailerrorText: "Email is required." });
    }
    if (!phonenumber) {
      isValid = false;
      this.setState({
        phoneerror: true,
        phoneerrorText: "Phone number is required.",
      });
    }
    if (!cityName) {
      isValid = false;
      this.setState({
        citynameerror: true,
        citynameerrorText: "Phone number is required.",
      });
    }
    if (!selectedBoard) {
      isValid = false;
      this.setState({ boardError: true, boardErrorText: "Board is required." });
    }
    if (!selectedState) {
      isValid = false;
      this.setState({ boardError: true, boardErrorText: "State is required." });
    }
    if (!selectedCourse) {
      isValid = false;
      this.setState({
        courseError: true,
        courseErrorText: "Course is required.",
      });
    }
    if (!selectedCurrentClass) {
      isValid = false;
      this.setState({ classError: true, classErrorText: "Class is required." });
    }
    if (!selectedProgram) {
      isValid = false;
      this.setState({ classError: true, classErrorText: "Program is required." });
    }
    if (!schoolName) {
      isValid = false;
      this.setState({
        schoolnameerror: true,
        schoolnameerrorText: "School name is required.",
      });
    }
    return isValid;
  };

  submitForm = (e: any) => {
    e.preventDefault();
    if (this.validateFormDetails()) {
      this.handleZohoFormSubmit();
    }
  };

  handleZohoFormSubmit = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const records = {
      data: {
        Last_Name: this.state.studentName,
        Email: this.state.email,
        School_Name: this.state.schoolName,
        Current_class: this.state.selectedCurrentClass,
        Phone: this.state.phonenumber,
        Looking_for_Admission_in: this.state.selectedCourse,
        Interested_Program:this.state.selectedProgram,
        Father_s_Name: this.state.parentName,
        City: this.state.cityName,
        Board: this.state.selectedBoard,
        State:this.state.selectedState
        // K_Type: "Teachze",
        // Business_Type: "Individual",
        // Lead_Source: "Teachze.com",
        // Webform_Type: "Teachze.com Register",
      },
    };

    const zohoResponse: any = await axios.post(
      `${configJSON.userManagementURL}/${configJSON.zohoFormLeadPostUrl}`,
      records,
      {
        headers: header,
      }
    );
    if (zohoResponse?.status === 200) {
      if (zohoResponse?.data?.errors) {
        toast.error(zohoResponse?.data?.errors[0]?.lead);
      } else {
        toast.error("Form Submitted Successfully");

        await this.props.openThankyouModal();
        await this.props.onCloseModal();
      }
    } else {
      toast.error("Sorry , form could not be submitted due to some error !");
    }

    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );
    // this.postFormDetails = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   configJSON.zohoFormLeadPostUrl
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(records)
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestbaseURLMessage),
    //   configJSON.userManagementURL
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   configJSON.apiMethod
    // );
    // runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    console.log("user_data", data);
    this.setState({ data: data });
  };

  async componentDidMount() {
    console.log(HISTORY.location?.state, "InsideForm");
    if (HISTORY.location?.state?.testimonials?.length) {
      this.setState({
        selectedProgram: HISTORY.location?.state?.program,
        selectedCourse:HISTORY.location?.state?.admission ? HISTORY.location?.state?.admission : HISTORY.location?.state?.courseName,
        filteredcoachingCoursesOptions:this.state.coachingCoursesOptions.filter((item:any)=>item.programType === HISTORY.location?.state?.program && item.from ===  HISTORY.location?.state?.isFrom)
     
      });
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.postFormDetails) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            // localStorage.setItem("assessmentData", JSON.stringify(responseJson.data.attributes));
            console.log(responseJson);
          } else {
            console.log(responseJson);
          }
        }
        console.log(errorReponse);
      }
    }
    // Customizable Area End
  }
}
