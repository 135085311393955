import { makeStyles } from "@material-ui/core/styles";


const useStylesButton = makeStyles((theme) => ({
    trialButton : {
        padding: "0.8rem" ,
        fontWeight: "bold",
        transform: "skewX(-6deg)",
        borderRadius: "10px",
        width: "217px",
        height: "61px",
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: "white",
        
          }
    }
}))


export default useStylesButton