import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { imageMaterial, imageFaculty ,responsive2, trophy1, teamSanthosh, teamYeshwanth, teamDinesh, teamJoshi, Dinesh_Kumar_square, Mrs_Vaishnavi_Narayanan_square, Joshi_kumar_square } from "../assets";


export interface Props {
    navigation: any;
}

interface S {
    // Customizable Area Start
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    data: any;
    features: any;
    selectedForItem: string;
    forStudentList: any;
    forParentList: any;
    forSocietyList: any;
    forItemsList: any;
    leadersList: any;
    selectedEducator: any;
    isOpenEducatorDetails: boolean;
    selectedLeader: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AboutUsController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            data: {},
            isOpenEducatorDetails: false,
            features: [
                {
                    featureImage: imageMaterial,
                    title: "Right study materials",
                    desc: "Our endeavor is to enable the students’ access the right study materials created by our Academic Panel of Senior Faculty members",
                },
                {
                    featureImage: imageFaculty,
                    title: "Expert Faculty",
                    desc: "Our Expert Faculty will deliver the class and will clear doubts raised by the students.",
                },
                {
                    featureImage: responsive2,
                    title: "Equipped with the Technological Tools",
                    desc: "Our Teachers are equipped with the technological tools to deliver classes at your location through our online classes which will be Live and Interactive.",
                },
                {
                    featureImage: trophy1,
                    title: "Quality Education",
                    desc: "Our Expert Faculty will deliver the class and will clear doubts raised by the students.",
                }
            ],
            selectedForItem: "student",
            forStudentList: [
                "Learning made more interactive and creative",
                "Independent and easy learning",
                "Expert faculty for student-centered learning",
                "Comfortable learning environment",
                "Face-to-face classes to enrich learning",
            ],
            forParentList: [
                "Unlimited access to top-notch curriculum",
                "Strong involvement in student’s progress",
                "Cost-effective e-learning programs",
                "Traditional learning with modern technology",
                "Less anxiety and continuous tracking of progress",
            ],
            forSocietyList: [
                "Reaching to maximum students",
                "No carbon footprint",
                "Consistent tracking of students’ progress",
                "Better retention",
                "Anytime, anywhere easy learning",
            ],
            forItemsList: [],
            selectedEducator: {
                id: "1",
                teacherName: "Vijay Sinha",
                qualifications: "M Tech , IIT Bombay",
                experience: "18 years experience of teaching.Mathematics graduation from Delhi University",
                no_of_likes: 3000,
            },
            leadersList: [
                {
                    id: 1,
                    profile: Dinesh_Kumar_square,      
                    name: "Dinesh Kumar",
                    role: "Co-founder",
                    Qualification: "Master's degree(Computer Science), Bachelor of Engineering (BE)(Computer Science)",
                    experience: "9 years and 4 months of experience in software",
                    desc: "EDUCATION FOR ALL. This is the mission that drives Dinesh Kumar. As a Silicon Valley professional and USC alumni with over ten years of experience working for companies like NVIDIA, HP, and various other startups, he possesses the expertise necessary to build a competitive yet enriching learning platform. He is passionate about technology and is particularly invested in using it to solve the genuine issues we face in the world today."
                },
                {
                    id: 2,
                    profile: Joshi_kumar_square,         
                    name: "Joshi Kumar",
                    role: "Co-founder",
                    Qualification: "Master's degree, Computer Science, Bachelor of Engineering (BE)(Computer Science)",
                    experience: "10 years of experience",
                    desc: "A technological geek at heart, Joshi is an alumnus of the National University of Singapore. He knew where his heart lies and pursued his career in the technology industry. Now he has over 10 years of experience working for Fortune 500 companies. He puts his creative best forward when finding solutions to problems and believes that technology is no less than magic if you explore enough"
                },
                {
                    id: 3,
                    profile: Mrs_Vaishnavi_Narayanan_square,
                    name: " Vyshnavi Narayanan",
                    role: "Co-founder",
                    Qualification: "",
                    experience: "",
                    desc: "Vysh is an alumna of Academy of Arts, San Francisco and has experience working for a Fortune 100 company. She has a passion for product design and wants to use her creative abilities in the field of education. She has a keen eye for detail and has won awards for her designs. She has established a strong track record in conceptualizing innovative product designs and loves to experiment with creative processes. She wants to combine her passion and skills and help people learn through new educational programs."
                },
                {
                    id: 4,
                    profile: "",
                    name: " Sunitha",
                    role: "Academic Director",
                    Qualification: "M.Sc, B.Ed",
                    experience: "16 years of experience in education",
                    desc: " Sunitha is a highly skilled and overtly student-focused educational leader possessing a strong commitment to the development of students with the experience of 16 years in the Field of Curriculum development and learning methodologies. Started the career as a teacher in 2005. Since then, has been working in various institutions as an educationist and received the Best Teacher award 2015 from CBSE board. Through workshop trained 300+ teachers on Art of Questioning, Thinking routines, Making Learning visible, Assessment through Blooms Taxonomy, 21st Century skills & Academic rigour. Experienced in creating and executing Interdisciplinary and Cross-Curricular Connect. Deployed imbibing Ron Richard’s thinking routines (project zero) and understanding map as academic routines to instill thinking minds among students. Worked in building emotional and physical awareness in primary school and best career prospects to students in senior school. Strongly believes in “the function of education is to teach one to think and imbibe life skills”.",
                    certifications: [ "International certificate in Education (ICEPT) – Asian International College, Singapore" ],
                    subject: [ "Computer Science" ],
                    achievements: [
                        "Best Teacher Award from CBSE",
                        "Best Presentation award – Academic Progression strategies 2019 for Concept consolidation & Application"
                    ],
                },
            ],
            selectedLeader: null,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      this.setState({ forItemsList: this.state.forStudentList });
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }
        // Customizable Area End
    }

    // Customizable Area Start
    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false,
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    
    // Customizable Area End
}
