import React from "react";
import {
    Grid,
    Modal,
    Typography,
    Button
} from "@material-ui/core";
import { group34, likes, job, experience, checkBox, wreath, bookmark, group22, leftArrow, imageArrowRight } from "../../assets";
import { Close } from "@material-ui/icons";

interface Props {
    onCloseModal: any;
    open: any;
    educatorSubjects: any;
    testimonialsList: any;
    selectedTestimonial: any;
    previousArrowClick: any;
    nextArrowClick: any;
    selectedEducator: any;
}

const EducatorDetails = ({
    onCloseModal,
    open,
    educatorSubjects,
    testimonialsList,
    selectedTestimonial,
    previousArrowClick,
    nextArrowClick,
    selectedEducator,
}: Props) => {
    const handleClose = () => {
        onCloseModal();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div
                style={{
                    justifyContent: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "80%",
                    width: "60%",
                    padding: "3%",
                    overflow: "auto",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "2%" }}>
                    <Typography className="Educator-Profile">Educator Profile</Typography>
                    <div className="close-rectangle" onClick={handleClose}>
                        <Close style={{ color: "#fff", cursor: "pointer" }} />
                    </div>
                </div>

                <Grid container className="Educator-bg-block">
                    <Grid item xs={12} sm={4}>
                        <img src={group34} style={{ marginBottom: "-25%" }} className="Educator-image"></img>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography className="Educator-name">
                            {selectedEducator?.teacherName}
                        </Typography>
                        <Typography className="Educator-exp" style={{ marginTop: 7 }}>
                            {selectedEducator?.qualifications}
                        </Typography>
                        <Typography className="Educator-years-experience-text" style={{ marginTop: 25 }}>
                            {selectedEducator?.experience}
                        </Typography>

                        <div style={{
                            marginTop: "8%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <span style={{ marginRight: "3%" }}>
                                <img src={likes} />
                            </span>
                            <Typography className="plus-Likes">
                                {selectedEducator?.no_of_likes > 3000
                                    ? `${selectedEducator?.no_of_likes}+ Likes`
                                    : `${selectedEducator?.no_of_likes} Likes`}
                            </Typography>
                            <Button style={{
                                width: 120,
                                height: 44,
                                borderRadius: 5,
                                backgroundColor: "#5580ea",
                                msTransform: "skewX(-6deg)",
                                transform: "skewX(-6deg)",
                                marginLeft: "8%"
                            }} className="like-small-button">
                                <Typography style={{
                                    fontFamily: "Inter",
                                    fontSize: 20,
                                    fontWeight: 500,
                                    color: "#fff",
                                    msTransform: "skewX(1deg)",
                                    transform: "skewX(1deg)",
                                    textTransform: "capitalize",
                                }}>
                                    Like
                                </Typography>
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <div style={{ marginTop: "5%" }}>
                    <Typography className="Educator-name">
                        About {selectedEducator?.teacherName}
                    </Typography>
                    <Typography className="Educator-years-experience-text" style={{ marginTop: 13 }}>
                        18 years Experience of Teaching Mathematics Graduation From Delhi University.
                    </Typography>
                    <Typography className="Educator-years-experience-text" style={{ marginTop: 10 }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Typography>
                </div>

                <Grid container >
                    <Grid item xs={12} sm={6}>
                        <div style={{
                            marginTop: "8%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={job} className="small-image"></img>
                            <Typography className="experience-text">Experience</Typography>
                        </div>

                        <div style={{
                            marginTop: "5%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={checkBox}></img>
                            <Typography className="Years-of-Teaching-Text">5+ Years of Teaching Experience</Typography>
                        </div>

                        <div style={{
                            marginTop: "2%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={checkBox}></img>
                            <Typography className="Years-of-Teaching-Text">2+ Years of Teaching Experience</Typography>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{
                            marginTop: "8%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={experience} className="small-image"></img>
                            <Typography className="experience-text">Certification</Typography>
                        </div>

                        <div style={{
                            marginTop: "5%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={checkBox}></img>
                            <Typography className="Years-of-Teaching-Text">M.Tech, IIT Bombay</Typography>
                        </div>

                        <div style={{
                            marginTop: "2%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={checkBox}></img>
                            <Typography className="Years-of-Teaching-Text">B.Tech, Delhi Engineering Collage</Typography>
                        </div>

                    </Grid>
                </Grid>

                <Grid container >
                    <Grid item xs={12}>
                        <div style={{
                            marginTop: "5%",
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <img src={bookmark} className="small-image"></img>
                            <Typography className="achievements-text">Subject</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", flexDirection: "row", marginTop: "3%" }}>
                        <Grid container>
                            {educatorSubjects && educatorSubjects.map((item: any) => (
                                <Grid item xs={6} sm={6} md={4} lg={2} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div className="view_box_subject_educator" >
                                        <img
                                            src={item.imgSource}
                                            style={{
                                                width: 64,
                                                height: 64,
                                                objectFit: "contain",
                                                padding: "10% 0",
                                            }} className="subject_image">
                                        </img>
                                        <Typography className="label-subjectname">
                                            {item.name}
                                        </Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container >
                    <Grid item xs={12}>
                        <div style={{
                            marginTop: "5%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={wreath}></img>
                            <Typography className="achievements-text">Achievements</Typography>
                        </div>

                        <div style={{
                            marginTop: "3%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={checkBox}></img>
                            <Typography className="Best-Teacher-Award-Text">Best Teacher Award 2022</Typography>
                        </div>

                        <div style={{
                            marginTop: "1%",
                            display: "flex",
                            alignItems: "center"
                        }}
                        >
                            <img src={checkBox}></img>
                            <Typography className="Best-Teacher-Award-Text">Master Of Physics Award</Typography>
                        </div>

                    </Grid>
                </Grid>

                <div style={{ marginTop: "3%", marginBottom: "5%" }}>
                    <Typography className="Testimonials-text">Testimonials</Typography>

                    <Grid style={{ display: "flex", flexDirection: "row", marginTop: "3%", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {testimonialsList && testimonialsList.map((item: any) => (
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <img src={item.profileImage} className={item.id === selectedTestimonial?.id ? "selectedImage" : "notSelectedImage"}></img>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <img src={leftArrow} onClick={previousArrowClick} style={{ marginRight: 8 }} className="small-image"></img>
                            <img src={imageArrowRight} onClick={nextArrowClick} className="small-image"></img>
                        </div>
                    </Grid>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "2%" }}>
                        <Typography className="Gamaho-Gemoy-Text">{selectedTestimonial?.name}</Typography>
                        <img src={group22} ></img>
                    </div>
                    <Typography className="Student-Text">{selectedTestimonial?.type}</Typography>
                    <Typography className="Student-Desc-Text">{selectedTestimonial?.desc}</Typography>
                </div>
            </div>
        </Modal>
    );
};
export default EducatorDetails;
