import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Button, Typography, Grid, Box } from "@material-ui/core";
import BlogPageController from "./BlogController.web";
import { search, hhh, me, tweet, insta, fbk, share, image_26 } from "../assets";
import "slick-carousel/slick/slick.css";
import "./Blogdetail.web.css";
import BlogDetailsTemplate from "./BlogDetailsTemplate";
import { HISTORY } from "../../../../components/src/common";
const Reveal = require("react-reveal/Reveal");

class Blogdetail extends BlogPageController {
  render() {
    const { state } = this.props.location;
    const { params } = this.props.match;
    const { blogs } = this.state;
    const push = (category: string, data: any) => {
      HISTORY.push(`/Blogdetail/${category}/${data.title}`, data);
    };
    return (
      <div>
        <Grid container className="firstbox">
          <Grid item xs={12} className="top-light-blue-box">
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="teachze-blog-parent-grid"
              >
                {" "}
                <Reveal effect="fadeInUp">
                  <div className="padding-right width-70per">
                    <Typography variant="h1" className="textlabel_heading">
                      Teachze Blog
                    </Typography>
                  </div>
                </Reveal>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Reveal effect="fadeInUp">
              <div className="dis-jcenter">
                <div className="width-70per">
                  <Grid container>
                    <Grid item xs={12} sm={8} md={6}>
                      <div className="Search-Bg-Rectangle_blog">
                        <input
                          placeholder="Search Blog.."
                          className="Search-for-course_blog"
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          className="search-Btn-icon"
                        >
                          <img title="Search Blog.."
                            src={search}
                            alt="Search icon"
                            className="Search-Img-icon"
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Reveal>
          </Grid>

          <Grid className="dis-jcenter"
            item
            xs={12}
            
          >
            <div className="width-70per">
              <Grid container style={{ paddingTop: "3%", paddingBottom: "3%" }}>
                <Grid item xs={12} sm={12} md={8}>
                  <Reveal effect="fadeInLeft left cascade">
                    <div className="imageabove-text-blog">
                      <span style={{ fontWeight: 600, color: "#394052" }}>
                        {" "}
                        Home {">"} Blog {">"} {params.category} {">"}{" "}
                      </span>
                      {state?.title}
                    </div>

                    <img title="Bridging the Educational Inequality"
                      src={state?.imgSource}
                      alt="Bridging image"
                      className="image-rep-blog"
                    />

                    <h2 className="imagebelow-text-blog">{state?.title}</h2>
                    <Box className="yellow-button-box">
                      <button className="yellow-button">{state?.date}</button>
                    </Box>
                  </Reveal>

                  <BlogDetailsTemplate content={state?.content} />
                  <Reveal effect="fadeInLeft left cascade">
                    <Box className="leave-comment">Leave a comment</Box>
                    <div className="Line"></div>

                    <Grid container className="width_90">
                      <Grid item xs={12}>
                        <Grid container className="name-email-box" spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <Box className="name">
                              {" "}
                              Name <div className="astrik">*</div>
                            </Box>
                            <input className="name-input" type="Name" />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className="email-box">
                              {" "}
                              E-mail<div className="astrik">*</div>
                            </Box>
                            <input className="email-box-input" type="text" />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box className="Comment-box">Comment</Box>
                            <input className="comment-box-text" type="text" />
                          </Grid>
                        </Grid>
                        <Box className="send-button">
                          <span style={{ transform: "skew(1deg)" }}>Send</span>
                        </Box>
                      </Grid>
                    </Grid>
                  </Reveal>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Reveal effect="fadeInRight right cascade">
                    {/* <Box className="about-author">
                      <span>About Author</span>
                    </Box>
                    <Box className="vijay-jala">
                      <img
                        alt="subject"
                        src={me}
                        className="vijay-jala-image"
                      />
                      <Box className="vijay-author" >
                        <span className="vijay-jala-text">
                          Vijay Jala
                        </span>
                        <span className="vijay-author-text">Author</span></Box>
                    </Box>
                    <div className="rightbox-start">
                      Lorem ipsum cum possimus facere tenetur, blanditiis harum
                      quos! Mollitia s oluta maxime quidem, praesentium ipsa
                      laudantium officiis neque accusamus odit et? elit. Laboriosam,
                      nam.
                    </div> */}

                    <Box className="latest-news">
                      <h4 className="latest-news-test">Latest News </h4>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        // marginLeft: "15px",
                      }}
                    >
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          {blogs &&
                            blogs[0]["Technology"]
                              .slice(0, 3)
                              .map((item: any) => (
                                <Box
                                  className="subject00"
                                  onClick={() => push("Technology", item)}
                                >
                                  <img
                                    alt="subject"
                                    src={item.imgSource}
                                    className="listitem_subjectlogooo"
                                  />

                                  <span className="listitem_subjectnameoo">
                                    {item.title}
                                  </span>
                                </Box>
                              ))}
                          {/* <Box
                            className="subject00"
                          >
                            <img
                              alt="subject"
                              src={hhh}
                              className="listitem_subjectlogooo"
                            />
                            <span className="listitem_subjectnameoo">
                              Tips for Finding Ways into Public Sector Work
                            </span>
                          </Box>
                          <Box
                            className="subject00"
                          >
                            <img
                              alt="subject"
                              src={hhh}
                              className="listitem_subjectlogooo"
                            />
                            <span className="listitem_subjectnameoo">
                              WaveMakers Connect: Boost Your Emotional Intelligence Recap
                            </span>
                          </Box> */}
                        </Grid>
                      </Grid>
                    </Box>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>

          {/* <Reveal effect="fadeInLeft left cascade">
            <div className="Rectangle-above-image-background">
              <img className="twitter" src={tweet} alt="image" />
              <img className="instagram" src={insta} alt="image" />
              <img className="facebook" src={fbk} alt="image" />
              <img className="Group-2" src={share} alt="image" />
            </div>
          </Reveal> */}
        </Grid>
      </div>
    );
  }
}

export default withRouter(Blogdetail);
