import React from "react";
import {
    Typography,
    Grid,
    Hidden,
} from "@material-ui/core";
import TechnologyController from './TechnologyController.web'
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import { thumbnail, videoPlayIcon, onlineLearningPana, webinarPana, groupVideoPana, webinarBro, telecommuting, discussion } from '../assets'
import './Technology.web.css'

const root = {
    flexGrow: 1,
    overflowX: "hidden",
    position: "relative"
} as const;
const Reveal = require("react-reveal/Reveal");

class Technology extends TechnologyController {
    render() {
        return (
            <div style={root}>
                <Helmet>
          <title>Teachze | Empowering students with the latest technologies</title>
          <meta name="description" content="Join Teachze LIVE online tuitions and Online coaching classes to experience its unique dynamic and interactive classes within a top-notch educational environment. Teachze empowers each student with giving access to the unlimited power of the latest technologies. With Teachze, anyone can learn anything from anywhere at any time. " />
        </Helmet>
                <Grid container style={{ position: "relative" }}>
                    <Grid className="Competitive-container-info" item xs={12} >
                        <div className="width-70per">
                            <Grid container style={{ padding: "2% 0 0 0" }}>
                                <Grid item xs={12} sm={7} md={7} className="technology-main-block-style" >
                                  <Reveal effect="fadeInLeft left cascade">
                                   <Typography variant="h1" className="Empowering-Students-text">
                                        Empowering Students with the latest tech.
                                    </Typography>
                                    <Typography className="Own-the-unlimited-text">
                                        Own the unlimited power of the latest technologies for taking TeachZe.
                                    </Typography>
                                   </Reveal>
                                </Grid>
                                <Grid className="" item xs={12} sm={5} md={5} style={{ marginBottom: "-2%" }}>
                                    <Reveal effect="fadeInRight right cascade">
                                        <div style={{ width: "100%" }}>
                                            <ReactPlayer
                                                url="https://player.vimeo.com/progressive_redirect/playback/670524444/rendition/540p/540p.mp4?loc=external&oauth2_token_id=1401671315&signature=6345b05d131e84b0af96a1d0bf2aa51b1ffd4dd1ac1bac44748069b7fd3acbfd"
                                                className="react-player video-display-size"
                                                style={{ borderRadius: "8px" }}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            controlsList: "nodownload",
                                                        },
                                                    },
                                                }}
                                                playing
                                                width="100%"
                                                height="310px"
                                                light={thumbnail}
                                                controls
                                                playIcon={<img alt="play icon" src={videoPlayIcon} />}
                                            />
                                        </div>
                                    </Reveal>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid className="justifyContent-center" item xs={12}>
                        <div className="width-70per">
                            <Grid className="main-block-box" container>
                                <Reveal effect="fadeInUp">
                                <div className="main-block-box">
                                    <Typography variant="h3" className="TECHNOLOGY-text">
                                        TECHNOLOGY
                                    </Typography>
                                    <Typography variant="h2" className="Our-Learning-Manage-text">
                                        Our Learning Management System will enable
                                    </Typography>
                                </div>
                                </Reveal>
                                <Grid item xs={12}>
                                    <Grid container className="padding-top-bottom">
                                        <Grid item xs={12} sm={6} className="left-block">
                                            <Reveal effect="fadeInLeft left cascade">
                                            <img title="Distance learning" alt="online learning" src={onlineLearningPana} className="block-img"></img>
                                            </Reveal>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <Typography variant="h2" className="block-text-1">
                                                Distance learning
                                            </Typography>
                                            <Typography className="block-text-2">
                                                Learn from any location – no geographical boundary for learning. Our Teachers are equipped with the technological tools to deliver classes at your location.
                                            </Typography>
                                            </Reveal>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Hidden only={['xs']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <Typography variant="h2" className="block-text-1">
                                                    Live and Interactive Classes
                                                </Typography>
                                                <Typography className="block-text-2">
                                                    Learn from any location – no geographical boundary for learning. Our Teachers are equipped with the technological tools to deliver classes at your location.
                                                </Typography>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                      
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <img title="Live and Interactive Classes" alt="Live Classes" src={webinarPana} className="block-img"></img>
                                            </Reveal>
                                        </Grid>

                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <Typography variant="h2" className="block-text-1">
                                                    Live and Interactive Classes
                                                </Typography>
                                                <Typography className="block-text-2">
                                                    Learn from any location – no geographical boundary for learning. Our Teachers are equipped with the technological tools to deliver classes at your location.
                                                </Typography>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Grid item xs={12} sm={6} className="left-block">
                                            <Reveal effect="fadeInLeft left cascade">
                                            <img title="Video Library" alt="group video" src={groupVideoPana} className="block-img"></img>
                                            </Reveal>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <Typography variant="h2" className="block-text-1">
                                                Video Library
                                            </Typography>
                                            <Typography className="block-text-2">
                                                All the regular class sessions would be available as a video for future reference for the students. This will help the students to revisit the entire class session.
                                            </Typography>
                                            </Reveal>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Hidden only={['xs']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <Typography variant="h2" className="block-text-1">
                                                    Online Sessions and Assessment
                                                </Typography>
                                                <Typography className="block-text-2">
                                                    All the tests and assessments will be in the on-line mode through our Question Paper Generator. This will deliver instant result and provide analytics on the performance of the student for a single test and also the series of tests taken by the student.
                                                </Typography>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <img title="Online Sessions and Assessment" alt="Online sessions" src={webinarBro} className="block-img"></img>
                                            </Reveal>
                                        </Grid>
                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <Typography variant="h2" className="block-text-1">
                                                    Online Sessions and Assessment
                                                </Typography>
                                                <Typography className="block-text-2">
                                                    All the tests and assessments will be in the on-line mode through our Question Paper Generator. This will deliver instant result and provide analytics on the performance of the student for a single test and also the series of tests taken by the student.
                                                </Typography>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Grid item xs={12} sm={6} className="left-block">
                                            <Reveal effect="fadeInLeft left cascade">
                                            <img title="Online Library" alt="telecommuting" src={telecommuting} className="block-img"></img>
                                            </Reveal>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <Typography variant="h2" className="block-text-1">
                                                Online Library
                                            </Typography>
                                            <Typography className="block-text-2">
                                                A repository of content will be available for the students to use at the time of their convenience.
                                            </Typography>
                                            </Reveal>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Hidden only={['xs']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                            <Reveal effect="fadeInLeft left cascade">
                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                        Discussion Forum
                                                    </Typography>
                                                    <Typography className="sub-block-content-text ">
                                                        To facilitate conceptual understanding Discussion forum would be used and the students would be asked to participate. There will be a guided discussion by the faculty to enable peer to peer learning.
                                                    </Typography>
                                                </div>

                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                        Doubt Board
                                                    </Typography>
                                                </div>

                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                        Structured Practice
                                                    </Typography>
                                                </div>

                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                        Feedback
                                                    </Typography>
                                                </div>
                                            </Reveal>
                                            </Grid>
                                        </Hidden>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <img title="Discussion Forum" alt="understanding discussion" src={discussion} className="block-img"></img>
                                            </Reveal>
                                        </Grid>

                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                        Discussion Forum
                                                    </Typography>
                                                    <Typography className="sub-block-content-text ">
                                                        To facilitate conceptual understanding Discussion forum would be used and the students would be asked to participate. There will be a guided discussion by the faculty to enable peer to peer learning.
                                                    </Typography>
                                                </div>

                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                        Doubt Board
                                                    </Typography>
                                                </div>

                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                        Structured Practice
                                                    </Typography>
                                                </div>

                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                        Feedback
                                                    </Typography>
                                                </div>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Technology;