// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import 'react-toastify/dist/ReactToastify.css';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import LandingPage from '../../blocks/email-account-login/src/LandingPage/LandingMainPage.web';
import { ToastContainer } from 'react-toastify';
import CompetetiveExamPage from '../../blocks/email-account-login/src/CompetativeMainPage.web';
import TutionPage from '../../blocks/email-account-login/src/TutionMainPage.web';
import TestSeriesPage from '../../blocks/email-account-login/src/TestSeriesMainPage.web';
import CourseDetailsPage from '../../blocks/email-account-login/src/CourseDetails/CourseDetailsMainPage.web';
import BatchDetailsPage from '../../blocks/email-account-login/src/CourseDetails/BatchDetailsMainPage.web';
import PrivacyPolicy from '../../blocks/email-account-login/src/PrivacyPolicy/PrivacyPolicyMainPage.web';
import Technology from '../../blocks/email-account-login/src/Technology/TechnologyMainPage.web';
import Faculties from '../../blocks/email-account-login/src/Faculties/FacultiesMainPage.web';
import AboutUs from '../../blocks/email-account-login/src/AboutUs/AboutUsMainPage.web';
import BlogPage from '../../blocks/email-account-login/src/Blog/BlogMainPage.web';
import Blogdetail from '../../blocks/email-account-login/src/Blog/BlogDetailMainPage.web';
import ThankYouPage from '../../blocks/email-account-login/src/ThankYou/ThankYou.web';
import ZohoRegistrationForm from '../../blocks/email-account-login/src/ZohoRegistrationFormModalPopup/ZohoRegistrationForm.web';
import ReactPixel from 'react-facebook-pixel';
import 'react-toastify/dist/ReactToastify.css';

const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false // enable logs
};
ReactPixel.init('1010540306553375', advancedMatching, options);

ReactPixel.pageView(); // For tracking page view

const routeMap = {
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  Blogdetail: {
    component: Blogdetail,
    path: '/Blogdetail/:category?/:title?',
    type: 'Guest',
    exact: true
  },
  LandingPage: {
    component: LandingPage,
    path: '/',
    type: 'Guest',
    exact: true
  },
  CourseDetailsPage: {
    component: CourseDetailsPage,
    path: '/CourseDetails',
    type: 'Guest',
    exact: true
  },
  CompetetiveExamPage: {
    component: CompetetiveExamPage,
    path: '/Competitive-exams',
    type: 'Guest',
    exact: true
  },
  CompetetiveExamNeetPage: {
    component: CompetetiveExamPage,
    path: '/Competitive-exams/Neet',
    type: 'Guest',
    exact: true
  },
  CompetetiveExamJeePage: {
    component: CompetetiveExamPage,
    path: '/Competitive-exams/Jee',
    type: 'Guest',
    exact: true
  },
  CompetetiveExamCaPage: {
    component: CompetetiveExamPage,
    path: '/Competitive-exams/CA',
    type: 'Guest',
    exact: true
  },
  CompetetiveExamFoundationPage: {
    component: CompetetiveExamPage,
    path: '/Competitive-exams/Foundation',
    type: 'Guest',
    exact: true
  },
  TutionPage: {
    component: TutionPage,
    path: '/Tutions',
    type: 'Guest',
    exact: true
  },
  TutionCbsePage: {
    component: TutionPage,
    path: '/Tutions/CBSE',
    type: 'Guest',
    exact: true
  },
  TutionTNPage: {
    component: TutionPage,
    path: '/Tutions/TN-Board',
    type: 'Guest',
    exact: true
  },
  TutionFoundationPage: {
    component: TutionPage,
    path: '/Tutions/Foundation',
    type: 'Guest',
    exact: true
  },
  TestSeriesPage: {
    component: TestSeriesPage,
    path: '/Test-series',
    type: 'Guest',
    exact: true
  },
  TestSeriesNeetPage: {
    component: TestSeriesPage,
    path: '/Test-series/Neet',
    type: 'Guest',
    exact: true
  },
  TestSeriesJeePage: {
    component: TestSeriesPage,
    path: '/Test-series/Jee',
    type: 'Guest',
    exact: true
  },
  BatchDetailsPage: {
    component: BatchDetailsPage,
    path: '/BatchDetails',
    type: 'Guest',
    exact: true
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy',
    type: 'Guest',
    exact: true
  },
  Technology: {
    component: Technology,
    path: '/Technology',
    type: 'Guest',
    exact: true
  },
  Faculties: {
    component: Faculties,
    path: '/Faculties',
    type: 'Guest',
    exact: true
  },
  BlogPage: {
    component: BlogPage,
    path: '/Blogs',
    type: 'Guest',
    exact: true
  },
  ThankYouPage: {
    component: ThankYouPage,
    path: '/ThankYou',
    type: 'Guest',
    exact: true
  },
  RegistrationForm: {
    component: ZohoRegistrationForm,
    path: '/RegistrationForm',
    type: 'Guest',
    exact: true
  },
  AboutUs: {
    component: AboutUs,
    path: '/AboutUs',
    type: 'Guest',
    exact: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastContainer
          autoClose={2000}
          position="top-right"
          className="toast-container"
          toastClassName="dark-toast"
        />
      </View>
    );
  }
}

export default App;
